import { api_fetch } from "@/api/client.tsx"
import { Option } from "@/utils"

export interface User {
  id: string
  first_name: Option<string>
  last_name: Option<string>
  email: string
  role: string
  org_id: string
  team?: string
}

export enum UserRole {
  ORG_ADMIN = "org_admin",
}

export interface UserInfo {
  id: string
  first_name: string | null
  last_name: string | null
  email: string
}

export async function getUserById(userId: string) {
  return await api_fetch<UserInfo>(`/user/${userId}`)
}
