import {
  ColumnDef,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  VisibilityState,
} from "@tanstack/react-table"

import { rankItem } from "@tanstack/match-sorter-utils"

import { Button } from "@/components/ui/button.tsx"
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu.tsx"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table.tsx"
import { useEffect, useMemo, useState } from "preact/compat"

import { AccountingMatchPopover } from "@/accounting/match_popover.tsx"
import { ReasonCode } from "@/api/deduction.tsx"
import { Separator } from "@/components/ui/separator.tsx"
import { Skeleton } from "@/components/ui/skeleton.tsx"
import { toast } from "@/components/ui/use-toast.ts"
import { ChevronDown, Columns2, Trash2, X } from "lucide-react"
import { PageControls } from "../table/page_controls.tsx"
import { useSearchParams } from "@/utils"

// Add near the top of the file
interface TableMeta<TData> {
  updateData?: (rowIndex: number, columnId: string, value: any) => void
  onDeleteRows?: (rowIds: string[]) => void
}

// Update the DataTableProps interface
interface DataTableProps<TData, TValue> {
  loading: boolean
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
  meta?: TableMeta<TData>
  toolbar?: preact.ComponentChild
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // ok if the first row in the table has a value that's null, that column won't be filtered
  // https://github.com/TanStack/table/issues/4919
  // thought this was fixed by returning '' instead of null but decided to just fix it by passing empty strings to the table.
  const itemValue = row.getValue(columnId)
  if (!itemValue) return false

  const itemRank = rankItem(itemValue, value)

  // Store the itemRank info
  addMeta({
    itemRank,
  })

  // Return if the item should be filtered in/out
  return itemRank.passed
}

// Change the component to use the generic type instead of Backup
export function BackupTable<TData, TValue>({
  loading,
  columns: providedColumns,
  data,
  meta,
  toolbar,
}: DataTableProps<TData, TValue>) {
  const params = useSearchParams<{ search?: string }>()

  const [rowSelection, setRowSelection] = useState({})
  // Update default sorting to use promo_sub_type
  const [sorting, setSorting] = useState<SortingState>([{ id: "promo_type", desc: false }])
  const [globalFilter, setGlobalFilter] = useState(params.search ?? "")
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({})
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 })

  useEffect(() => {
    const visibleColumns: VisibilityState = {}
    providedColumns.forEach((column: any) => {
      // hack to always show these columns (mostly relevant for accounting detail page)
      if (
        column.id === "reason_code" ||
        column.accessorKey === "retailer_name" ||
        column.accessorKey === "code_description" ||
        column.accessorKey === "product_category" ||
        column.accessorKey === "execution_date" ||
        column.accessorKey === "retailer_invoice_number" ||
        column.accessorKey === "product_line"
      ) {
        visibleColumns[column.id || column.accessorKey] = true
        return
      }

      visibleColumns[column.accessorKey as string] = data.some(row => {
        const value = (row as any)[column.accessorKey as string]
        return !!value
      })
    })
    setColumnVisibility(visibleColumns)
  }, [data, providedColumns])

  const table = useMemo(() => {
    return useReactTable({
      data,
      columns: providedColumns,
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      globalFilterFn: fuzzyFilter,
      onColumnVisibilityChange: setColumnVisibility,
      onGlobalFilterChange: setGlobalFilter,
      onRowSelectionChange: setRowSelection,
      onSortingChange: setSorting,
      onPaginationChange: setPagination,
      meta: meta as TableMeta<TData>,
      state: {
        columnVisibility,
        rowSelection,
        sorting,
        globalFilter,
        pagination,
      },
    })
  }, [
    data,
    providedColumns,
    columnVisibility,
    globalFilter,
    rowSelection,
    sorting,
    pagination,
    meta,
  ])

  // Handle data change to keep pagination state
  useEffect(() => {
    if (data.length < pagination.pageIndex * pagination.pageSize) {
      setPagination(prev => ({ ...prev, pageIndex: 0 }))
    }
  }, [data, pagination.pageIndex, pagination.pageSize])

  const handleBulkSelectMatch = async (code: ReasonCode) => {
    const selectedRows = table.getSelectedRowModel().rows

    // Update the local state to reflect the changes
    selectedRows.forEach(row => {
      // Update both reason_code and expense_account
      // @ts-ignore
      if (table.options.meta?.updateData) {
        // @ts-ignore
        table.options.meta.updateData(row.index, "reason_code", code)
        // @ts-ignore
        table.options.meta.updateData(row.index, "expense_account", code.expense_account)
        // @ts-ignore
        table.options.meta.updateData(row.index, "name", code.name)
      }
    })

    toast({
      title: "Success",
      description: `${selectedRows.length} accounting match${
        selectedRows.length > 1 ? "es" : ""
      } completed`,
    })

    setRowSelection({})
  }

  const isRowSelected = Object.keys(rowSelection).length > 0

  return (
    <>
      {table.getRowModel().rows?.length > 0 ? (
        <div className="flex items-center justify-between py-4">
          <div className="flex gap-2">{toolbar}</div>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="text-slate-500">
                <Columns2 className="h-4" />
                Columns
                <ChevronDown className="h-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {table
                .getAllColumns()
                .filter(column => column.getCanHide())
                .map(column => {
                  return (
                    <DropdownMenuCheckboxItem
                      key={column.id}
                      className="capitalize"
                      checked={column.getIsVisible()}
                      onCheckedChange={(value: any) => column.toggleVisibility(!!value)}>
                      {column.id.replaceAll("_", " ")}
                    </DropdownMenuCheckboxItem>
                  )
                })}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      ) : (
        <div className="flex gap-2 my-4">{toolbar}</div>
      )}
      <Table>
        <TableHeader className="bg-slate-50">
          {table.getHeaderGroups().map(headerGroup => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <TableHead key={header.id} className="first:rounded-tl-md last:rounded-tr-md">
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                )
              })}
            </TableRow>
          ))}
        </TableHeader>
        {loading ? (
          <TableBody>
            <TableRow>
              {Array.from({ length: providedColumns.length }).map((_, cellIndex) => (
                <TableCell key={cellIndex}>
                  <Skeleton className="w-[100px] h-[20px] rounded-full bg-slate-500" />
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map(row => (
                <TableRow key={row.id} data-state={row.getIsSelected() && "selected"}>
                  {row.getVisibleCells().map(cell => (
                    <TableCell
                      className="hover:cursor-pointer"
                      // onClick={() => handleRowClick(row, cell.id)}
                      key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={providedColumns.length} className="h-24 text-center">
                  No backup parsed.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>
      <div class="flex items-center justify-between p-2">
        <PageControls table={table} />
      </div>

      {isRowSelected && (
        <div class="flex fixed bottom-10 z-10 bg-slate-900 rounded-lg border left-1/2 transform -translate-x-1/2 p-2">
          <div className="flex justify-start grow">
            <Button onClick={() => setRowSelection({})} className="text-gray-400" variant="link">
              {`${table.getFilteredSelectedRowModel().rows.length} selected`}
              <X className="h-4" />
            </Button>
          </div>
          <Separator orientation="vertical" className="h-10 bg-slate-700 mr-3" />
          <div className="flex justify-end space-x-3">
            <Button
              variant="destructive"
              onClick={() => {
                const selectedRows = table.getSelectedRowModel().rows
                // @ts-ignore
                const rowIds = selectedRows.map(row => row.original.id)
                // @ts-ignore
                meta?.onDeleteRows?.(rowIds)
                setRowSelection({})
              }}
              className="bg-red-600 hover:bg-red-700">
              <Trash2 className="h-4 w-4 mr-2" />
              Delete Selected
            </Button>
            <AccountingMatchPopover onSelectMatch={handleBulkSelectMatch} />
          </div>
        </div>
      )}
    </>
  )
}
