import { DataTableState } from '@/deductions/table_state';
import { putTransactionType } from '@/global_filter';
import { Activity, ActivityDetails } from '@/stores/activity';
import { exactCurrencyFormatter } from '@/utils';
import { formatDistanceToNow, subMonths } from 'date-fns';
import {
  AlertCircle,
  Bell,
  CheckCircle2,
  FileText,
  MessageCircle,
  Upload
} from 'lucide-react';
import { Link } from 'wouter-preact';

interface ActivityItemProps {
  activity: Activity;
  onClose?: () => void;
  isStandalone?: boolean;
}

type ActivitySample = NonNullable<ActivityDetails['sample_items']>[number];

function getActivityIcon(type: Activity['activity_type']) {
  switch (type) {
    case 'status_change':
      return <CheckCircle2 className="h-5 w-5 text-green-500" />;
    case 'new_import':
      return <Upload className="h-5 w-5 text-blue-500" />;
    case 'task_update':
      return <FileText className="h-5 w-5 text-orange-500" />;
    case 'new_message':
      return <MessageCircle className="h-5 w-5 text-purple-500" />;
    case 'dispute_created':
      return <AlertCircle className="h-5 w-5 text-green-500" />;
    default:
      return <Bell className="h-5 w-5 text-gray-500" />;
  }
}

function formatDisputeType(type: string): string {
  switch (type?.toLowerCase()) {
    case 'chargeback':
      return 'a dispute';
    case 'backup':
      return 'a backup request';
    case 'earlypay':
      return 'an early pay dispute';
    case 'unknown':
      return 'a placeholder dispute';
    default:
      return type?.toLowerCase() || 'a dispute';
  }
}

function handleBulkStatusClick(status: string, onClose?: () => void, isStandalone?: boolean) {
  DataTableState.set(s => {
    const newState = {
      ...s,
      search: "",
      transactionTypes: putTransactionType(s.transactionTypes, "deduction"),
      columnFilters: [{ id: "status_value", value: status }],
      pagination: { pageIndex: 0, pageSize: s.pagination.pageSize },
      startDate: subMonths(new Date(), 12),
      endDate: new Date(),
    };
    
    if (onClose && !isStandalone) {
      setTimeout(onClose, 0);
    }
    
    return newState;
  });

  // Only redirect in standalone mode
  if (isStandalone) {
    window.location.href = '/deductions';
  }
}

function handleBulkTaskClick(taskType: string, status: string | undefined, onClose?: () => void, isStandalone?: boolean) {
  DataTableState.set(s => {
    const newState = {
      ...s,
      search: "",
      transactionTypes: putTransactionType(s.transactionTypes, "deduction"),
      columnFilters: [
        ...(status ? [{ id: "task", value: status }] : [])
      ],
      pagination: { pageIndex: 0, pageSize: s.pagination.pageSize },
      startDate: subMonths(new Date(), 12),
      endDate: new Date(),
    };
    
    if (onClose && !isStandalone) {
      setTimeout(onClose, 0);
    }
    
    return newState;
  });

  // Only redirect in standalone mode
  if (isStandalone) {
    window.location.href = '/deductions';
  }
}

function formatActivityDetails(activity: Activity): string {
  const metadata = activity.metadata;
  const details = metadata.individual_details;
  
  switch (activity.activity_type) {
    case 'new_import':
      const totalCount = metadata.deduction_count;
      const source = metadata.source;
      const samples = details?.sample_invoices || [];
      if (samples.length > 0) {
        return `Imported ${totalCount} deductions from ${source}, including ${samples.map((s: ActivitySample) => s.invoice_number).join(', ')}`;
      }
      return `Imported ${totalCount} deductions from ${source}`;

    case 'status_change':
      const status = metadata.new_status;
      const count = metadata.deduction_count;
      const statusSamples = details?.sample_items || [];
      if (statusSamples.length > 0) {
        return `Updated status to ${status} for ${count} deductions, including ${statusSamples.map((s: ActivitySample) => s.invoice_number).join(', ')}`;
      }
      return `Updated status to ${status} for ${count} deductions`;

    case 'task_update':
      const taskType = metadata.task_type;
      const taskCount = metadata.task_count;
      const taskSamples = details?.sample_items || [];
      if (taskSamples.length > 0) {
        return `Updated ${taskCount} ${taskType} tasks, including ${taskSamples.map((s: ActivitySample) => s.invoice_number).join(', ')}`;
      }
      return `Updated ${taskCount} ${taskType} tasks`;

    case 'new_message':
      const messageCount = metadata.message_count;
      const messageSamples = details?.sample_items || [];
      if (messageSamples.length > 0) {
        const firstSample = messageSamples[0] as ActivitySample;
        const fromName = firstSample.from?.name || firstSample.from?.email || 'Unknown';
        return `${messageCount} new messages from ${fromName}, including "${firstSample.subject}"`;
      }
      return `${messageCount} new messages`;
    
    case 'dispute_created':
      const disputeType = metadata.type;
      const disputeAmount = metadata.deduction_amount;
      const invoiceNumber = metadata.invoice_number;
      return `Created ${formatDisputeType(disputeType || '')} for ${exactCurrencyFormatter(disputeAmount || 0)}, including ${invoiceNumber}`;

    default:
      return 'Activity details not available';
  }
}

function ActivityItemContent({ activity, onClose, isStandalone }: ActivityItemProps) {
  return (
    <div className="flex gap-4">
      <div className="mt-1">
        {getActivityIcon(activity.activity_type)}
      </div>
      <div className="flex-1 space-y-1">
        <div className="flex items-center justify-between">
          <p className="text-sm font-medium">
            {activity.actor_name || 'MarginWiz'}
          </p>
          <span className="text-xs text-muted-foreground">
            {formatDistanceToNow(new Date(activity.created_at), { addSuffix: true })}
          </span>
        </div>
        <p className="text-sm text-muted-foreground">
          {formatActivityDetails(activity)}
        </p>
        {activity.metadata.individual_details && (
          <div className="mt-2 text-xs text-muted-foreground space-y-1">
            {(activity.activity_type === 'new_import' 
              ? activity.metadata.individual_details.sample_invoices 
              : activity.metadata.individual_details.sample_items
            )?.map((detail: ActivitySample, i: number) => (
              <div key={i} className="pl-2 border-l-2 border-muted">
                {detail.invoice_number && (
                  <span className="hover:underline">
                    {detail.invoice_number}
                  </span>
                )}
                {detail.invoice_amount && (
                  <span className="ml-2">
                    {new Intl.NumberFormat('en-US', { 
                      style: 'currency', 
                      currency: 'USD' 
                    }).format(detail.invoice_amount)}
                  </span>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export function ActivityItem({ activity, onClose, isStandalone }: ActivityItemProps) {
  const deductionId = activity.metadata.deduction_ids?.[0] || activity.metadata.deduction_id;
  const isBulkAction = activity.metadata.deduction_count && activity.metadata.deduction_count > 1;

  const content = <ActivityItemContent activity={activity} onClose={onClose} isStandalone={isStandalone} />;

  if (isBulkAction) {
    const onClick = () => {
      switch (activity.activity_type) {
        case 'status_change':
          handleBulkStatusClick(activity.metadata.new_status!, onClose, isStandalone);
          break;
        case 'task_update':
          handleBulkTaskClick(activity.metadata.task_type!, activity.metadata.status, onClose, isStandalone);
          break;
      }
    };
    
    return (
      <div className="px-6 py-3 hover:bg-muted/50 cursor-pointer" onClick={onClick}>
        {content}
      </div>
    );
  } else if (deductionId) {
    return (
      <Link href={`/deduction/${deductionId}`}>
        <div className="px-6 py-3 hover:bg-muted/50 cursor-pointer">
          {content}
        </div>
      </Link>
    );
  }

  return (
    <div className="px-6 py-3">
      {content}
    </div>
  );
} 