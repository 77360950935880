import { BreadcrumbNav, BreadcrumbNavProps } from "@/app/breadcrumb.tsx"
import { Searchbar } from "@/app/searchbar.tsx"
import { ActivityFeed } from "@/components/activity-feed"
import { Button } from "@/components/ui/button"
import { DateRangeFilter, DistributorFilter } from "@/global_filter"
import { Bell, ExternalLinkIcon } from "lucide-react"
import { useState } from "preact/hooks"
import { Link } from "wouter-preact"

interface GlobalFilterBarProps {
  showDistributorFilter?: boolean
}

export function GlobalFilterBar({ showDistributorFilter = true }: GlobalFilterBarProps) {
  return <div class="space-x-2 flex">
    {showDistributorFilter && <DistributorFilter/>}
    <DateRangeFilter />
  </div>
}

interface NavbarProps {
  showDistributorFilter?: boolean
}

export function Navbar({ showDistributorFilter = true }: NavbarProps) {
  const [showActivityFeed, setShowActivityFeed] = useState(false);

  return <div class="flex justify-between pb-12 space-x-2 items-center">
    <Searchbar />
    <div className="flex items-center space-x-4">
      <GlobalFilterBar showDistributorFilter={showDistributorFilter} />
      <Button
        variant="outline"
        // @ts-ignore
        size="icon"
        onClick={() => setShowActivityFeed(true)}
      >
        <Bell className="h-5 w-5" />
      </Button>
    </div>
    <ActivityFeed 
      open={showActivityFeed} 
      onOpenChange={setShowActivityFeed} 
    />
  </div>
}

interface ToggleViewButtonProps {
  deductionId: string
  currentPath: string
}

const createToggleUrl = (currentPath: string, deductionId: string): string => {
  const isDeductionView = currentPath.includes("/deduction/")
  return isDeductionView 
    ? `/split/${deductionId}` 
    : `/deduction/${deductionId}`
}

const getToggleButtonText = (currentPath: string): string => {
  return currentPath.includes("/deduction/") 
    ? "View Accounting" 
    : "View Deduction"
}

function ToggleViewButton({ deductionId, currentPath }: ToggleViewButtonProps) {
  const toggleUrl = createToggleUrl(currentPath, deductionId)
  const buttonText = getToggleButtonText(currentPath)

  return (
    <Link href={toggleUrl}>
      <Button variant="outline" className="space-x-2">
        <span>{buttonText}</span>
        <ExternalLinkIcon className="h-4 w-4" />
      </Button>
    </Link>
  )
}

export function DetailNavbar({items}: BreadcrumbNavProps) {
  const currentPath = window.location.pathname
  const deductionId = currentPath.split("/").pop() || ""

  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center space-x-4">
        <BreadcrumbNav items={items}/>
        <ToggleViewButton 
          deductionId={deductionId} 
          currentPath={currentPath}
        />
      </div>
      <Searchbar />
    </div>
  )
}