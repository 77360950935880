import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { cn } from "@/utils";

export type BadgeVariantOptionType = 
  | "new" | "review" | "validated" | "won" | "pending" | "disputed" 
  | "backup_requested" | "backup_received" | "processing" | "disputable" 
  | "default" | "secondary" | "destructive" | "outline"
  | "emerald" | "sky" | "amber" | "rose" | "slate" | "teal" | "lime" | "indigo" | "fuchsia"
  | undefined;
const badgeVariants = cva(
  "inline-flex items-center rounded-full border px-2.5 py-0.5 text-sm transition-colors focus:outline-hidden focus:ring-1 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        // needs action - red
        new: "border-transparent bg-red-500 text-black hover:bg-red-600",
        
        // needs action - amber
        backup_received: "border-transparent bg-amber-500 text-black hover:bg-amber-600",
        review: "border-transparent bg-amber-500 text-black hover:bg-amber-600",
        disputable: "border-transparent bg-amber-500 text-black hover:bg-amber-600",
        
        // partial action taken - yellow
        expensable: "border-transparent bg-yellow-500 text-black hover:bg-yellow-600",
        backup_requested: "border-transparent bg-yellow-500 text-black hover:bg-yellow-600",
        disputed: "border-transparent bg-yellow-500 text-black hover:bg-yellow-600",
        pending: "border-transparent bg-yellow-500 text-black hover:bg-yellow-600",
        
        // don't need to work on it - slate
        processing: "border-transparent bg-slate-500 text-white hover:bg-slate-600",
        archived: "border-transparent bg-slate-500 text-white hover:bg-slate-600",
        lost: "border-transparent bg-slate-500 text-white hover:bg-slate-600",
        misc: "border-transparent bg-slate-500 text-white hover:bg-slate-600",
        
        // celebration - green
        validated: "border-transparent bg-green-500 text-black hover:bg-green-600",
        won: "border-transparent bg-green-500 text-black hover:bg-green-600",

        // default variants
        default: "border-transparent bg-primary text-primary-foreground hover:bg-primary/80",
        secondary: "border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80",
        outline: "text-foreground",
        warning: "border-transparent bg-yellow-300 text-yellow-900 hover:bg-yellow-400",
        success: "border-transparent bg-green-200 text-green-800 hover:bg-green-300",
        destructive: "border-transparent bg-red-200 text-red-800 hover:bg-red-300",

        // Category colors with matching shades
        emerald: "border-transparent bg-emerald-500 text-black hover:bg-emerald-600",
        sky: "border-transparent bg-sky-500 text-black hover:bg-sky-600",
        amber: "border-transparent bg-amber-500 text-black hover:bg-amber-600",
        rose: "border-transparent bg-rose-100 text-rose-700 hover:bg-rose-200",
        slate: "border-transparent bg-slate-500 text-white hover:bg-slate-600",
        teal: "border-transparent bg-teal-100 text-teal-700 hover:bg-teal-200",
        lime: "border-transparent bg-lime-100 text-lime-700 hover:bg-lime-200",
        indigo: "border-transparent bg-indigo-100 text-indigo-700 hover:bg-indigo-200",
        fuchsia: "border-transparent bg-fuchsia-100 text-fuchsia-700 hover:bg-fuchsia-200",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  )
}

export { Badge, badgeVariants };
