import { QueryData, api_fetch } from "@/api"
import { Card, CardContent, CardHeader } from "@/components/ui/card"
import { DataTableState } from "@/deductions/table_state"
import { currencyFormatter, displayFormattedDistributor, toISODateString } from "@/utils"
import { useEffect, useState } from "preact/hooks"
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts"
import { getColor } from "./chart_colors"
import { TitleArea } from "./monthly_breakdown_base"

interface DisputedBySourceData {
  source: string
  original_source?: string
  disputed_amount: number
  num_disputes: number
}

// Format percentage as "XX.X%"
const formatPercent = (value: number) => `${(value * 100).toFixed(1)}%`

export function DisputedBySourceChart() {
  const [data, setData] = useState<DisputedBySourceData[]>([])
  const { startDate, endDate } = DataTableState.use(ts => ts!)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function fetchData() {
      try {
        let res = await api_fetch<QueryData>(`/deductions/query`, {
          params: {
            query_name: "disputed_by_source",
            start_date: startDate ? toISODateString(startDate) : undefined,
            end_date: endDate ? toISODateString(endDate) : undefined,
          },
        })

        if (!res.ok) {
          throw new Error("Failed to fetch data")
        }

        const headerIndex = res.value.data.headers.reduce(
          (acc, header, index) => {
            acc[header] = index
            return acc
          },
          {} as { [key: string]: number },
        )

        const chartData = res.value.data.rows.map(row => ({
          source: row[headerIndex.source] as string,
          original_source: row[headerIndex.original_source] as string,
          disputed_amount: Math.abs(Number(row[headerIndex.disputed_amount])),
          num_disputes: Number(row[headerIndex.num_disputes]),
        }))

        setData(chartData)
        setLoading(false)
      } catch (error) {
        console.error("Error fetching data:", error)
        setLoading(false)
      }
    }

    fetchData()
  }, [startDate, endDate])

  const total = data.reduce((sum, item) => sum + item.disputed_amount, 0)

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }: any) => {
    const RADIAN = Math.PI / 180
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    if (percent < 0.05) return null // Don't show labels for small slices

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        className="text-xs font-medium"
      >
        {formatPercent(percent)}
      </text>
    )
  }

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload
      return (
        <div className="rounded-lg border bg-background p-3 shadow-xs">
          <p className="font-semibold">
            {displayFormattedDistributor(data.source, data.original_source)}
          </p>
          <p className="text-sm text-muted-foreground">
            Amount: {currencyFormatter(data.disputed_amount)}
          </p>
          <p className="text-sm text-muted-foreground">
            Count: {data.num_disputes}
          </p>
          <p className="text-sm text-muted-foreground">
            Share: {formatPercent(data.disputed_amount / total)}
          </p>
        </div>
      )
    }
    return null
  }

  if (loading) {
    return (
      <div className="flex mt-4">
        <Card className="w-full max-w-4xl">
          <CardHeader>
            <TitleArea title="Loading..." />
          </CardHeader>
        </Card>
      </div>
    )
  }

  return (
    <div className="flex mt-4">
      <Card className="w-full max-w-4xl">
        <CardHeader>
          <TitleArea 
            title="Disputed Amount by Customer" 
            titleTooltip="Distribution of disputed amounts across different customers" 
          />
        </CardHeader>
        <CardContent>
          <div className="h-[400px]">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={data}
                  dataKey="disputed_amount"
                  nameKey="source"
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={120}
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={getColor(entry.source, "deductions")}
                    />
                  ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
                <Legend 
                  formatter={(value: string, entry: any) => 
                    displayFormattedDistributor(entry.payload.source, entry.payload.original_source)
                  }
                  wrapperStyle={{ paddingTop: "16px" }}
                />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </CardContent>
      </Card>
    </div>
  )
}

if (import.meta.vitest) {
  describe("DisputedBySourceChart", () => {
    it("formats data correctly", () => {
      const mockData = [
        { source: "unfi", disputed_amount: 1000, num_disputes: 5 },
        { source: "kehe", disputed_amount: 2000, num_disputes: 10 },
      ]
      
      const total = mockData.reduce((sum, item) => sum + item.disputed_amount, 0)
      expect(total).toBe(3000)
      
      expect(currencyFormatter(1000)).toBe("$1,000")
      expect(formatPercent(1000/3000)).toBe("33.3%")
    })
  })
} 