import { Err, Ok, Result } from "@/utils"

interface GetOptions {
  method?: "GET"
  use_cache?: boolean
  params?: Record<string, string | number | boolean | null | undefined>
}

interface DeleteOptions {
  method: "DELETE"
  use_cache?: false
}

interface RestDataOptions {
  method?: "POST" | "PUT" | "PATCH"
  body: object
  use_cache?: boolean
}

export type RestOptions = GetOptions | RestDataOptions | DeleteOptions

export interface HttpResponse<T> {
  data: T
  status: number
  headers: Headers
}

export class HttpError extends Error {
  status: number | null

  constructor(message: string, status: number | null) {
    super(message)
    this.status = status
  }
}

const REQUEST_CACHE: Record<string, [Result<HttpResponse<any>, HttpError>, number]> = {}

export function data_or_throw<T>(result: Result<HttpResponse<T>, HttpError>): T {
  if (!result.ok) {
    throw result.error
  }
  return result.value.data
}

export async function api_fetch_throws<T>(path: string, opt?: RestOptions): Promise<T> {
  return data_or_throw(await api_fetch(path, opt))
}

export async function api_fetch<T>(path: string, opt?: RestOptions): Promise<Result<HttpResponse<T>, HttpError>> {
  let options = opt ?? {}
  let res
  if ("params" in options) {
    let qs = new URLSearchParams()
    for (let [key, value] of Object.entries(options.params!)) {
      if (value == undefined) {
        continue
      }
      qs.set(key, value.toString())
    }
    path += "?" + qs.toString()
  }
  let cacheKey: string = JSON.stringify([path, options])
  if (!!options.use_cache) {
    let cached = REQUEST_CACHE[cacheKey]
    if (cached) {
      let [result, timestamp] = cached
      if (Date.now() - timestamp < 1000 * 60 * 5) {
        return result
      }
    }
  }
  try {
    res = await fetch("/api" + path, {
      method: options.method ?? ("body" in options ? "POST" : "GET"),
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      credentials: "same-origin",
      body: "body" in options ? JSON.stringify(options.body) : null,
    })
  } catch (e) {
    // the request timed out or we couldn't connect to the server at all (e.g. server is down).
    return Err(
      new HttpError("There is a problem with your internet connection. Check your connection and try again.", null),
    )
  }
  const content = await res.text()
  let message
  let data
  try {
    data = JSON.parse(content)
    message = data.message
  } catch (e) {
  }
  // add to cache
  if (res.status >= 500) {
    message = message ?? "There was a server error. Please try again later."
    return Err(new HttpError(message, res.status))
  } else if (res.status >= 400) {
    message = message ?? "There was a client error."
    return Err(new HttpError(message, res.status))
  } else {
    let result = Ok({
      data,
      status: res.status,
      headers: res.headers,
    })
    if (!!options.use_cache) {
      REQUEST_CACHE[cacheKey] = [result, Date.now()]
    }
    return result
  }
}

if (import.meta.vitest) {
  const mockFetch = vi.fn()
  global.fetch = mockFetch

  describe("api_fetch", () => {
    beforeEach(() => {
      vi.resetAllMocks()
    })

    afterEach(() => {
      vi.restoreAllMocks()
    })

    it("should make a successful GET request", async () => {
      const mockResponse = { data: "test data" }
      mockFetch.mockResolvedValueOnce({
        ok: true,
        status: 200,
        text: () => Promise.resolve(JSON.stringify(mockResponse)),
        headers: new Headers(),
      })

      const result = await api_fetch("/test")
      expect(result.ok).toBe(true)
      if (result.ok) {
        expect(result.value.data).toEqual(mockResponse)
        expect(result.value.status).toBe(200)
      }
    })

    it("should make a successful POST request", async () => {
      const mockResponse = { data: "posted data" }
      mockFetch.mockResolvedValueOnce({
        ok: true,
        status: 201,
        text: () => Promise.resolve(JSON.stringify(mockResponse)),
        headers: new Headers(),
      })

      const result = await api_fetch("/test", { method: "POST", body: { test: "data" } })
      expect(result.ok).toBe(true)
      if (result.ok) {
        expect(result.value.data).toEqual(mockResponse)
        expect(result.value.status).toBe(201)
      }
      expect(mockFetch).toHaveBeenCalledWith(
        "/api/test",
        expect.objectContaining({
          method: "POST",
          body: JSON.stringify({ test: "data" }),
        }),
      )
    })

    it("should handle network errors", async () => {
      mockFetch.mockRejectedValueOnce(new Error("Network error"))

      const result = await api_fetch("/test")
      expect(result.ok).toBe(false)
      if (!result.ok) {
        expect(result.error).toBeInstanceOf(HttpError)
        expect(result.error.message).toBe(
          "There is a problem with your internet connection. Check your connection and try again.",
        )
      }
    })

    it("should handle server errors (5xx)", async () => {
      mockFetch.mockResolvedValueOnce({
        ok: false,
        status: 500,
        text: () => Promise.resolve(JSON.stringify({ message: "Server error" })),
      })

      const result = await api_fetch("/test")
      expect(result.ok).toBe(false)
      if (!result.ok) {
        expect(result.error).toBeInstanceOf(HttpError)
        expect(result.error.message).toBe("Server error")
        expect(result.error.status).toBe(500)
      }
    })

    it("should handle client errors (4xx)", async () => {
      mockFetch.mockResolvedValueOnce({
        ok: false,
        status: 400,
        text: () => Promise.resolve(JSON.stringify({ message: "Bad request" })),
      })

      const result = await api_fetch("/test")
      expect(result.ok).toBe(false)
      if (!result.ok) {
        expect(result.error).toBeInstanceOf(HttpError)
        expect(result.error.message).toBe("Bad request")
        expect(result.error.status).toBe(400)
      }
    })

    it("should use cache when specified", async () => {
      const mockResponse = { data: "cached data" }
      mockFetch.mockResolvedValueOnce({
        ok: true,
        status: 200,
        text: () => Promise.resolve(JSON.stringify(mockResponse)),
        headers: new Headers(),
      })

      // First call to populate cache
      await api_fetch("/test", { use_cache: true })

      // Second call should use cache
      const result = await api_fetch("/test", { use_cache: true })
      expect(result.ok).toBe(true)
      if (result.ok) {
        expect(result.value.data).toEqual(mockResponse)
      }
      expect(mockFetch).toHaveBeenCalledTimes(1) // Fetch should only be called once
    })

    it("should handle query parameters", async () => {
      mockFetch.mockResolvedValueOnce({
        ok: true,
        status: 200,
        text: () => Promise.resolve("{}"),
        headers: new Headers(),
      })

      await api_fetch("/test", { params: { foo: "bar", baz: 123 } })
      expect(mockFetch).toHaveBeenCalledWith("/api/test?foo=bar&baz=123", expect.anything())
    })
  })
}
