import { DeductionResponse } from "@/api"
import { TransactionType } from "@/global_filter/transaction_type.tsx"
import { Option } from "@/utils"
import { ColumnFiltersState, ColumnSort, PaginationState, VisibilityState } from "@tanstack/react-table"
import { subMonths } from "date-fns"
import { entity, persistence } from "simpler-state"

interface DataTableParams {
  search: string
  startDate: Option<Date>
  endDate: Option<Date>
  distributor: Option<string>
  transactionTypes: TransactionType[]
  rowSelection: DeductionResponse | {}
  sorting: ColumnSort[]
  columnVisibility: VisibilityState
  pagination: PaginationState
  columnFilters: ColumnFiltersState
}

export const INITIAL_TABLE_STATE: DataTableParams = {
  search: "",
  startDate: subMonths(new Date(), 6),
  endDate: new Date(),
  distributor: null,
  transactionTypes: ["deduction"],
  rowSelection: {},
  columnFilters: [],
  columnVisibility: {
    check_amount: false,
    check_number: false,
    execution_date: false,
    dc_name: false,
    reason_code_type: false,
    product_line: false,
    retailer_invoice_number: false,
    validated: false,
    custom_deduction_method: false,
    executive_mapping: false,
    dispute_likelihood: false,
    accounting_status: false,
    custom_category: false,
    has_splits: false,
    is_parent: false,
    customer_number: false,
    import_source: false,
    pending: false,
    remote_accounting_id: false,
  },
  pagination: { pageIndex: 0, pageSize: 50 }, // todo(joey): maybe just don't save this in the sticky state?
  sorting: [{ id: "validated", desc: false }],
}

function deserializeFn(data: any): DataTableParams {
  data = JSON.parse(data)
  if (data.startDate) {
    data.startDate = new Date(data.startDate)
  } else if (data.start_date) {
    data.startDate = new Date(data.start_date)
  } else {
    data.startDate = INITIAL_TABLE_STATE.startDate
  }
  if (data.endDate) {
    data.endDate = new Date(data.endDate)
  } else if (data.end_date) {
    data.endDate = new Date(data.end_date)
  } else {
    data.endDate = INITIAL_TABLE_STATE.endDate
  }
  data.search = data.search ?? INITIAL_TABLE_STATE.search
  if (data.distributor === "All Distributors") {
    data.distributor = INITIAL_TABLE_STATE.distributor
  } else if (data.distributor) {
    data.distributor = data.distributor.toLowerCase()
  } else {
    data.distributor = INITIAL_TABLE_STATE.distributor
  }
  data.transactionTypes = data.transactionTypes ?? INITIAL_TABLE_STATE.transactionTypes
  data.rowSelection = data.rowSelection ?? INITIAL_TABLE_STATE.rowSelection
  data.sorting = data.sorting ?? INITIAL_TABLE_STATE.sorting
  data.columnVisibility = data.columnVisibility ?? INITIAL_TABLE_STATE.columnVisibility
  data.pagination = data.pagination ?? INITIAL_TABLE_STATE.pagination
  data.columnFilters = data.columnFilters ?? INITIAL_TABLE_STATE
  return data
}

export const DataTableState = entity(INITIAL_TABLE_STATE, [persistence("deduction_table_state", { deserializeFn })])
