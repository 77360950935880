export const colors = {
  kehe: {
    sales: "#81c784", // green-300
    deductions: "#388e3c", // green-700
  },
  unfi: {
    sales: "#4db6ac", // teal-300
    deductions: "#009688", // teal-700
  },
  target: {
    sales: "#ef9a9a", // red-300
    deductions: "#e53935", // red-700
  },
  ahold: {
    sales: "#9fa8da", // indigo-300
    deductions: "#3949ab", // indigo-700
  },
  ab: {
    sales: "#ce93d8", // purple-300
    deductions: "#7b1fa2", // purple-700
  },
  cands: {
    sales: "#90caf9", // blue-300
    deductions: "#1976d2", // blue-700
  },
  biggeyser: {
    sales: "#ffcc80", // orange-300
    deductions: "#f57c00", // orange-700
  },
  cvs: {
    sales: "#a5d6a7", // light-green-300
    deductions: "#388e3c", // light-green-700
  },
  polar: {
    sales: "#80deea", // cyan-300
    deductions: "#0097a7", // cyan-700
  },
  albertsons: {
    sales: "#fff176", // yellow-300
    deductions: "#fbc02d", // yellow-700
  },
  walmart: {
    sales: "#f48fb1", // pink-300
    deductions: "#c2185b", // pink-700
  },
  hannaford: {
    sales: "#bcaaa4", // brown-300
    deductions: "#5d4037", // brown-700
  },
  heb: {
    sales: "#e6ee9c", // lime-300
    deductions: "#afb42b", // lime-700
  },
  kroger: {
    sales: "#b39ddb", // deep-purple-300
    deductions: "#512da8", // deep-purple-700
  },
  costco: {
    sales: "#ffab91", // deep-orange-300
    deductions: "#e64a19", // deep-orange-700
  },
  demoulas: {
    sales: "#9575cd", // deep-purple-300
    deductions: "#512da8", // deep-purple-700
  },
}

export function getColor(distributor: string, type: "sales" | "deductions") {
  if (distributor.toLowerCase() in colors) {
    return colors[distributor.toLowerCase() as keyof typeof colors][type]
  }
  // Fallback colors for any other distributors
  return type === "sales" ? "#8884d8" : "#0088fe"
} 