import { Wordmark } from "@/app/logo"
import { classes } from "@/utils"
import { StarIcon } from "@heroicons/react/20/solid"
import { MinusCircle, PlusCircle } from "lucide-react"
import { useEffect, useState } from "preact/hooks"

export const DEMO_LINK = "https://calendly.com/kurtwolf/demo"

export function HeaderBar() {
  return (
    <div class="flex flex-wrap-reverse justify-between">
      <Wordmark />
      <div class="space-x-4 hidden lg:flex items-center">
        <a
          class="px-4 py-2 text-gray-900 border border-indigo rounded-full uppercase text-sm font-bold"
          href="/login"
        >
          Login
        </a>
        <a
          class="px-4 py-2 text-gray-900 border border-indigo bg-indigo rounded-full text-white uppercase text-sm font-bold"
          href={DEMO_LINK}
        >
          Book a demo
        </a>
      </div>
    </div>
  )
}

export function HeroInner() {
  return (
    <div class="flex flex-wrap-reverse justify-between">
      <div class="mt-12 lg:mt-60">
        <div class="font-display text-5xl">
          Manage Your <br />
          Margins, Like Magic.
        </div>
        <div class="text-lg mt-4 max-w-lg">
          MarginWiz is the first AI-powered platform for CPG brands that helps you track, validate, and reclaim
          deductions and take control of your margins, all in a few clicks.
        </div>
        <div class="flex items-center mt-8 space-x-4">
          <a
            class="px-4 py-2 text-gray-900 border border-indigo bg-indigo rounded-full text-white uppercase text-sm font-bold"
            href={DEMO_LINK}
          >
            Book a demo
          </a>
          <a
            class="px-4 py-2 text-gray-900 border border-indigo rounded-full uppercase text-sm font-bold"
            href="mailto:sales@marginwiz.com"
          >
            Contact Us
          </a>
        </div>
      </div>
      <img class="max-w-[670px] w-3/4 lg:w-auto mx-auto lg:mt-12" src="/img/landing/soda.png" />
    </div>
  )
}

export function Integrations() {
  return (
    <div class="mt-24 pb-12">
      <div class="uppercase text-center ">Distributors & Retailers we connect to</div>
      <div class="flex flex-wrap justify-between mt-3 rounded-xl bg-salmon items-center lg:space-y-4 space-y-8 px-4 pt-4">
        <img src="/img/integration/kehe.png" class="h-11 img-masked" />
        <img src="/img/integration/unfi.png" class="h-9 img-masked" />
        <img src="/img/integration/target.png" class="h-9 img-masked" />
        <img src="/img/integration/amazon.png" class="h-9 mt-2 img-masked" />
        <img src="/img/integration/walmart.png" class="h-9 img-masked" />
        <img src="/img/integration/ahold.png" class="h-9 -mt-2 img-masked" />
        <img src="/img/integration/rainforest.png" class="h-9 img-masked" />
        <div class="font-display text-3xl self-start">& more!</div>
      </div>
    </div>
  )
}
export function Hero2() {
  return (
    <div
      class="pt-1 lg:pt-6 bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: 'url("/img/landing/hero2bg.png")' }}
    >
      <HeaderBar />
      <HeroInner />
      <Integrations />
    </div>
  )
}

export function Benefits() {
  return (
    <div class="pt-12 bg-orange-100">
      <div class="uppercase text-center text-sm">Our Solution</div>
      <div class="font-display text-5xl text-center ">
        Automate your<br /> deduction disputes
      </div>
      <div class="flex flex-wrap space-y-6 justify-between mt-8 lg:mt-14">
        <div class="lg:basis-3/10">
          <div class="font-display text-7xl text-center text-salmon">15x</div>
          <div class="font-display text-2xl text-center">time savings</div>
          <div class="text-center mt-3">
            File disputes in seconds, not minutes. Map deductions to the correct accounting codes automatically.
          </div>
        </div>
        <div class="lg:basis-3/10">
          <div class="font-display text-7xl text-center text-salmon">
            +100
          </div>
          <div class="font-display text-2xl text-center">
            team happiness
          </div>
          <div class="text-center mt-3">
            Let us handle your deductions, so you and your team can focus on the work they love doing.
          </div>
        </div>
        <div class="lg:basis-3/10">
          <div class="font-display text-7xl text-center text-salmon">
            25%+
          </div>
          <div class="font-display text-2xl text-center">
            profit margin boost
          </div>
          <div class="text-center mt-3">
            Put the money you earned back on your bottom line, where it belongs.
          </div>
        </div>
      </div>
      <div class="flex flex-wrap items-end mt-8 lg:mt-20 space-y-6 justify-center">
        <img
          class="w-1/2 lg:w-[250px] lg:-mr-6 z-1 lg:mb-8"
          src="/img/landing/mascot_money.png"
        />
        <a href="/img/landing/dashboard.png">
          <img class="lg:w-[853px]" src="/img/landing/dashboard.png" />
        </a>
      </div>
    </div>
  )
}

const TESTIMONIALS = [
  {
    text:
      "I've been waiting the last 15 years of my career for something like MarginWiz. I needed this at Poppi, and I'm so glad they've made deductions easy to manage.",
    name: "Roxanne McBride",
    role: "CFO at Bellway",
    photo: "/img/testimonials/roxanne.jpeg",
    logo: "/img/testimonials/bellway.png",
    alt: "Bellway",
  },
  {
    text:
      "What this gives me more than anything is peace of mind. With MarginWiz, now at least I know where my money is going and that we are doing our best to win back what is ours.",
    name: "Jon Reed",
    role: "CEO at Compostic",
    photo: "/img/testimonials/jon.jpeg",
    logo: "/img/testimonials/compostic.png",
    alt: "Compostic",
  },
  {
    text:
      "I see MarginWiz becoming the operating system for CPG brands. They're dialed in on the most important problem first though, which is deductions.",
    name: "Jim DeCicco",
    role: "Co-founder at Super Coffee",
    photo: "/img/testimonials/jim.png",
    logo: "/img/testimonials/super.png",
    alt: "Super Coffee",
  },
  {
    text:
      "Nat and the team care about their mission of helping brands so much, and I'm grateful to work with people whose passion matches mine.",
    name: "Sophia Maroon",
    role: "CEO at Dress It Up Dressing",
    photo: "/img/testimonials/sophia.png",
    logo: "/img/testimonials/dressitup.png",
    alt: "Dress It Up Dressing",
  },
  {
    text:
      "I used to dread doing deductions work, but now I actually enjoy how efficient I can be with MarginWiz and celebrating the repayments when they come through.",
    name: "Rachel Bower",
    role: "Sr. Director of Planning at Bellway",
    photo: "/img/testimonials/rachel.png",
    logo: "/img/testimonials/bellway.png",
    alt: "Bellway",
  },
  {
    text:
      "I was unsure that an upstart logo could handle the complexity of our deductions, but MarginWiz met our needs perfectly and it has been a game changer for us.",
    name: "Walter Gordon",
    role: "Global Vice President at Diageo",
    photo: "/img/testimonials/walter.png",
    logo: "/img/testimonials/diageo.png",
    alt: "Diageo",
  },
  {
    text: "When I first logged into the portal, it was love at first sight.",
    name: "Sarah Bell",
    role: "Co-founder of Spring & Mulberry",
    photo: "/img/testimonials/sarah_mul.jpeg",
    logo: "/img/testimonials/springandmulberry.png",
    alt: "Spring & Mulberry",
  },
  {
    text:
      "I'm continuously blown away at how quickly the team improves the platform with new integrations and features. I feel so grateful to have found MarginWiz when I did.",
    name: "JP Mackey",
    role: "President at Scharffen Berger Chocolates",
    photo: "/img/testimonials/jp.png",
    logo: "/img/testimonials/scharffen.png",
    alt: "Scharffen Berger Chocolates",
  },
]

export function Testimonials() {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isTransitioning, setIsTransitioning] = useState(false)

  useEffect(() => {
    const interval = setTimeout(() => {
      setIsTransitioning(true)
      setTimeout(() => {
        setCurrentIndex((prev) => (prev + 1) % TESTIMONIALS.length)
        setIsTransitioning(false)
      }, 500)
    }, 5000) // Rotate every 5 seconds
    return () => clearInterval(interval)
  }, [currentIndex])

  function handlePhotoClick(index: number) {
    setIsTransitioning(true)
    setTimeout(() => {
      setCurrentIndex(index)
      setIsTransitioning(false)
    }, 500)
  }

  let longest_text = TESTIMONIALS.reduce((a, b) => a.length > b.text.length ? a : b.text, TESTIMONIALS[0].text)
  let testimonial = TESTIMONIALS[currentIndex]
  return (
    <div className="min-h-1/2 bg-orange-100 py-16">
      <div className="min-h-1/2 bg-indigo-500 rounded-[50px] px-8 pt-16 py-8 max-w-6xl mx-auto relative flex flex-col">
        {/* Testimonial Content */}
        <div class="flex mx-auto">
          <StarIcon class="h-6 text-lime-200" />
          <StarIcon class="h-6 text-lime-200" />
          <StarIcon class="h-6 text-lime-200" />
          <StarIcon class="h-6 text-lime-200" />
          <StarIcon class="h-6 text-lime-200" />
        </div>

        <div class={classes("transition-opacity duration-300", isTransitioning ? "opacity-0" : "opacity-100")}>
          <div class="text-white text-4xl text-center max-w-3xl mx-auto relative mt-9 font-display">
            <div class="absolute">{testimonial.text}</div>
            <div class="opacity-0">{longest_text}</div>
          </div>
          <div className="text-center text-white mt-4">
            <div className="text-xl font-display">{testimonial.name}</div>
            <div className="text-sm opacity-75">{testimonial.role}</div>
            <img
              src={testimonial.logo}
              alt={testimonial.alt}
              className="h-12 object-contain rounded-xl mx-auto mt-2"
            />
          </div>
        </div>

        {/* Photo Navigation */}
        <div class="flex justify-center gap-4 mt-12">
          {TESTIMONIALS.map((testimonial, index) => (
            <button
              key={index}
              onClick={() => handlePhotoClick(index)}
              className={classes(
                "transition-transform duration-300 rounded-md",
                currentIndex === index ? "scale-110 ring-4 ring-white" : "scale-100 opacity-50 hover:opacity-75",
              )}
            >
              <img
                src={testimonial.photo}
                alt={testimonial.name}
                className="w-8 h-8 rounded-md object-cover"
              />
            </button>
          ))}
        </div>
      </div>
    </div>
  )
}

const TIERS = [
  {
    name: "Emerging",
    revenue: "Brands < $3m annual revenue",
    description: "Start fighting deductions as far back as a year with the platform made for emerging brands like you.",
  },
  {
    name: "Growth",
    revenue: "Brands < $20m annual revenue",
    price: "$20",
    description: "Automate your deductions and accounting workflows, with platform and service that guarantees an ROI.",
  },
  {
    name: "Scale",
    revenue: "Brands > $20m annual revenue",
    description:
      "For larger brands with many distributors and custom needs, we are excited to work together to optimize the difference between your gross and net revenue.",
  },
]

export function PricingTiers() {
  const [selectedTier, setSelectedTier] = useState(0)

  return (
    <div class="space-y-4 mt-8">
      {TIERS.map((tier, index) => (
        <div
          key={tier.name}
          class={classes(
            index < TIERS.length - 1 && "border-b border-gray-400",
          )}
        >
          <div
            class="p-4 flex justify-between items-center"
            onClick={() => setSelectedTier(index)}
          >
            <div>
              <div class="font-display text-xl">{tier.name}</div>
              <div class="text-gray-600">{tier.revenue}</div>
            </div>
            <div class="w-6">
              {selectedTier === index
                ? <MinusCircle class="bg-indigo" />
                : <PlusCircle class="bg-indigo" />}
            </div>
          </div>

          {/* Expandable content */}
          <div
            class={classes(
              "transition-all duration-300 overflow-hidden",
              selectedTier === index ? "max-h-96" : "max-h-0",
            )}
          >
            <p class="text-gray-700 mb-4 mx-4">{tier.description}</p>
          </div>
        </div>
      ))}
    </div>
  )
}

export function Pricing() {
  return (
    <div class="bg-sky-100 py-12">
      <div class="max-w-7xl mx-auto flex">
        <div
          class="bg-cover bg-center bg-no-repeat rounded-4xl basis-1/2 hidden lg:flex items-center justify-center"
          style={{ backgroundImage: 'url("/img/landing/chips_background.jpg")' }}
        >
          <img src="/img/landing/chips.png" class="w-2/3 my-12 " />
        </div>
        <div class="basis-1/2 px-12 py-4">
          <div class="uppercase text-sm">Simple Pricing</div>
          <div class="text-5xl font-display mt-3">
            No hidden agenda,<br /> only revealing hidden costs.
          </div>
          <div class="my-6">
            Our pricing is based on volume of deductions, dollars saved, and number of distribution sources.
          </div>
          <a
            class="px-4 py-2 text-gray-900 border border-indigo bg-indigo rounded-full text-white uppercase text-sm font-bold"
            href={DEMO_LINK}
          >
            Book a demo
          </a>
          <PricingTiers />
        </div>
      </div>
    </div>
  )
}

const BIOS = [
  {
    color: "bg-yellow-400",
    name: "Kurt Wolf",
    role: "CEO",
    picture: "/img/team/kurt.webp",
    linkedin: "https://www.linkedin.com/in/kurtbuilds",
    logos: [],
    summary: `Kurt is an entrepreneur at heart and engineer by training.
      He got his first exposure to CPG leading technology for a merchandising work marketplace,
      and built the banking industry's most advanced automated negotiation platform at his last startup.
      He's now thrilled to bring that experience to fight on behalf of food & beverage entrepreneurs to win dollars back to their bottom line.`,
  },
  {
    color: "bg-lime-200",
    name: "Nat Noone",
    role: "CRO",
    picture: "/img/team/nat.png",
    linkedin: "https://www.linkedin.com/in/nat-noone-3211997/",
    logos: [],
    summary: `Nat brings over 30 years of CPG experience from every angle.
      He grew up on a truck with a juice company that was bought by Odwalla (later bought by Coca-Cola where he held several leadership positions).
      Most recently, he founded a national refrigerated DSD company and Wave Soda. He's thrilled to deliver the ultimate solution to this painful problem he's felt first-hand in every role over his long career.`,
  },
  {
    color: "bg-salmon",
    name: "Joey Caffrey",
    role: "CTO",
    picture: "/img/team/joey.webp",
    linkedin: "https://www.linkedin.com/in/joey-caffrey-58155687/",
    logos: [],
    summary:
      `Joey loves building technology to solve problems he's seen in his own life, so when he learned how painful deductions are for food & beverage brands, he knew he had to do something about it.
     His passion for helping food & beverage entrepreneurs succeed is further fueled by the fact that his parents have worked in CPG analytics & operations (Nielsen, Advantage) for decades.`,
  },
]

export function Team() {
  return (
    <div class="pt-12 bg-orange-100">
      <div class="uppercase text-center text-sm">Company</div>
      <div class="font-display text-5xl text-center mt-6">
        Your Team of Wiz Kids
      </div>
      <div class="mt-6 lg:w-lg mx-auto text-center">
        We have a mix of CPG and tech experience, but brands choose us because we obsess over helping them get their
        money back.
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 p-4 max-w-7xl mx-auto mt-6">
        {BIOS.map((member, index) => (
          <div class={classes(member.color, "rounded-4xl p-8 flex flex-col")}>
            <div class="flex justify-between">
              <div>
                <div class="font-display text-3xl">{member.name}</div>
                <div>{member.role}</div>
              </div>
              <img
                src={member.picture}
                alt={member.name}
                className="w-16 h-full object-cover rounded-full bg-white"
              />
            </div>
            <div class="mt-6 grow-1">{member.summary}</div>
            <a href={member.linkedin} target="_blank" rel="noopener noreferrer" class="mt-4">
              <img src="/img/team/linkedin.png" alt="LinkedIn" className="w-8 h-8 bg-white rounded-sm" />
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

export function Newsletter() {
  return (
    <div class="flex">
      <div>newsletter</div>
    </div>
  )
}

export function Landing() {
  let year = new Date().getFullYear()
  return (
    <div class="flex flex-col min-h-full w-full">
      <div class="landing-container font-content">
        <Hero2 />
        <Benefits />
        <Testimonials />
        <Pricing />
        <Team />
        <div className="bg-orange-100 py-8 text-center text-gray-600 text-sm">
          <div className="max-w-7xl mx-auto px-4">
            <p>© {year} MarginWiz. All rights reserved.</p>
            <p className="mt-2">
              <a href="/privacy.html" className="text-indigo-600 hover:underline">Privacy Policy</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
