import { api_fetch } from "@/api/client";
import { Message } from "@/api/deduction.tsx";
import { entity } from 'simpler-state';

interface MessageState {
  messages: Message[];
  isLoading: boolean;
  error: string | null;
  lastRefresh: Date | null;
  readStatus: Record<string, string>; // messageId -> read_at timestamp
}

export const messageState = entity<MessageState>({
  messages: [],
  isLoading: false,
  error: null,
  lastRefresh: null,
  readStatus: {},
});

let pollInterval: ReturnType<typeof setInterval> | null = null;

export const fetchMessages = async () => {
  try {
    messageState.set(state => ({ ...state, isLoading: true, error: null }));
    
    const result = await api_fetch<Message[]>("/messages");
    
    if (!result.ok) {
      throw new Error(result.error.message);
    }

    messageState.set({ 
      messages: result.value.data,
      lastRefresh: new Date(),
      isLoading: false,
      error: null,
      readStatus: messageState.get().readStatus // Preserve read status
    });
  } catch (err) {
    messageState.set(state => ({ 
      ...state, 
      error: (err as Error).message, 
      isLoading: false 
    }));
  }
};

export const markMessageRead = async (messageId: string) => {
  try {
    console.log('Marking message as read:', messageId); // Add logging
    const result = await api_fetch<void>(`/message/${messageId}/read`, { 
      method: "POST",
      body: {} 
    });
    
    if (!result.ok) {
      throw new Error(result.error.message);
    }

    // Update local state with the new read status
    messageState.set(state => ({
      ...state,
      messages: state.messages.map(message => 
        message.id === messageId 
          ? { ...message, read_at: new Date().toISOString() }
          : message
      )
    }));
  } catch (err) {
    console.error('Failed to mark message as read:', err);
  }
};

export const startPolling = () => {
  if (pollInterval) return;
  
  pollInterval = setInterval(() => {
    fetchMessages();
  }, 2 * 60 * 1000); // Poll every 2 minutes
};

export const stopPolling = () => {
  if (pollInterval) {
    clearInterval(pollInterval);
    pollInterval = null;
  }
};
