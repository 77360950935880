// @ts-nocheck
import * as TooltipPrimitive from "@radix-ui/react-tooltip"
import * as React from "react"

import { cn } from "@/utils"

// Create a wrapper component that provides the tooltip context
const TooltipProvider = TooltipPrimitive.Provider

// Root component that manages the open state
const Tooltip = ({ delayDuration = 300, ...props }) => (
  <TooltipPrimitive.Root delayDuration={delayDuration} {...props} />
)

// The element that triggers the tooltip
const TooltipTrigger = TooltipPrimitive.Trigger

// Portal to render the tooltip outside the DOM hierarchy
const TooltipPortal = TooltipPrimitive.Portal

// The actual tooltip content
const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      "z-50 overflow-hidden rounded-md border bg-popover px-3 py-1.5 text-sm text-popover-foreground shadow-md animate-in fade-in-50 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
      className
    )}
    {...props}
  />
))
TooltipContent.displayName = TooltipPrimitive.Content.displayName

// Arrow component for the tooltip
const TooltipArrow = TooltipPrimitive.Arrow

export {
  Tooltip, TooltipArrow, TooltipContent, TooltipPortal, TooltipProvider, TooltipTrigger
}

