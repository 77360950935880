import { fetch_query_data } from "@/api"
import { LoadingSpinner } from "@/app/loading"
import { BreakdownData, FALLBACK_COLORS } from "@/components/breakdown"
import { Badge } from "@/components/ui/badge"
import { StatusFlag } from "@/deductions/status_state"
import { DataTableState } from "@/deductions/table_state"
import { putTransactionType } from "@/global_filter"
import { currencyFormatter, index_map, useAsyncEffect } from "@/utils"
import { BarList } from "@tremor/react"
import { useState } from "preact/compat"

const CATEGORY_DOMAINS = {
  "Sales": {
    color: "emerald",
    categories: ["Slotting", "Free Fill", "Retailer Promotion", "Distributor Promotion"]
  },
  "Operations": {
    color: "sky",
    categories: ["Operational Issues", "Freight", "Spoilage"]
  },
  "Finance": {
    color: "amber",
    categories: ["Contractual Fees", "Invoice Discrepancy", "Invoice Adjustment", "Misc"]
  }
} as const

// Update category colors to use domain colors
export const CATEGORY_COLORS = Object.entries(CATEGORY_DOMAINS).reduce((colors, [domain, config]) => {
  config.categories.forEach(category => {
    colors[category] = config.color
  })
  return colors
}, {} as Record<string, string>)

interface CategoryData extends BreakdownData {
  count: number
  originalName: string
  domain: keyof typeof CATEGORY_DOMAINS
}

function GroupHeader({ 
  domain, 
  data, 
  isFirst 
}: { 
  domain: keyof typeof CATEGORY_DOMAINS, 
  data: CategoryData[], 
  isFirst?: boolean 
}) {
  const total = data.reduce((sum, item) => sum + item.value, 0)
  return (
    <div className={`mb-2 flex items-center justify-between ${isFirst ? 'mt-2' : 'mt-6'}`}>
      <div className="flex items-center gap-2">
        <h3 className="font-medium">{domain}</h3>
        <Badge variant="outline">{currencyFormatter(total)}</Badge>
      </div>
    </div>
  )
}

export function CategoryBreakdown() {
  const [data, setData] = useState<CategoryData[]>([])
  const [loading, setLoading] = useState(true)
  const [start, end, distributor] = DataTableState.use(u => [u.startDate, u.endDate, u.distributor])
  const statusFlag = StatusFlag.use(sf => sf!)

  useAsyncEffect(async () => {
    let res = await fetch_query_data("category", { start, end, distributor })
    setLoading(false)
    if (!res.ok) {
      setData([])
      throw new Error("Failed to fetch data")
    }

    let { rows, headers } = res.value
    let headerIndex = index_map(headers)

    rows = rows.filter(row => row[headerIndex.name] !== "Total")
    rows.sort((a, b) => (b[headerIndex.value] as number) - (a[headerIndex.value] as number))

    let data = rows.map((row) => {
      const name = row[headerIndex.name] as keyof typeof CATEGORY_COLORS
      const count = row[headerIndex.count] as number
      const domain = Object.entries(CATEGORY_DOMAINS).find(([_, config]) => 
        // @ts-ignore
        config.categories.includes(name)
      )?.[0] as keyof typeof CATEGORY_DOMAINS

      return {
        name: `${name} (${count})`,
        originalName: name,
        value: Math.abs(row[headerIndex.value] as number),
        count,
        color: CATEGORY_COLORS[name] || FALLBACK_COLORS[0],
        domain
      }
    })
    setData(data)
  }, [start, end, distributor, statusFlag])

  if (loading || !data) {
    return <LoadingSpinner color="blue" />
  }

  const groupedData = Object.entries(CATEGORY_DOMAINS).map(([domain]) => ({
    domain: domain as keyof typeof CATEGORY_DOMAINS,
    items: data.filter(item => item.domain === domain)
  }))

  return (
    <div className="flex">
      <div className="mt-2 max-w-2xl grow">
        {groupedData.map(({ domain, items }, index) => (
          <div key={domain}>
            <GroupHeader domain={domain} data={items} isFirst={index === 0} />
            <BarList
              data={items}
              className="mt-2"
              valueFormatter={currencyFormatter}
              onValueChange={(item: CategoryData) => {
                DataTableState.set(s => ({
                  ...s,
                  search: "",
                  transactionTypes: putTransactionType(s.transactionTypes, "deduction"),
                  columnFilters: [{ id: "category", value: item.originalName }],
                  pagination: { pageIndex: 0, pageSize: s.pagination.pageSize },
                }))
              }}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

