import { DeductionResponse, Dispute, Message } from "@/api/deduction.tsx"
import { useEffect, useState } from "preact/compat"

import { Backup } from "@/api/backup.tsx"
import { api_fetch, api_fetch_throws, data_or_throw } from "@/api/client.tsx"
import { User } from "@/api/user.tsx"
import { LoadingSpinner } from "@/app/loading"
import { DetailNavbar } from "@/app/navbar"
import { Button } from "@/components/ui/button.tsx"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover.tsx"
import { toast } from "@/components/ui/use-toast.ts"
import { AssignmentForm } from "@/deductions/table/assignment_form.tsx"
import type { Option } from "@/utils"
import { displayFormattedDistributor, exactCurrencyFormatter, useFetch } from "@/utils"
import { CircleCheck, ShieldAlert } from "lucide-react"
import { Link, useLocation } from "wouter-preact"
import { StatusFlag } from "../status_state.tsx"
import { getDisputeAction } from "../table/actions/dispute_utils"
import { FileDisputeDrawer } from "../table/actions/file_dispute_drawer.tsx"
import { backup_columns } from "./backup_columns.tsx"
import { BackupTable } from "./backup_table.tsx"
import { Conversations } from "./conversations.tsx"
import { DeductionCard } from "./deduction_card.tsx"
import { DeductionFile, Files } from "./files.tsx"
import { Updates } from "./updates.tsx"

export function getFiles(
  deduction: DeductionResponse,
  messages: Message[],
): DeductionFile[] {
  let files: DeductionFile[] = []
  for (const message of messages) {
    // if (message.sequence === 0) {
    //   continue
    // }
    for (const attachment of message.attachments) {
      let s3_uri = attachment.s3_uri
      if (s3_uri == deduction.check_s3_uri) {
        // bullshit hack to try to have the backup date be more accurate.
        // see e.g. /deduction/01j47kppgp1pkdrg4tpw1h_za0a
        continue
      }
      if (s3_uri === deduction.backup_s3_uri) {
        // bullshit hack to try to have the backup date be more accurate.
        // see e.g. /deduction/01j47kppgp1pkdrg4tpw1h_za0a
        deduction.created_at = message.date
        continue
      }
      files.push({
        display_name: attachment.s3_uri.split("/").pop() || "Email Attachment",
        date: message.date,
        s3_uri,
        deduction_id: deduction.id,
      })
    }
  }
  const distributor = displayFormattedDistributor(deduction.source, deduction.original_source)
  if (deduction.backup_s3_uri) {
    files.push({
      display_name: `${distributor} Backup`,
      // TODO should be date of the backup request.
      date: deduction.created_at,
      s3_uri: deduction.backup_s3_uri,
      deduction_id: deduction.id,
    })
  }
  if (deduction.check_s3_uri) {
    files.push({
      display_name: `${distributor} Check`,
      date: deduction.check_date,
      s3_uri: deduction.check_s3_uri,
      deduction_id: deduction.id,
    })
  }
  if (deduction.user_files) {
    for (const user_file of deduction.user_files) {
      files.push({
        display_name: user_file.display_name,
        date: user_file.date,
        s3_uri: user_file.s3_uri,
        deduction_id: deduction.id,
        actor_name: user_file.actor_name,
      })
    }
  }
  files = files.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
  files = files.filter((f, i, self) => i === self.findIndex(f2 => f.s3_uri === f2.s3_uri))
  return files
}

interface DeductionDetailProps {
  params: { deduction_id: string }
}

export function DeductionDetail({ params }: DeductionDetailProps) {
  const deduction_id = params.deduction_id
  const statusFlag = StatusFlag.use(sf => sf!)

  let [deduction, loading, , setDeduction] = useFetch<Option<DeductionResponse>>(null, async () => {
    let res = await api_fetch<DeductionResponse>(`/deduction/${deduction_id}`)
    if (!res.ok) {
      if (res.error.status === 403) {
        setLocation(`/login?next=${location.pathname}`)
        throw new Error("Unauthorized")
      }
    }
    return data_or_throw(res)
  }, [deduction_id, statusFlag])
  
  let [backup, backupLoading] = useFetch([], () => (
    api_fetch_throws<Backup[]>(`/deduction/${deduction_id}/backup`)
  ), [deduction_id])

  let [messages, messagesLoading] = useFetch([], () => (
    api_fetch_throws<Message[]>(`/deduction/${deduction_id}/messages`)
  ), [deduction_id, statusFlag])
  
  let [dispute, disputeLoading] = useFetch<Option<Dispute>>(null, async () => {
    if (!deduction || !deduction.dispute_id) {
      return null
    }
    return await api_fetch_throws<Dispute>(`/dispute/${deduction.dispute_id}`)
  }, [deduction?.dispute_id, statusFlag])

  const [files, setFiles] = useState<DeductionFile[]>([])

  let [, setLocation] = useLocation()

  if (!deduction || loading || messagesLoading) {
    return <LoadingSpinner color="plue-500" />
  }

  useEffect(() => {
    setFiles(getFiles(Object.assign({}, deduction), messages))
  }, [deduction, messages])

  function handleDeductionUpdate(updated: DeductionResponse) {
    setDeduction(updated)
  }

  let title = deduction.invoice_number
  if (title.match(/^[0-9]+$/)) {
    title = `#${title}`
  }
  let breadcrumbs = [
    { label: "Deductions", href: "/deductions" },
    { href: "#", label: deduction.invoice_number },
  ]
  return (
    <div key={deduction_id}>
      <DetailNavbar items={breadcrumbs} />
      <DetailActions title={title} deduction={deduction} dispute={dispute} />
      {deduction && (
        <DeductionCard
          deduction={deduction}
          onUpdate={handleDeductionUpdate}
        />
      )}
      {/* TODO: stack the updates and files on top of each other. make convos wider */}
      <div class="columns-2 gap-4 mt-4">
        <div class="flex flex-col gap-4">
          <Updates deduction={deduction} backup={backup[0]} messages={messages} dispute={dispute} />
          <Files
            files={files}
            deductionId={deduction.id}
            orgId={deduction.org_id}
            source={deduction.source}
            invoiceNumber={deduction.invoice_number}
          />
        </div>
        <Conversations deduction={deduction} messages={messages} />
      </div>
      <div class="w-full min-h-44">
        <BackupTable loading={backupLoading} columns={backup_columns} data={backup} />
      </div>
    </div>
  )
}

function buildDisputeAction(dispute: Option<Dispute>, deduction: DeductionResponse) {
  let action = getDisputeAction(deduction, dispute)
  if (!action) {
    return null
  }
  if (action.type === "active") {
    let amount = exactCurrencyFormatter(dispute!.amount)
    return (
      <span className="text-green-600 flex items-center">
        <ShieldAlert className="w-4 mr-1" />
        Disputed {amount && `(${amount})`}
      </span>
    )
  } else if (action.type === "can_file") {
    if (action.url) {
      return (
        <Button
          variant="tertiary"
          onClick={() => window.open(action.url!)}
        >
          <ShieldAlert className="w-4 mr-1" />
          {action.cta}
        </Button>
      )
    } else {
      return (
        <FileDisputeDrawer
          deduction={deduction}
          trigger={
            <Button variant="tertiary">
              <ShieldAlert className="w-4 mr-1" />
              {action.cta}
            </Button>
          }
        />
      )
    }
  } else if (action.type === "repayment") {
    return (
      <Link href={`/deduction/${action.id}`}>
        <Button variant="outline" className="space-x-2">
          <span>View Original Deduction</span>
          {/* <ExternalLinkIcon className="h-4 w-4" /> */}
        </Button>
      </Link>
    )
  } else if (action.type === "error") {
    return (
      <span className="text-red-500 flex items-center">
        <ShieldAlert className="w-4 mr-1" />
        {action.message}
      </span>
    )
  } else {
    throw new Error("Unreachable")
  }
}

export function DetailActions({ 
  title, 
  deduction, 
  dispute 
}: { 
  title: string; 
  deduction: DeductionResponse;
  dispute: Option<Dispute>;
}) {
  const [assignmentPopoverOpen, setAssignmentPopoverOpen] = useState(false)
  const [users] = useFetch([], async () => (
    api_fetch_throws<User[]>("/users")
  ), [])

  async function handleTaskAssignment(values: any) {
    let body = [{
      deduction_id: deduction.id,
      user_id: values.user_id,
      task_type: values.taskType,
      backup_type: values.backupType,
      note: values.note,
      is_time_sensitive: values.isTimeSensitive,
    }]

    let res = await api_fetch("/tasks", { body })

    if (!res.ok) {
      toast({
        title: "Error",
        description: "Failed to assign task",
      })
      console.error(`Failed to assign task`)
      return
    }

    toast({
      title: "Success",
      description: "Task assigned successfully",
    })
    setAssignmentPopoverOpen(false)
    StatusFlag.set(flag => !flag)
  }

  return (
    <div className="flex items-center justify-between py-2 border-b ">
      <h1 className="text-2xl">{title}</h1>
      <div className="flex gap-2 ml-auto">
        <Popover
          modal={true}
          open={assignmentPopoverOpen}
          onOpenChange={setAssignmentPopoverOpen}
        >
          <PopoverTrigger
            onClick={(e: MouseEvent) => {
              e.preventDefault()
              e.stopImmediatePropagation()
              setAssignmentPopoverOpen(!assignmentPopoverOpen)
            }}
          >
            <Button variant="outline" className="bg-yellow-500-opacity-10">
              <CircleCheck className="h-4 mr-1 text-yellow-800" />
              <span className="text-yellow-800">Assign task</span>
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-[324px]">
            <AssignmentForm users={users} onSubmit={handleTaskAssignment} />
          </PopoverContent>
        </Popover>
        {buildDisputeAction(dispute, deduction)}
      </div>
    </div>
  )
}

if (import.meta.vitest) {
  describe("getFiles function", () => {
    function mockDeduction() {
      return {
        id: "deduction1",
        source: "email",
        backup_s3_uri: "s3://backup/123",
        check_s3_uri: "s3://check/456",
        created_at: "2022-01-01",
        check_date: "2022-01-02",
      }
    }
    it("should process email attachments correctly", () => {
      const messages = [
        {
          date: "2022-01-03",
          attachments: [{ s3_uri: "s3://attachments/789" }, { s3_uri: "s3://attachments/101" }],
        },
      ]
      // @ts-ignore
      const result = getFiles(mockDeduction(), messages)
      expect(result).toHaveLength(4) // 2 attachments + backup + check
      expect(result[0]).toHaveProperty("display_name", "789")
      expect(result[1]).toHaveProperty("display_name", "101")
    })

    it("should skip email attachments that match backup or check URIs", () => {
      const messages = [
        {
          date: "2022-01-03",
          attachments: [
            { s3_uri: "s3://backup/123" },
            { s3_uri: "s3://check/456" },
            { s3_uri: "s3://attachments/789" },
          ],
        },
      ]
      // @ts-ignore
      const result = getFiles(mockDeduction(), messages)
      expect(result).toHaveLength(3) // 1 attachment + backup + check
      expect(result[0]).toHaveProperty("display_name", "789")
    })

    it("should process dispute messages correctly", () => {
      const messages = [
        {
          date: "2022-01-04",
          attachments: [
            { s3_uri: "s3://messages/123", id: "123" },
            { s3_uri: "s3://messages/456", id: "456" },
          ],
          sequence: 1,
        },
      ]
      // @ts-ignore
      const result = getFiles(mockDeduction(), messages)
      expect(result).toHaveLength(4) // 2 message attachments + backup + check
      expect(result[0]).toHaveProperty("display_name", "123")
      expect(result[1]).toHaveProperty("display_name", "456")
    })

    it("should skip dispute messages with sequence 0", () => {
      const messages = [
        {
          date: "2022-01-04",
          attachments: [
            { s3_uri: "s3://messages/123" },
          ],
          sequence: 0,
        },
      ]
      // @ts-ignore
      const result = getFiles(mockDeduction(), messages)
      expect(result).toHaveLength(3) // Only backup + check
    })

    it("should include backup and check files when present", () => {
      // @ts-ignore
      const result = getFiles(mockDeduction(), [])
      expect(result).toHaveLength(2)
      expect(result[0]).toHaveProperty("display_name", "Email Check")
      expect(result[1]).toHaveProperty("display_name", "Email Backup")
    })

    it("should handle missing backup or check URIs", () => {
      const deductionWithoutURIs = { ...mockDeduction, backup_s3_uri: null, check_s3_uri: null }
      // @ts-ignore
      const result = getFiles(deductionWithoutURIs, [], [])
      expect(result).toHaveLength(0)
    })

    it("should sort files by date in descending order", () => {
      const messages = [
        { date: "2022-01-03", attachments: [{ s3_uri: "s3://attachments/789" }] },
        { date: "2022-01-05", attachments: [{ s3_uri: "s3://attachments/101" }] },
      ]
      // @ts-ignore
      const result = getFiles(mockDeduction(), messages)
      expect(result).toHaveLength(4)
      expect(result[0]).toHaveProperty("date", "2022-01-05")
      expect(result[1]).toHaveProperty("date", "2022-01-03")
      expect(result[2]).toHaveProperty("date", "2022-01-02")
      expect(result[3]).toHaveProperty("date", "2022-01-01")
    })

    it("should handle empty inputs", () => {
      // @ts-ignore
      let deduction: DeductionResponse = {
        ...mockDeduction(),
        backup_s3_uri: null,
        check_s3_uri: null,
      }
      const result = getFiles(deduction, [])
      expect(result).toHaveLength(0)
    })

    it('should use "Email Attachment" as display name when s3_uri is empty', () => {
      const emails = [
        {
          date: "2022-01-03",
          attachments: [{ s3_uri: "" }],
        },
      ]
      // @ts-ignore
      const result = getFiles(mockDeduction(), emails, [])
      expect(result).toHaveLength(3) // 1 attachment + backup + check
      expect(result[0]).toHaveProperty("display_name", "Email Attachment")
    })
  })
}
