import { Button } from "@/components/ui/button"
import { Command, CommandEmpty, CommandGroup, CommandItem, CommandList } from "@/components/ui/command"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { DataTableState } from "@/deductions/table_state"
import { ActiveFilterIcon } from "@/global_filter/active_filter_icon"
import { Check } from "lucide-react"
import { useState } from "preact/hooks"

export const TRANSACTION_TYPES = [
  { value: "invoice", label: "Invoices" },
  { value: "deduction", label: "Deductions" },
  { value: "prepayment", label: "Prepayments" },
  { value: "repayment", label: "Repayments" },
  { value: "pending", label: "Pending" },
] as const

export type TransactionType = typeof TRANSACTION_TYPES[number]["value"]

interface TransactionTypeFilterProps {
  className?: string
}

export function TransactionTypeFilter({ className }: TransactionTypeFilterProps) {
  const [open, setOpen] = useState(false)
  const types = DataTableState.use(s => s.transactionTypes)

  function getButtonText(types: TransactionType[]) {
    if (types.length === 0) {
      return "Select types..."
    } else if (types.length === 1) {
      return TRANSACTION_TYPES.find((t: typeof TRANSACTION_TYPES[number]) => t.value === types[0])?.label
    } else if (types.length === TRANSACTION_TYPES.length) {
      return "All Types"
    } else {
      return "Multiple Types"
    }
  }

  let active = types.length < TRANSACTION_TYPES.length
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant="outline" role="combobox" aria-expanded={open} className={className}>
          {getButtonText(types)}
          {active && <ActiveFilterIcon/>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <Command>
          <CommandList>
            <CommandEmpty>No type found.</CommandEmpty>
            <CommandGroup>
              {TRANSACTION_TYPES.map((type) => (
                <CommandItem
                  key={type.value}
                  onSelect={() => {
                    const newSelection = types.includes(type.value)
                      ? types.filter(t => t !== type.value)
                      : [...types, type.value]
                    DataTableState.set(s => ({ ...s, transactionTypes: newSelection }))
                    setOpen(true)
                  }}
                >
                  <Check
                    className={`mr-2 h-4 w-4 ${types.includes(type.value) ? "opacity-100" : "opacity-0"}`}
                  />
                  {type.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}


export function putTransactionType(types: TransactionType[], type: TransactionType) {
  if (types.includes(type)) {
    return types
  } else {
    return [...types, type]
  }
}
