import { Sheet, SheetContent } from '@/components/ui/sheet';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Activity, activityState, fetchActivities, startPolling, stopPolling } from '@/stores/activity';
import { classes } from '@/utils';
import { Bell } from 'lucide-react';
import { useEffect } from 'preact/hooks';
import { ActivityList } from './activity-list';
import { MessageList } from './message-list';
import { NoteList } from './note-list';

interface ActivityFeedProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  isStandalone?: boolean;
  onClose?: () => void;
}

interface TabConfig {
  value: string;
  label: string;
  component: (props: { 
    isStandalone: boolean; 
    onClose?: () => void;
    activities?: Activity[];
    isLoading?: boolean;
  }) => JSX.Element;
}

const TABS: TabConfig[] = [
  {
    value: 'internal',
    label: 'Internal',
    component: NoteList,
  },
  {
    value: 'external',
    label: 'External',
    component: MessageList,
  },
  {
    value: 'activity',
    label: 'Activity',
    component: ({ isStandalone, onClose, activities, isLoading }) => (
      <ActivityList
        activities={activities || []}
        isLoading={isLoading || false}
        isStandalone={isStandalone}
        onClose={onClose}
      />
    ),
  },
];

function ActivityHeader({ lastRefresh }: { lastRefresh: Date | null }) {
  return (
    <div className="flex items-center justify-between p-6 border-b">
      <div className="flex items-center gap-2">
        <Bell className="h-5 w-5" />
        <h1 className="text-2xl font-semibold">Notification / Activity Feed</h1>
      </div>
      {/* {lastRefresh && (
        <span className="text-sm text-muted-foreground">
          Updated {formatDistanceToNow(lastRefresh, { addSuffix: true })}
        </span>
      )} */}
    </div>
  );
}

export function ActivityFeed({ open, onOpenChange, isStandalone = false, onClose }: ActivityFeedProps) {
  const { activities, isLoading, error, lastRefresh } = activityState.use();

  useEffect(() => {
    if (isStandalone || open) {
      fetchActivities();
      startPolling();
    } else if (!isStandalone && !open) {
      stopPolling();
    }
    return () => stopPolling();
  }, [open, isStandalone]);

  const content = (
    <>
      <ActivityHeader lastRefresh={lastRefresh} />
      
      <Tabs defaultValue="internal" className="w-full">
        <TabsList 
          className={classes(
            "grid w-full grid-cols-3 mb-2",
            "border-0 bg-transparent",
            "[&>*]:rounded-none [&>*]:border-b-2 [&>*]:border-transparent",
            "[&>*[data-state=active]]:border-plue-900 [&>*[data-state=active]]:shadow-none"
          )}
        >
          <TabsTrigger value="internal">Internal</TabsTrigger>
          <TabsTrigger value="external">External</TabsTrigger>
          <TabsTrigger value="activity">Activity</TabsTrigger>
        </TabsList>

        {TABS.map(({ value, component: Component }) => (
          <TabsContent key={value} value={value} className="mt-0">
            {value === 'activity' && error ? (
              <div className="p-6 text-destructive">{error}</div>
            ) : (
              <Component
                activities={value === 'activity' ? activities : undefined}
                isLoading={value === 'activity' ? isLoading : false}
                isStandalone={isStandalone}
                onClose={!isStandalone ? onOpenChange?.bind(null, false) : undefined}
              />
            )}
          </TabsContent>
        ))}
      </Tabs>
    </>
  );

  if (isStandalone) {
    return <div className="container py-6">{content}</div>;
  }

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent 
        side="right" 
        className="w-[400px] sm:w-[600px] lg:w-[800px] p-0"
      >
        {content}
      </SheetContent>
    </Sheet>
  );
} 