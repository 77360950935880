import { api_fetch } from "@/api/client"
import { Button } from "@/components/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { classes, useAsyncEffect } from "@/utils"
import { BookOpenCheck, Check, ChevronsUpDown } from "lucide-react"
import { useRef, useState } from "preact/hooks"
import { ReasonCode } from "../api/deduction"

interface AccountingMatchPopoverProps {
  onSelectMatch: (match: ReasonCode) => void
  initialCode?: ReasonCode
}

export const AccountingMatchPopover = ({ onSelectMatch, initialCode }: AccountingMatchPopoverProps) => {
  const [open, setOpen] = useState(false)
  const [selectedCode, setSelectedCode] = useState<ReasonCode | undefined>(initialCode)
  const [codes, setCodes] = useState<ReasonCode[]>([])
  const commandListRef = useRef<HTMLDivElement>(null)

  useAsyncEffect(async () => {
    const res = await api_fetch<ReasonCode[]>("/reason_codes", { use_cache: true, method: "GET" })
    if (res.ok) {
      setCodes(
        res.value.data.sort((a, b) => {
          const aAccount = a.expense_account || ""
          const bAccount = b.expense_account || ""

          // Extract numeric prefixes
          const aMatch = aAccount.match(/^(\d+)/)
          const bMatch = bAccount.match(/^(\d+)/)

          // Get numeric values or 0 if no match
          const aNum = aMatch ? parseInt(aMatch[1], 10) : 0
          const bNum = bMatch ? parseInt(bMatch[1], 10) : 0

          // Sort by number first
          if (aNum !== bNum) {
            return aNum - bNum
          }

          // If numbers are equal, sort by full string
          return aAccount.localeCompare(bAccount)
        })
      )
    }
  }, [])

  const getCodeLabel = (code?: ReasonCode): string | undefined => {
    if (!code) return undefined
    return code.name ? `${code.expense_account}: ${code.name}` : code.expense_account
  }

  const handleSelectCode = (codeLabel: string, code: ReasonCode) => {
    setSelectedCode(code)
    onSelectMatch(code)
    setOpen(false)
  }

  const handleSearchChange = () => {
    // Reset scroll position when search input changes
    if (commandListRef.current) {
      commandListRef.current.scrollTop = 0
    }
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button 
          variant="outline" 
          className={`w-full justify-start text-left font-normal ${
            selectedCode ? 'bg-white border-plue-200' : 'text-plue-700'
          }`}
        >
          <div className="flex items-center gap-2 truncate">
            {selectedCode ? (
              <>
                <BookOpenCheck className="h-4 w-4 shrink-0" />
                <span className="truncate">{getCodeLabel(selectedCode)}</span>
              </>
            ) : (
              <>
                <ChevronsUpDown className="h-4 w-4 shrink-0" />
                <span>Select code...</span>
              </>
            )}
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[620px] p-0" align="start">
        <Command>
          <CommandInput placeholder="Search reason code..." onValueChange={handleSearchChange} />
          <CommandList ref={commandListRef}>
            <CommandEmpty>No codes found. Email joey@marginwiz.com to get set up.</CommandEmpty>
            <CommandGroup>
              {codes.map((code, index) => (
                <CommandItem
                  key={code.id}
                  value={code as any}
                  className={`${index % 2 === 0 ? 'bg-plue-50' : ''} ${
                    selectedCode?.id === code.id ? 'bg-plue-100' : ''
                  }`}
                  onSelect={(currentValue: string) => handleSelectCode(currentValue, code)}
                >
                  <Check
                    className={classes(
                      "mr-2 h-4 w-4",
                      selectedCode?.id === code.id ? "opacity-100" : "opacity-0"
                    )}
                  />
                  <span className="truncate">{getCodeLabel(code)}</span>
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}

if (import.meta.vitest) {
  describe("AccountingMatchPopover sorting", () => {
    const createMockReasonCode = (expense_account: string, name = "Test"): ReasonCode => ({
      id: "1",
      expense_account,
      name,
      org_id: "1",
      code_type: "deduction",
      description: "",
    })

    it("should sort by numeric prefix first", () => {
      const unsorted = [
        createMockReasonCode("4620 Tradespend:Promotions:Scanbacks"),
        createMockReasonCode("4010 Gross Revenue : Sales Revenue"),
        createMockReasonCode("6500 Expense : Sales & Marketing"),
      ]

      const sorted = unsorted.sort((a, b) => {
        const aAccount = a.expense_account || ""
        const bAccount = b.expense_account || ""

        const aMatch = aAccount.match(/^(\d+)/)
        const bMatch = bAccount.match(/^(\d+)/)

        const aNum = aMatch ? parseInt(aMatch[1], 10) : 0
        const bNum = bMatch ? parseInt(bMatch[1], 10) : 0

        if (aNum !== bNum) {
          return aNum - bNum
        }

        return aAccount.localeCompare(bAccount)
      })

      expect(sorted.map(x => x.expense_account)).toEqual([
        "4010 Gross Revenue : Sales Revenue",
        "4620 Tradespend:Promotions:Scanbacks",
        "6500 Expense : Sales & Marketing",
      ])
    })

    it("should sort alphabetically when numeric prefixes are the same", () => {
      const unsorted = [
        createMockReasonCode("46201 Tradespend:Promotions:Scanbacks"),
        createMockReasonCode("46200 Tradespend:Promotions:Scanbacks"),
        createMockReasonCode("46102 Tradespend:Promotions:Billbacks"),
        createMockReasonCode("46101 Tradespend:Promotions:Billbacks"),
      ]

      const sorted = unsorted.sort((a, b) => {
        const aAccount = a.expense_account || ""
        const bAccount = b.expense_account || ""

        const aMatch = aAccount.match(/^(\d+)/)
        const bMatch = bAccount.match(/^(\d+)/)

        const aNum = aMatch ? parseInt(aMatch[1], 10) : 0
        const bNum = bMatch ? parseInt(bMatch[1], 10) : 0

        if (aNum !== bNum) {
          return aNum - bNum
        }

        return aAccount.localeCompare(bAccount)
      })

      expect(sorted.map(x => x.expense_account)).toEqual([
        "46101 Tradespend:Promotions:Billbacks",
        "46102 Tradespend:Promotions:Billbacks",
        "46200 Tradespend:Promotions:Scanbacks",
        "46201 Tradespend:Promotions:Scanbacks",
      ])
    })

    it("should handle missing expense accounts", () => {
      const unsorted = [
        createMockReasonCode(""),
        createMockReasonCode("4010 Gross Revenue"),
        createMockReasonCode(undefined as any),
      ]

      const sorted = unsorted.sort((a, b) => {
        const aAccount = a.expense_account || ""
        const bAccount = b.expense_account || ""

        const aMatch = aAccount.match(/^(\d+)/)
        const bMatch = bAccount.match(/^(\d+)/)

        const aNum = aMatch ? parseInt(aMatch[1], 10) : 0
        const bNum = bMatch ? parseInt(bMatch[1], 10) : 0

        if (aNum !== bNum) {
          return aNum - bNum
        }

        return aAccount.localeCompare(bAccount)
      })

      expect(sorted.map(x => x.expense_account)).toEqual(["", undefined, "4010 Gross Revenue"])
    })
  })
}
