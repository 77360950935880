import { api_fetch } from "@/api/client";
import { DeductionNote } from "@/api/deduction.tsx";
import { entity } from 'simpler-state';

interface NoteState {
  notes: DeductionNote[];
  isLoading: boolean;
  error: string | null;
  lastRefresh: Date | null;
  readStatus: Record<string, string>; // noteId -> read_at timestamp
}

export const noteState = entity<NoteState>({
  notes: [],
  isLoading: false,
  error: null,
  lastRefresh: null,
  readStatus: {},
});

let pollInterval: ReturnType<typeof setInterval> | null = null;

export const fetchNotes = async () => {
  try {
    noteState.set(state => ({ ...state, isLoading: true, error: null }));
    
    const result = await api_fetch<DeductionNote[]>("/notes");
    
    if (!result.ok) {
      throw new Error(result.error.message);
    }

    noteState.set({ 
      notes: result.value.data,
      lastRefresh: new Date(),
      isLoading: false,
      error: null,
      readStatus: noteState.get().readStatus // Preserve read status
    });
  } catch (err) {
    noteState.set(state => ({ 
      ...state, 
      error: (err as Error).message, 
      isLoading: false 
    }));
  }
};

export const markNoteRead = async (noteId: string) => {
  try {
    console.log('Marking note as read:', noteId);
    const result = await api_fetch<void>(`/note/${noteId}/read`, { 
      method: "POST",
      body: {} 
    });
    
    if (!result.ok) {
      throw new Error(result.error.message);
    }

    noteState.set(state => ({
      ...state,
      notes: state.notes.map(note => 
        note.id === noteId 
          ? { ...note, read_at: new Date().toISOString() }
          : note
      )
    }));
  } catch (err) {
    console.error('Failed to mark note as read:', err);
  }
};

export const startPolling = () => {
  if (pollInterval) return;
  
  pollInterval = setInterval(() => {
    fetchNotes();
  }, 2 * 60 * 1000); // Poll every 2 minutes
};

export const stopPolling = () => {
  if (pollInterval) {
    clearInterval(pollInterval);
    pollInterval = null;
  }
}; 