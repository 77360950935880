export function Logo() {
  return (
    <div class="flex shrink-0 items-center mx-auto">
      <img
        class="block h-10 w-10 mr-2"
        src="/logo/rounded.png"
        alt="MarginWiz"
      />
      <Wordmark />
    </div>
  )
}

export function Wordmark() {
  return (
    <img
      class="block -mb-1"
      src="/logo/wordmark.svg"
      alt="MarginWiz"
    />
  )
}
