import { api_fetch, Cell, QueryData } from "@/api"
import { LoadingSpinner } from "@/app/loading"
import { UserState } from "@/auth/user.tsx"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card.tsx"
import { DataTableState, INITIAL_TABLE_STATE } from "@/deductions/table_state.tsx"
import { ActiveFilterIcon } from "@/global_filter/active_filter_icon"
import { TransactionType } from "@/global_filter/transaction_type"
import {
  classes,
  cn,
  currencyFormatter,
  map,
  Option,
  toISODateString,
  useAsyncEffect
} from "@/utils"
import { useState } from "preact/compat"

export function SummaryCards() {
  const [data, setData] = useState<QueryData>()
  const [loading, setLoading] = useState(true)
  const { transactionTypes } = DataTableState.use()
  const [start, end, distributor] = DataTableState.use(s => [s.startDate, s.endDate, s.distributor])
  const [error, setError] = useState<Option<string>>("this is an error")

  const user = UserState.use()

  useAsyncEffect(async () => {
    let res = await api_fetch<QueryData>(`/deductions/query`, {
      params: {
        start_date: map(start, toISODateString),
        end_date: map(end, toISODateString),
        distributor,
        query_name: "summary",
      },
    })
    if (!res.ok) {
      setLoading(false)
      throw new Error("Failed to fetch data")
    }
    setLoading(false)
    setData(res.value.data)
  }, [start, end, distributor])

  function handleSetTransactionTypes(type: TransactionType) {
    if (transactionTypes?.includes(type)) {
      DataTableState.set(s => ({
        ...s,
        transactionTypes: s.transactionTypes?.filter(t => t !== type),
      }))
    } else {
      DataTableState.set(s => ({
        ...s,
        transactionTypes: [...(s.transactionTypes || []), type],
      }))
    }
  }

  if (loading) {
    return <LoadingSpinner color="blue" />
  }

  const row = data?.rows[0]!
  const salesTotal = Math.abs(row[0] as number)
  const deductionsTotal = Math.abs(row[2] as number)
  const tradeRate = (deductionsTotal / salesTotal) * 100
  const wonTotal = Math.abs(row[6] as number)
  const winRate = (wonTotal / deductionsTotal) * 100
  const shouldShowWinRate = winRate >= 3

  return (
    <div className="flex flex-row space-x-4 mb-6">
      <Card className={classes("h-fit cursor-pointer flex-1", transactionTypes?.includes("invoice") && "border-blue-400")}>
        <CardBody
          value={row[0]}
          total={`${row[1]} purchase orders`}
          title="Total Sales"
          valueFormatter={currencyFormatter}
          onClick={() => handleSetTransactionTypes("invoice")}
          isActive={transactionTypes?.includes("invoice")}
        />
      </Card>
      
      <Card className={classes("h-fit cursor-pointer flex-1", transactionTypes?.includes("deduction") && "border-blue-400")}>
        <CardBody
          value={row[2]}
          total={`${row[3]} deductions on invoices`}
          title="Total Deductions"
          valueFormatter={currencyFormatter}
          color="red-800"
          rightValue={tradeRate}
          rightColor="red-800"
          rightValueFormatter={(v: number) => `${Math.round(v)}%`}
          rightTitle="Deduction Rate"
          onClick={() => handleSetTransactionTypes("deduction")}
          isActive={transactionTypes?.includes("deduction")}
        />
      </Card>
      
      {row[6] !== 0 && (
        <Card className={classes("h-fit cursor-pointer flex-1", transactionTypes?.includes("repayment") && "border-blue-400 ")}>
          <CardBody
            value={row[6]}
            total={`${row[7]} repayments`}
            title="Repayments"
            valueFormatter={currencyFormatter}
            color="green-600"
            rightValue={shouldShowWinRate ? winRate : undefined}
            rightColor="green-600"
            rightValueFormatter={(v: number) => `${Math.round(v)}%`}
            rightTitle={shouldShowWinRate ? "% of Deductions Repaid" : undefined}
            onClick={() => transactionTypes?.includes("repayment") ? DataTableState.set(s => ({ ...s, transactionTypes: INITIAL_TABLE_STATE.transactionTypes })) : DataTableState.set(s => ({ ...s, transactionTypes: ["repayment"], columnFilters: INITIAL_TABLE_STATE.columnFilters, search: '' }))}
            isActive={transactionTypes?.includes("repayment")}
          />
        </Card>
      )}
    </div>
  )
}

interface SummaryCardData {
  title?: string
  color?: string
  total?: Cell
  value: Cell
  valueFormatter?: (value: number) => string
  onClick?: () => void
  rightValue?: Cell
  rightColor?: string
  rightValueFormatter?: (value: number) => string
  rightTitle?: string
  rightTotal?: Cell
  isActive?: boolean
}

export function CardBody({ 
  title, 
  color, 
  total, 
  value, 
  valueFormatter, 
  onClick,
  rightValue,
  rightColor,
  rightValueFormatter,
  rightTitle,
  rightTotal,
  isActive
}: SummaryCardData) {
  return (
    <div onClick={onClick}>
      {(title || rightTitle) && (
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-4">
          <CardTitle className="text-md font-medium flex items-center">
            {title}
            {isActive && <ActiveFilterIcon />}
          </CardTitle>
          {rightTitle && (
            <CardTitle className="text-md font-medium">{rightTitle}</CardTitle>
          )}
        </CardHeader>
      )}
      <CardContent>
        <div className="flex justify-between items-start">
          <div>
            <div className={cn("text-3xl font-bold", color && `text-${color}`)}>
              {valueFormatter ? valueFormatter(value as number) : value}
            </div>
            {total && <p className="text-sm text-muted-foreground pt-4">{total}</p>}
          </div>
          {rightValue && (
            <div>
              <div className={cn("text-3xl font-bold text-right min-w-[120px]", rightColor && `text-${rightColor}`)}>
                {rightValueFormatter ? rightValueFormatter(rightValue as number) : rightValue}
              </div>
              {rightTotal && <p className="text-sm text-muted-foreground pt-4 text-right">{rightTotal}</p>}
            </div>
          )}
        </div>
      </CardContent>
    </div>
  )
}