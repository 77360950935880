import { fetch_query_data } from "@/api"
import { LoadingSpinner } from "@/app/loading"
import { UserState } from "@/auth/user"
import { DataTableState } from "@/deductions/table_state"
import { currencyFormatter, index_map, useAsyncEffect } from "@/utils"
import { BarList } from "@tremor/react"
import { useState } from "preact/compat"

type TaskBreakdownData = {
  name: string
  value: number
  amount: number
  key?: string
  color?: string
}

const TASK_STATUS_COLORS: { [key: string]: string } = {
  completed: "green",
  pending: "yellow",
  "all-pending": "amber",
}

export function TaskBreakdown() {
  const [data, setData] = useState<TaskBreakdownData[]>([])
  const [loading, setLoading] = useState(true)
  const [start, end, distributor] = DataTableState.use(s => [s.startDate, s.endDate, s.distributor])
  const user = UserState.use(u => u!)
  const currentUserName = user.email.split('@')[0]

  useAsyncEffect(async () => {
    let res = await fetch_query_data("task_status", { start, end, distributor })
    setLoading(false)
    if (!res.ok) {
      setData([])
      throw new Error("Failed to fetch data")
    }

    let { rows, headers } = res.value
    const headerIndex = index_map(headers)

    // Filter out the "no_user" placeholder row
    rows = rows.filter(row => row[headerIndex.user_name] !== "no_user")

    let total = rows
      .map(row => row[headerIndex.count] as number)
      .reduce((a, b) => a + b, 0)
    let nudge = total * 0.3

    let data = rows.map(row => {
      let amount = row[headerIndex.total_amount] as number
      let count = row[headerIndex.count] as number
      let status = row[headerIndex.status_value] as string
      let userName = row[headerIndex.user_name] as string
      
      // Determine color based on status and user assignment
      let color = userName === "All Pending" 
        ? TASK_STATUS_COLORS["all-pending"]
        : userName === currentUserName && status === "pending"
          ? "blue" 
          : TASK_STATUS_COLORS[status.toLowerCase()] || "slate"
      
      return {
        key: userName === "All Pending" ? "pending" : `${status}: ${userName}`,
        amount: amount,
        name: userName === "All Pending" ? `All Pending (${count})` : `${status}: ${userName} (${count})`,
        value: count + nudge,
        color,
      }
    })

    setData(data)
  }, [start, end, distributor])

  if (loading) {
    return <LoadingSpinner color="blue" />
  }

  return (
    <div className="flex">
      <BarList
        data={data}
        className="max-w-2xl grow"
        sortOrder="none"
        valueFormatter={(_: TaskBreakdownData) => ""}
        onValueChange={(item: TaskBreakdownData) => {
          DataTableState.set(s => ({
            ...s,
            search: "",
            columnFilters: [
              { id: "task", value: item.key! }
            ],
            pagination: { pageIndex: 0, pageSize: s.pagination.pageSize },
          }))
        }}
      />
      <div className="xl:ml-6">
        {data.map(d => (
          <div className="h-8 mb-1.5 items-center flex">
            {currencyFormatter(Math.abs(d.amount))}
          </div>
        ))}
      </div>
    </div>
  )
} 