"use client"

import { TableColumn } from "@/deductions/table/columns.tsx"
import { CellContext } from "@tanstack/react-table"

import { Backup } from "@/api/backup.tsx"
import { dateFormatter, exactCurrencyFormatter } from "@/utils"
import { ColumnHeader } from "../table/column_header.tsx"

const FORMATTER = new Intl.DateTimeFormat("en-US", {
  month: "short",
  day: "numeric",
  year: "numeric",
})

export function cellDateFormatter<T>(cell: CellContext<T, unknown>) {
  let value = cell.getValue() as string
  return dateFormatter(value, FORMATTER)
}

export function cellCurrencyFormatter<T>(cell: CellContext<T, unknown>) {
  let val = cell.getValue() as string
  if (!val) return ""
  let amount = parseFloat(val)
  return exactCurrencyFormatter(amount)
}

export function cellPercentFormatter<T>(cell: CellContext<T, unknown>) {
  let val = cell.getValue() as string
  if (!val) return ""
  let amount = parseFloat(val)
  return `${amount.toFixed(2)}%`
}

export const backup_columns: TableColumn<Backup>[] = [
  {
    accessorKey: "retailer_name",
    header: ({ column }) => <ColumnHeader column={column} title="Retailer" />,
  },
  {
    accessorKey: "retailer_code",
    header: ({ column }) => <ColumnHeader column={column} title="Retailer Code" />,
  },
  {
    accessorKey: "product_description",
    header: ({ column }) => <ColumnHeader column={column} title="Product Description" />,
  },
  {
    accessorKey: "quantity",
    header: ({ column }) => <ColumnHeader column={column} title="Quantity" />,
  },
  {
    accessorKey: "unit_amount",
    header: ({ column }) => <ColumnHeader column={column} title="Unit Amount" />,
    cell: cellCurrencyFormatter,
  },
  {
    accessorKey: "product_pack_size",
    header: ({ column }) => <ColumnHeader column={column} title="Pack Size" />,
  },
  {
    accessorKey: "product_number",
    header: ({ column }) => <ColumnHeader column={column} title="Product Number" />,
  },
  {
    accessorKey: "promo_type",
    header: ({ column }) => <ColumnHeader column={column} title="Promo Type" />,
  },
  {
    accessorKey: "promo_sub_type",
    header: ({ column }) => <ColumnHeader column={column} title="Promo Sub Type" />,
  },
  {
    accessorKey: "promo_period",
    header: ({ column }) => <ColumnHeader column={column} title="Promo Period" />,
  },
  {
    accessorKey: "received",
    header: ({ column }) => <ColumnHeader column={column} title="Date Received" />,
  },
  {
    accessorKey: "start_date",
    header: ({ column }) => <ColumnHeader column={column} title="Start Date" />,
    cell: cellDateFormatter,
  },
  {
    accessorKey: "end_date",
    header: ({ column }) => <ColumnHeader column={column} title="End Date" />,
    cell: cellDateFormatter,
  },
  {
    accessorKey: "total",
    header: ({ column }) => <ColumnHeader column={column} title="Total Amount" />,
    cell: cellCurrencyFormatter,
  },
  {
    accessorKey: "store_number",
    header: ({ column }) => <ColumnHeader column={column} title="Store Number" />,
  },
  {
    accessorKey: "discount_percent",
    header: ({ column }) => <ColumnHeader column={column} title="Discount Percent" />,
    cell: cellPercentFormatter,
  },
  {
    accessorKey: "unit_discount_amount",
    header: ({ column }) => <ColumnHeader column={column} title="Unit Discount Amount" />,
    cell: cellCurrencyFormatter,
  },
  {
    accessorKey: "admin_fee",
    header: ({ column }) => <ColumnHeader column={column} title="Admin Fee" />,
    cell: cellCurrencyFormatter,
  },
  {
    accessorKey: "vendor_remit_number",
    header: ({ column }) => <ColumnHeader column={column} title="Remit Number" />,
  },
  {
    accessorKey: "po",
    header: ({ column }) => <ColumnHeader column={column} title="PO" />,
  },
  {
    accessorKey: "quantity_received",
    header: ({ column }) => <ColumnHeader column={column} title="Quantity Received" />,
  },
  {
    accessorKey: "execution_date",
    header: ({ column }) => <ColumnHeader column={column} title="Execution Date" />,
    cell: cellDateFormatter,
  },
  {
    accessorKey: "upc",
    header: ({ column }) => <ColumnHeader column={column} title="UPC" />,
  },
  {
    accessorKey: "carrier",
    header: ({ column }) => <ColumnHeader column={column} title="Carrier" />,
  },
  {
    accessorKey: "dc",
    header: ({ column }) => <ColumnHeader column={column} title="DC" />,
  },
  {
    accessorKey: "invoice_date",
    enableHiding: false,
    enableGlobalFilter: false,
    header: ({ column }) => <ColumnHeader column={column} title="Invoice Date" />,
    cell: cellDateFormatter,
  },
  {
    accessorKey: "total_shorted",
    header: ({ column }) => <ColumnHeader column={column} title="Total Shorted" />,
  },
  {
    accessorKey: "total_weight_received",
    header: ({ column }) => <ColumnHeader column={column} title="Total Weight Received" />,
  },
  {
    accessorKey: "total_weight_ordered",
    header: ({ column }) => <ColumnHeader column={column} title="Total Weight Ordered" />,
  },
  {
    accessorKey: "total_weight_shorted",
    header: ({ column }) => <ColumnHeader column={column} title="Total Weight Shorted" />,
  },
  {
    accessorKey: "total_lost",
    header: ({ column }) => <ColumnHeader column={column} title="Total Lost" />,
  },
]
