import { Integration } from "@/api/deduction.tsx"
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "@/components/ui/alert-dialog"
import { Button } from "@/components/ui/button"
import { Form, FormField, FormItem, FormMessage } from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Switch } from "@/components/ui/switch"
import { toast } from "@/components/ui/use-toast"
import { classes, safeExec, useAsyncEffect } from "@/utils"
import { TrashIcon } from "@heroicons/react/20/solid"
import { zodResolver } from "@hookform/resolvers/zod"
import { capitalCase } from "change-case"
import { ComponentChildren } from "preact"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { UserState } from "src/auth/user.tsx"
import * as z from "zod"
import { api_fetch } from "../api/client.tsx"

/** Team is 1-1 with a warehouse, so when you select a team, that will set the global corresponding warehouse.
 *
 * @constructor
 */
export function Settings() {
  const user = UserState.use((u) => u!)
  return (
    <div class="mx-auto max-w-4xl px-4 sm:px-6 lg:px-8 mb-8 mt-2">
      <AutoValidationSettings />
      <ConnectionSettings />
      <ProfileSettings />
    </div>
  )
}

export function ProfileSettings() {
  const email = UserState.use((u) => u!.email)
  return (
    <Section title="Profile">
      <Row title="Email Address">{email}</Row>
      <Row title="Subscription">
        <a href={import.meta.env.VITE_STRIPE_CUSTOMER_PORTAL} class="text-plue-600">
          Manage billing
        </a>
      </Row>
    </Section>
  )
}

// Add validation schema
const autoValidationSchema = z.object({
  spoilage_auto_validate: z.boolean().default(false),
  contractual_auto_validate: z.boolean().default(false),
  auto_validate_threshold: z.number().min(0).max(750).nullable(),
})

type AutoValidationFormData = z.infer<typeof autoValidationSchema>

function AutoValidationSettings() {
  const [showConfirm, setShowConfirm] = useState(false)
  const [formData, setFormData] = useState<AutoValidationFormData>()

  const form = useForm<AutoValidationFormData>({
    resolver: zodResolver(autoValidationSchema),
    defaultValues: {
      spoilage_auto_validate: false,
      contractual_auto_validate: false,
      auto_validate_threshold: null,
    }
  })

  useAsyncEffect(async () => {
    const res = await api_fetch<AutoValidationFormData>('/organization/auto_validation')
    if (!res.ok) return
    
    form.reset(res.value.data)
  }, [])

  const onSubmit = (data: AutoValidationFormData) => {
    setFormData(data)
    setShowConfirm(true)
  }

  const handleConfirm = async () => {
    if (!formData) return
    
    const res = await api_fetch<{ validated_count: number }>('/organization/auto_validation', {
      method: 'POST',
      body: formData
    })

    if (res.ok) {
      setShowConfirm(false)
      form.reset(formData)
      
      toast({
        title: "Settings Updated",
        description: `Successfully updated auto-validation settings and validated ${res.value.data.validated_count} deduction${
          res.value.data.validated_count === 1 ? "" : "s"
        }`,
      })
    }
  }

  return (
    <Section title="Auto-Validation Settings" class="first:pt-6">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
          <Row title="Auto-validate Spoilage">
            <FormField
              control={form.control}
              name="spoilage_auto_validate"
              render={({ field }) => (
                <FormItem>
                  <Switch 
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormItem>
              )}
            />
          </Row>

          <Row title="Auto-validate Contractual (Fairshare/Merchandising)">
            <FormField
              control={form.control}
              name="contractual_auto_validate"
              render={({ field }) => (
                <FormItem>
                  <Switch
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormItem>
              )}
            />
          </Row>

          <Row title="Auto-validate Amount Threshold">
            <FormField
              control={form.control}
              name="auto_validate_threshold"
              render={({ field }) => (
                <FormItem>
                  <Input
                    type="number"
                    min="0"
                    max="750"
                    value={field.value ?? ""}
                    onChange={e => {
                      const value = e.currentTarget.value
                      field.onChange(value ? Number(value) : null)
                    }}
                    placeholder="Enter amount (max $750)..."
                    className="w-54"
                  />
                  <FormMessage />
                </FormItem>
              )}
            />
          </Row>

          <AlertDialog open={showConfirm} onOpenChange={setShowConfirm}>
            <AlertDialogTrigger asChild>
              <Button 
                type="submit" 
                variant="default"
                disabled={!form.formState.isDirty}
              >
                Save Changes
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Confirm Auto-Validation Changes</AlertDialogTitle>
                <AlertDialogDescription>
                  This will update auto-validation settings and apply them to existing deductions with status "new". Are you sure?
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Cancel</AlertDialogCancel>
                <AlertDialogAction onClick={handleConfirm}>
                  Confirm
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </form>
      </Form>
    </Section>
  )
}

function ConnectionSettings() {
  const [integrations, setIntegrations] = useState<Integration[]>([])

  useAsyncEffect(async () => {
    let qs = new URLSearchParams()

    const res = await api_fetch<Integration[]>(`/integration?${qs}`)
    if (!res.ok) {
      return
    }
    setIntegrations(res.value.data)
  }, [])

  function deleteConnection(id: string) {
    let ans = confirm("Are you sure you want to delete this connection?")
    if (!ans) {
      return
    }
    safeExec(async () => {
      let res = await api_fetch(`/integration/${id}`, {
        method: "DELETE",
      })
      setIntegrations(integrations.filter((i) => i.id !== id))
    })
  }

  return (
    <Section title="Connections" class="first:pt-6">
      {integrations.map((integration) => {
        let name = capitalCase(integration.name)
        return (
          <div class="flex justify-between gap-x-6 pt-3">
            <span>{name}</span>
            <span class="flex items-center">
              {integration.username}
              <button
                onClick={() => deleteConnection(integration.id)}
                alt="Delete Connection"
                type="button"
              >
                <TrashIcon class="ml-1 h-5 w-5 text-red-400" />
              </button>
            </span>
          </div>
        )
      })}
    </Section>
  )
}

interface SectionProps {
  children: ComponentChildren
  title: string
  class?: string
}

export function Section({ children, title, ...props }: SectionProps) {
  return (
    <div class={classes("pt-12", props.class)}>
      <h2 className="text-lg font-semibold leading-7 text-gray-900">{title}</h2>
      <dl className="space-y-3 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
        {children}
      </dl>
    </div>
  )
}

interface RowProps {
  children: ComponentChildren
  title: string
}

export function Row({ children, title, ...props }: RowProps) {
  return (
    <div className="pt-3 sm:flex">
      <dt className="text-gray-900 sm:w-64 sm:flex-none sm:pr-6">{title}</dt>
      <dd className="flex justify-end gap-x-6 sm:flex-auto">{children}</dd>
    </div>
  )
}
