import { entity } from 'simpler-state';

export interface ActivityMetadata {
  deduction_count?: number;
  total_amount?: number;
  deduction_ids?: string[];
  individual_details?: Array<{
    invoice_number: string;
    invoice_amount: number;
    source?: string;
    status?: string;
    task_type?: string;
    message_preview?: string;
    from?: string;
    subject?: string;
  }>;
  new_status?: string;
  task_type?: string;
  status?: string;
  task_count?: number;
  message_count?: number;
  type?: string;
  deduction_amount?: number;
  source?: string;
  invoice_number?: string;
  deduction_id?: string;
}

export interface ActivityDetails {
  sample_items?: Array<{
    invoice_number: string;
    message_preview?: string;
    from?: { name: string; email: string };
    subject?: string;
    status?: string;
    task_type?: string;
    invoice_amount?: number;
  }>;
  sample_invoices?: Array<{
    invoice_number: string;
    invoice_amount: number;
    source: string;
  }>;
  total_count: number;
  source?: string;
  status?: string;
  task_type?: string;
}

export interface Activity {
  id: string;
  org_id: string;
  activity_type: 'status_change' | 'new_import' | 'task_update' | 'new_message' | 'dispute_created';
  actor_id: string | null;
  actor_name: string | null;
  created_at: string;
  metadata: {
    deduction_id?: string;
    deduction_ids?: string[];
    deduction_count?: number;
    message_count?: number;
    task_count?: number;
    task_type?: string;
    status?: string;
    new_status?: string;
    source?: string;
    total_amount?: number;
    type?: string;
    invoice_number?: string;
    deduction_amount?: number;
    individual_details: ActivityDetails;
  };
}

interface ActivityState {
  activities: Activity[];
  isLoading: boolean;
  error: string | null;
  lastRefresh: Date | null;
}

export const activityState = entity<ActivityState>({
  activities: [],
  isLoading: false,
  error: null,
  lastRefresh: null
});

let pollInterval: ReturnType<typeof setInterval> | null = null;

export const fetchActivities = async () => {
  try {
    activityState.set(state => ({ ...state, isLoading: true, error: null }));
    const response = await fetch('/api/activity');
    if (!response.ok) throw new Error('Failed to fetch activities');
    
    const data = await response.json();
    
    // Transform the rows data into Activity objects
    const activities = data.rows.map((row: any[]) => ({
      id: row[0],
      activity_type: row[1],
      actor_name: row[2],
      created_at: row[3],
      metadata: row[4]
    }));

    activityState.set({ 
      activities, 
      lastRefresh: new Date(),
      isLoading: false,
      error: null 
    });
  } catch (err) {
    activityState.set(state => ({ 
      ...state, 
      error: (err as Error).message, 
      isLoading: false 
    }));
  }
};

export const startPolling = () => {
  if (pollInterval) return;
  
  pollInterval = setInterval(() => {
    fetchActivities();
  }, 2 * 60 * 1000); // Poll every 2 minutes
};

export const stopPolling = () => {
  if (pollInterval) {
    clearInterval(pollInterval);
    pollInterval = null;
  }
}; 