import { Backup } from "@/api/backup.tsx"
import { api_fetch } from "@/api/client.tsx"
import { DeductionResponse, Dispute, Message } from "@/api/deduction.tsx"
import { DisputeType } from "@/api/dispute.tsx"
import { TaskStatus, TaskType } from "@/api/task.tsx"
import { UserInfo, getUserById } from "@/api/user.tsx"
import { LoadingSpinner } from "@/app/loading"
import { Button } from "@/components/ui/button.tsx"
import { Card } from "@/components/ui/card.tsx"
import { Separator } from "@/components/ui/separator.tsx"
import { displayFormattedDistributor, exactCurrencyFormatter, map_or } from "@/utils"
import { capitalCase } from "change-case"
import { format } from "date-fns"
import { Calendar, Check, CircleCheck, FileCog, FileUp, FileWarning, MailPlus, SendHorizonal } from "lucide-react"
import { useEffect, useState } from "preact/hooks"
import { StatusFlag } from "../status_state.tsx"

interface Update {
  date: string | Date
  title: string
  actor: string
  description?: string
  action?: any
}

const UPDATE_ICONS = {
  "Deduction created": <FileUp className="text-gray-400 ml-4" />,
  "Dispute created": <FileWarning className="text-gray-400 ml-4" />,
  "Backup parsed": <FileCog className="text-gray-400 ml-4" />,
  "Email sent": <SendHorizonal className="text-gray-400 ml-4" />,
  "Email received": <MailPlus className="text-gray-400 ml-4" />,
  "pending task": <CircleCheck className="text-gray-400 ml-4" />,
  "completed task": <Check className="text-plue-400 ml-4" />,
}

function getUpdateIcon(title: string) {
  for (const key in UPDATE_ICONS) {
    if (title.toLowerCase().startsWith(key.toLowerCase())) {
      return UPDATE_ICONS[key as keyof typeof UPDATE_ICONS]
    }
  }
  return null
}

const ACTOR_ICONS = {
  MarginWiz: <img class="block h-6 w-6 mr-2" src="/logo/rounded.png" alt="MarginWiz" />,
  unfi: <img class="block w-10 mr-2" src="/img/integration/unfi.png" alt="UNFI" />,
}

// Helper function to create an avatar with initials
function getUserInitialsAvatar(name: string) {
  const initials = name.toLowerCase().includes('marginwiz') ? ACTOR_ICONS.MarginWiz : name.split(' ')
    .map(part => part.charAt(0).toUpperCase())
    .join('')
    .substring(0, 2);

  
  return (
    <div class="flex items-center justify-center h-6 w-6 rounded-full bg-plue-100 text-plue-700 text-xs font-medium">
      {initials}
    </div>
  );
}

function parseEmailDomain(email: string) {
  const [_, domain] = email.split("@")
  return domain?.split(".")[0]
}

export function formatTaskType(taskType: TaskType) {
  switch (taskType) {
    case TaskType.FileDispute:
      return "File dispute"
    case TaskType.PullBackup:
      return "Pull backup"
    case TaskType.Followup:
      return "Follow-up"
    case TaskType.Validate:
      return "Validate deduction"
    case TaskType.Expense:
      return "Expense"
    case TaskType.MapAccountingCode:
      return "Map accounting code"
  }
}

export function formatDisputeType(disputeType: DisputeType) {
  switch (disputeType) {
    case DisputeType.Backup:
      return "backup request"
    case DisputeType.Chargeback:
      return "chargeback dispute"
    case DisputeType.EarlyPay:
      return "early pay dispute"
    case DisputeType.Unknown:
      return "dispute"
  }
}

async function handleTaskComplete(task_id: string) {
  const res = await api_fetch(`/task`, {
    method: "PUT",
    body: {
      status: TaskStatus.Completed,
      task_id,
    },
  })
  if (!res.ok) {
    console.error("Failed to mark task complete")
  }
  // todo: toast
  StatusFlag.set(flag => !flag)
}

function getTaskUpdate(deduction: DeductionResponse) {
  let task = deduction.task!
  return {
    date: task.created_at ?? "",
    title: `${capitalCase(task.status ?? "")} task`,
    description: `${task.user_email.split("@")[0] ?? ""} assigned to ${formatTaskType(task.type)}${
      task.backup_type ? ` (${task.backup_type.toLowerCase()})` : ""
    }${task.note ? `\nNote: ${task.note}` : ""}`,
    actor: task.assigner_email.split("@")[0] ?? "",
    action: task.status.toLowerCase() === TaskStatus.Pending
      ? (
        <Button onClick={() => handleTaskComplete(task.id)} variant="secondary">
          Mark complete
        </Button>
      )
      : (
        ""
      ),
  }
}

export function Updates({
  deduction,
  backup,
  messages,
  dispute,
  loading = false,
  className = "h-96",
}: {
  deduction: DeductionResponse
  backup?: Backup
  messages: Message[]
  dispute?: Dispute | null
  loading?: boolean
  className?: string
}) {
  const [disputeUser, setDisputeUser] = useState<UserInfo | null>(null);
  const [userLoading, setUserLoading] = useState(false);
  
  // Fetch user info when dispute is available
  useEffect(() => {
    if (dispute?.user_id) {
      setUserLoading(true);
      getUserById(dispute.user_id)
        .then(response => {
          if (response.ok) {
            setDisputeUser(response.value.data);
          }
        })
        .catch(err => {
          console.error("Failed to fetch user:", err);
        })
        .finally(() => {
          setUserLoading(false);
        });
    }
  }, [dispute?.user_id]);
  
  if (loading || userLoading) {
    return (
      <Card className={className}>
        <LoadingSpinner color="plue-500" />
      </Card>
    )
  }

  const updates: Update[] = [
    {
      date: deduction.created_at,
      title: "Deduction created",
      description: `Synced from ${displayFormattedDistributor(deduction.source, deduction.original_source)}`,
      actor: "MarginWiz",
    },
  ]
  
  // Add dispute information if available directly from deduction
  if (deduction.dispute_id && dispute) {
    let actor = "MarginWiz";
    
    // Use the fetched user info if available
    if (disputeUser) {
      if (disputeUser.first_name) {
        actor = disputeUser.first_name;
        if (disputeUser.last_name) {
          actor += ` ${disputeUser.last_name}`;
        }
      } else {
        // Fallback to email username if no first name
        actor = disputeUser.email.split('@')[0];
      }
    } 
    
    updates.unshift({
      date: dispute.created_at,
      title: "Dispute created",
      description: `${actor} created a ${formatDisputeType(dispute.type as DisputeType)} for ${exactCurrencyFormatter(dispute.amount)}`,
      actor: actor,
    });
  }
  
  if (backup) {
    updates.unshift({
      date: backup.created_at,
      title: "Backup parsed",
      description: "See details in table below",
      // todo: add link to the file here also for convenience?
      actor: "MarginWiz",
    })
  }
  messages?.forEach(message => {
    if (message.attachments) {
      const isFirstEmail = message.sequence === 0
      // email.attachments.some(attachment => attachment.s3_uri.includes("dispute"))
      updates.unshift({
        date: message.date,
        title: isFirstEmail ? "Email sent" : "Email received",
        description: isFirstEmail ? "Dispute / backup request filed" : "Response received",
        actor: map_or(message.from.email, "MarginWiz", parseEmailDomain),
      })
    }
  })
  if (deduction.task?.created_at) {
    updates.unshift(getTaskUpdate(deduction))
  }

  return (
    <Card className={`${className} overflow-y-auto`}>
      <div class="flex justify-between items-center m-4">
        <h2 class="flex text-xl">
          <Calendar class="h-6 w-6" />
          <span class="ml-2">Updates</span>
        </h2>
      </div>
      <div class="px-4 w-full flex justify-center">
        <Separator class="w-1/2" />
      </div>
      <div class="flex flex-col gap-4 mt-4 mb-4">
        {updates.map((update, i) => (
          <>
            <div class="flex gap-2 items-start ">
              <div class="flex items-center justify-center">{getUpdateIcon(update.title)}</div>
              <div class="flex flex-col grow">
                <div class="text-lg ">{update.title}</div>
                <span class="text-gray-500">{update.description}</span>
                <div class="flex justify-between w-full mt-3">
                  <div class="flex items-center">
                    {update.action || 
                     (ACTOR_ICONS[update.actor as keyof typeof ACTOR_ICONS] || 
                      (update.title === "Dispute created" && disputeUser?.first_name ? 
                       getUserInitialsAvatar(update.actor) : 
                       <span className="mr-2 text-sm text-gray-500">{update.actor}</span>))}
                  </div>{" "}
                  <div class="flex items-center">
                    <Calendar className="text-gray-500 h-4 w-4 " />
                    <div class="text-sm text-gray-500 text-right ml-2 mr-4">
                      {format(update.date as Date, "PPP")}
                    </div>
                  </div>
                  {" "}
                </div>
              </div>
            </div>
            {i < updates.length - 1 && <hr class="border-t border-gray-200 ml-12 mx-4" />}
          </>
        ))}
      </div>
    </Card>
  )
}
