import { useSearchParams } from "@/utils"
import { VNode } from "preact"
import { ComponentProps } from "./type.tsx"

interface LoginContainerProps extends ComponentProps {
  logo?: VNode
  title: string
}
export function LoginScreen(props: LoginContainerProps) {
  const search = useSearchParams<{ next?: string }>()

  return (
    <div class="min-h-screen bg-gray-50 flex flex-col justify-center pb-12 sm:px-6 lg:px-8 w-full">
      {props.logo}
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">{props.title}</h2>
      </div>

      <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div class="bg-white py-8 px-4 shadow-sm sm:rounded-lg sm:px-10">
          {props.children}
        </div>
      </div>
    </div>
  )
}
