import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "@/components/ui/alert-dialog.tsx"
import { Button } from "@/components/ui/button.tsx"
import { Card } from "@/components/ui/card.tsx"
import { Separator } from "@/components/ui/separator.tsx"
import { toast } from "@/components/ui/use-toast"
import { classes } from "@/utils/index.tsx"
import { formatDistanceToNow } from "date-fns"
import { Eye, FilesIcon, PaperclipIcon, Trash2, Upload } from "lucide-react"
import { useDropzone } from 'react-dropzone'
import { StatusFlag } from "../status_state.tsx"
import { GoogleDriveSearch } from "./google_drive_search"

interface SimpleFileUploaderProps {
  onFileUpload: (files: File[]) => void
}

export function SimpleFileUploader({ onFileUpload }: SimpleFileUploaderProps) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: acceptedFiles => {
      onFileUpload(acceptedFiles)
    },
    multiple: true
  })

  const rootProps = getRootProps() as any

  return (
    <div
      {...rootProps}
      className={classes(
        "relative",
        isDragActive && "after:absolute after:inset-[-12px] after:rounded-lg after:border-2 after:border-dashed after:border-primary"
      )}>
      <Button
        variant="tertiary"
        // @ts-ignore
        size="sm">
        <Upload className="h-4 w-4 mr-2" />
        Upload
      </Button>
      <input {...getInputProps()} />
    </div>
  )
}

export interface DeductionFile {
  display_name: string
  date: string
  s3_uri: string
  deduction_id?: string
  actor_name?: string
}

function formatFileSource(file: DeductionFile) {
  return file.display_name
}

export function handleViewS3Uri(s3_uri: string) {
  return function handleClick(e: MouseEvent) {
    e.preventDefault()
    e.stopImmediatePropagation()
    window.open(`/api/_s3?s3_uri=${encodeURIComponent(s3_uri)}`, "_blank")
  }
}

async function uploadFiles(deductionId: string, files: File[]): Promise<DeductionFile[]> {
  const formData = new FormData()
  for (const file of files) {
    formData.append("files", file)
  }

  const response = await fetch(`/api/deduction/${deductionId}/files`, {
    method: "POST",
    credentials: "same-origin",
    body: formData,
  })

  if (!response.ok) {
    throw new Error("Failed to upload files")
  }

  return response.json()
}

async function handleFileDelete(deductionId: string, s3Uri: string): Promise<DeductionFile[]> {
  const encodedUri = encodeURIComponent(s3Uri)
  const response = await fetch(`/api/deduction/${deductionId}/files/${encodedUri}`, {
    method: 'DELETE',
    credentials: 'same-origin',
  })

  if (!response.ok) {
    throw new Error('Failed to delete file')
  }

  return response.json()
}

function DeleteFileDialog({ 
  file, 
  deductionId,
  onDelete 
}: { 
  file: DeductionFile
  deductionId: string
  onDelete: () => void 
}) {
  const handleConfirmDelete = async () => {
    try {
      await handleFileDelete(deductionId, file.s3_uri)
      toast({
        title: "Success",
        description: "File deleted successfully",
      })
      onDelete()
    } catch (error) {
      console.error("Error deleting file:", error)
      toast({
        title: "Error",
        description: "Failed to delete file",
        variant: "destructive",
      })
    }
  }

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <button 
          onClick={(e) => e.stopPropagation()} 
          className="p-1 hover:bg-gray-100 rounded-full"
        >
          <Trash2 className="h-4 w-4 text-red-500" />
        </button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently delete the file.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={(e: Event) => e.stopPropagation()}>
            Cancel
          </AlertDialogCancel>
          <AlertDialogAction 
            onClick={(e: Event) => {
              e.stopPropagation()
              handleConfirmDelete()
            }}
          >
            Delete
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

interface FilesProps {
  files: DeductionFile[]
  deductionId: string
  orgId?: string
  source?: string
  invoiceNumber?: string
  onFileSelect?: (file: DeductionFile) => void
  height?: "default" | "compact"
}

export async function handleFileUpload(deductionId: string, files: File[]): Promise<DeductionFile[]> {
  try {
    const newFiles = await uploadFiles(deductionId, files)
    StatusFlag.set(flag => !flag)
    return newFiles
  } catch (error) {
    console.error("Error uploading files:", error)
    return []
  }
}

export function Files({ 
  files, 
  deductionId, 
  orgId, 
  source, 
  invoiceNumber, 
  onFileSelect,
  height = "default" 
}: FilesProps) {
  const handleFileClick = (file: DeductionFile) => {
    if (onFileSelect) {
      onFileSelect(file)
    } else {
      handleViewS3Uri(file.s3_uri)(new MouseEvent('click'))
    }
  }

  const handleUpload = async (uploadedFiles: File[]) => {
    const newFiles = await handleFileUpload(deductionId, uploadedFiles)
    if (newFiles.length > 0 && onFileSelect) {
      onFileSelect(newFiles[newFiles.length - 1])
    }
  }

  return (
    <Card className={`${height === "compact" ? "h-48" : "h-64"} mt-1.5 overflow-y-auto`}>
      <div className="flex justify-between items-center m-4">
        <h2 className="flex text-xl">
          <FilesIcon className="h-6 w-6" />
          <span className="ml-2">Files</span>
        </h2>
        <div className="flex gap-2">
          <GoogleDriveSearch orgId={orgId} source={source} invoiceNumber={invoiceNumber} />
          <SimpleFileUploader onFileUpload={handleUpload} />
        </div>
      </div>
      <div className="px-4 w-full flex justify-center">
        <Separator className="w-1/2" />
      </div>
      <div className="flex flex-col gap-4 mt-4 mb-4">
        {files.map((file, i) => (
          <div
            key={i}
            className="flex mt-2 gap-2 items-center ml-5 hover:cursor-pointer pr-5"
            onClick={() => handleFileClick(file)}>
            <div className="shrink-0">
              <div className="inline-flex items-center justify-center border p-2 rounded-3xl">
                <PaperclipIcon className="text-gray-800 h-5 w-5" />
              </div>
            </div>
            <div className="grow min-w-0">
              <div className="text-lg truncate">{file.display_name}</div>
              <div className="text-gray-500 truncate">{`Uploaded${
                file.actor_name ? ` by ${file.actor_name}` : ""
              } ${formatDistanceToNow(new Date(file.date), { addSuffix: true })}`}</div>
            </div>
            <div className="flex items-center gap-2">
              <Eye className="text-gray-800 h-6 w-6" />
              {file.deduction_id && (
                <DeleteFileDialog
                  file={file}
                  deductionId={deductionId}
                  onDelete={() => {
                    StatusFlag.set(flag => !flag)
                  }}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </Card>
  )
}
