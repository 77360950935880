import { Message } from "@/api/deduction.tsx";
import { Separator } from "@/components/ui/separator";
import { fetchMessages, markMessageRead, messageState, startPolling, stopPolling } from "@/stores/message";
import { format } from "date-fns";
import { ChevronDown, ChevronUp, Loader2, Mail } from "lucide-react";
import { useEffect, useState } from "preact/hooks";

interface MessageListProps {
  isStandalone?: boolean;
  onClose?: () => void;
}

// Filter out messages with empty bodies
function getValidMessages(messages: Message[]) {
  return messages.filter(message => !!message.body);
}

function MessageItem({ message, onClose }: { message: Message; onClose?: () => void }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const formattedDate = format(new Date(message.date), "MMM d, yyyy");
  const displayName = message.from.name?.toLowerCase().includes('unfi') 
    ? 'UNFI' 
    : (message.from.name || message.from.email);

  const handleClick = async (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (!message.read_at) {
      await markMessageRead(message.id);
    }
    if (onClose) {
      onClose();
    }
    window.location.href = `/deduction/${message.deduction_id || ''}`;
  };

  const handleExpandClick = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div 
        className="flex mt-2 gap-2 items-start ml-2 cursor-pointer"
      >
        <div className="flex flex-col items-center">
          <div 
            onClick={handleClick}
            className="inline-flex items-center justify-center border p-2 rounded-3xl cursor-pointer"
          >
            <Mail className={`h-5 w-5 ${message.read_at ? 'text-gray-500' : 'text-plue-900'}`} />
          </div>
        </div>
        <div className="flex flex-col grow overflow-hidden">
          <div className="flex justify-between w-full">
            <div 
              onClick={handleClick}
              className="text-md truncate cursor-pointer hover:underline"
            >
              {displayName}
            </div>
            <div className="text-gray-500 text-sm mt-1">{formattedDate}</div>
          </div>
          {message.subject && message.sequence === 0 && (
            <div 
              onClick={handleClick}
              className="text-sm mt-1 truncate cursor-pointer hover:underline"
            >
              {message.subject}
            </div>
          )}
          <div className="flex items-center">
            <div className={`text-sm mt-1 grow text-gray-500 max-w-full overflow-hidden ${
              isExpanded ? "" : "line-clamp-2"
            }`}>
              {message.body}
            </div>
            <div className="mr-2">
              {isExpanded 
                ? <ChevronUp className="cursor-pointer" onClick={handleExpandClick} />
                : <ChevronDown className="cursor-pointer" onClick={handleExpandClick} />}
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 w-full flex justify-center">
        <Separator />
      </div>
    </>
  );
}

export function MessageList({ isStandalone, onClose }: MessageListProps) {
  const { messages, isLoading } = messageState.use();
  const validMessages = getValidMessages(messages);

  useEffect(() => {
    fetchMessages();
    startPolling();
    return () => stopPolling();
  }, []);

  if (isLoading && validMessages.length === 0) {
    return (
      <div className="flex justify-center items-center h-[200px]">
        <Loader2 className="h-6 w-6 animate-spin" />
      </div>
    );
  }

  return (
    <div className="overflow-auto max-h-[calc(100vh-120px)]">
      {validMessages.map((message) => (
        <MessageItem 
          key={message.id} 
          message={message} 
          onClose={onClose}
        />
      ))}
      
      {isLoading && (
        <div className="flex justify-center p-4">
          <Loader2 className="h-6 w-6 animate-spin" />
        </div>
      )}
    </div>
  );
} 