import { TaskInfo } from "@/api/task.tsx"
import { Uuid } from "src/utils/type"
import { DisputeType } from "./dispute"

export interface Integration {
  id: string
  name: string
  username: string
  // database schema
  schema_name: string
  data: { workspace_id: string }
}

export interface SyncTableData {
  table: string
  integration: string
  integration_id: Uuid
}

export interface JobSchedule {
  id: string
  crontab: string
  data: SyncTableData
  integration_id: Uuid
}

export interface UserFile {
  display_name: string
  date: string
  s3_uri: string
  actor_name: string
}

export interface Deduction {
  id: string
  org_id: string
  remote_id: string
  created_at: string
  updated_at: string
  source: string
  invoice_amount: number
  discount_amount: number
  dc?: string
  dc_name?: string
  gross_amount: number
  invoice_date: string
  invoice_number: string
  check_amount: number
  check_date: string
  check_number: number
  category_id: string
  backup_s3_uri: string | null
  check_s3_uri: string | null
  po_number: string
  data: string
  document: string
  retailer_name: string | null
  execution_date: string
  user_files: UserFile[]
  dispute_id: string
  product_line: string
  retailer_invoice_number: string
  payment_due_date?: string
  original_source?: string
  custom_deduction_method?: string
  is_deduction: boolean
  is_prepayment: boolean
  is_repayment: boolean
  repayment_for?: string
  accounting_status?: string
  has_splits: boolean
  is_parent: boolean
  import_source: string
  description?: string
  pending: boolean
  remote_accounting_id?: string
}

export interface DeductionNote {
  id: string
  created_at: string
  user_id: string
  deduction_id: string
  org_id: string
  message: string
  actor_name: string
  read_at: string | null
  invoice_number: string | null
  amount: number | null
}

export interface DeductionResponse extends Deduction {
  category: string
  category_description: string
  status_value?: string
  notes: DeductionNote[]
  task?: TaskInfo
  validated: boolean
  reason_codes?: ReasonCodeInfo[]
}

export interface ReasonCodeInfo {
  id: string
  name: string
  description: string
  expense_account: string
  actor: string
  code_type: string
  amount: number
  customer_name: string
  product_line: string
  promo_type: string
  promo_sub_type: string
  deduction_reason_code_id?: string
  retailer_name: string
  code_description: string
  product_category: string
  execution_date: string
  retailer_invoice_number: string
  dispute_likelihood?: string
  custom_category?: string
  remote_id?: string
}

export interface ReasonCode {
  id: string
  org_id: string
  name?: string
  code_type: string
  description?: string
  expense_account?: string
  income_account?: string
}

// export interface DisputeMessage {
//   id: string
//   org_id: string
//   integration_id: string
//
//   from: string
//   remote_id: string // Freshdesk ticket id
//   body: string
//   sequence: number
//   status: string
//
//   sent_at: string // Assuming DateTime is represented as a string
//
//   deduction_id?: string
//   dispute_id?: string
//   attachment_s3_paths?: string[]
//   inbound: boolean
// }

// export interface EmailMessage {
//   id: string
//   org_id: string
//   integration_id: string
//   thread_id: string
//   sequence: number
//   date: string // Assuming DateTime is a string representation
//   from: string
//   to: string
//   cc: string
//   bcc: string
//   subject: string
//   body: string
//   // raw_data: any; // Assuming Json<gm::Message> can be represented as any
//   attachments: { s3_uri: string; id: string }[]
//   status?: string
// }

export interface Recipient {
  name?: string
  email?: string
}

export interface AttachmentRef {
  id: string
  s3_uri: string
}

export interface Message {
  id: string
  org_id: string
  integration_id: string
  thread_id: string
  source: string

  dispute_id?: string
  deduction_id?: string

  remote_id: string
  sequence: number
  date: string
  from: Recipient
  to: Recipient[]
  cc: Recipient[]
  bcc: Recipient[]
  subject: string
  body: string
  inbound: boolean
  read_at: string | null

  attachments: AttachmentRef[]
}

export interface Dispute {
  id: string
  org_id: string
  deduction_id?: string

  job_id?: string
  created_at: string
  updated_at: string
  source: string

  user_id: string
  type: DisputeType
  amount: number

  dispute_type_description: string
  message: string
  dispute_file_s3_uris: string[]

  email_remote_id?: string
  email_thread_id?: string
}

export interface UpdateDeductionPayload {
  po_number?: string
  retailer_name?: string
  execution_date?: string
  dc?: string
}

export async function updateDeduction(
  id: string,
  payload: UpdateDeductionPayload,
): Promise<DeductionResponse> {
  const response = await fetch(`/api/deduction/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  })

  if (!response.ok) {
    throw new Error("Failed to update deduction")
  }

  return response.json()
}
