import { ActivityItem } from '@/components/activity-feed/activity-item';
import { Activity } from '@/stores/activity';
import { format } from 'date-fns';
import { Loader2 } from 'lucide-react';

interface ActivityListProps {
  activities: Activity[];
  isLoading: boolean;
  onClose?: () => void;
  isStandalone?: boolean;
}

export function ActivityList({ activities, isLoading, onClose, isStandalone }: ActivityListProps) {
  if (isLoading && activities.length === 0) {
    return (
      <div className="flex justify-center items-center h-[200px]">
        <Loader2 className="h-6 w-6 animate-spin" />
      </div>
    );
  }

  // Group activities by date
  const groupedActivities = activities.reduce<Record<string, Activity[]>>((acc, activity) => {
    const date = format(new Date(activity.created_at), 'MMM d, yyyy');
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(activity);
    return acc;
  }, {});

  return (
    <div className="overflow-auto max-h-[calc(100vh-120px)]">
      {Object.entries(groupedActivities).map(([date, dateActivities]) => (
        <div key={date} className="py-4">
          <div className="px-6 py-2 text-sm font-medium text-muted-foreground">
            {date}
          </div>
          <div className="space-y-4">
            {dateActivities.map((activity) => (
              <ActivityItem 
                key={activity.id} 
                activity={activity} 
                onClose={onClose}
                isStandalone={isStandalone}
              />
            ))}
          </div>
        </div>
      ))}
      
      {isLoading && (
        <div className="flex justify-center p-4">
          <Loader2 className="h-6 w-6 animate-spin" />
        </div>
      )}
    </div>
  );
} 