import { api_fetch, QueryData } from "@/api"
import { Navbar } from "@/app/navbar"
import { DataTableState } from "@/deductions/table_state"
import { currencyFormatter, formatDistributor, map, toISODateString } from "@/utils"
import { ColumnDef } from "@tanstack/react-table"
import { useEffect, useMemo, useState } from "preact/compat"
import { AnalyticsTable } from "./analytics_table"

interface CustomerBreakdownRow {
  source: string
  invoice_amount: number
  deduction_amount: number
  net_amount: number
  deduction_percentage: number
}

const columns: ColumnDef<CustomerBreakdownRow>[] = [
  {
    accessorKey: "source",
    header: "Customer",
  },
  {
    accessorKey: "invoice_amount",
    header: "Invoice Amount",
    cell: ({ row }) => currencyFormatter(row.getValue("invoice_amount") as number),
  },
  {
    accessorKey: "deduction_amount",
    header: "Deduction Amount",
    cell: ({ row }) => currencyFormatter(row.getValue("deduction_amount") as number),
  },
  {
    accessorKey: "net_amount",
    header: "Net Amount",
    cell: ({ row }) => currencyFormatter(row.getValue("net_amount") as number),
  },
  {
    accessorKey: "deduction_percentage",
    header: "Deduction %",
    cell: ({ row }) => {
      const deductionAmount = row.getValue("deduction_amount") as number
      const invoiceAmount = row.getValue("invoice_amount") as number
      return `${((deductionAmount / invoiceAmount) * 100).toFixed(2)}%`
    },
  },
]

export function Analytics() {
  const [data, setData] = useState<CustomerBreakdownRow[]>([])
  const [loading, setLoading] = useState(true)
  const { startDate: start, endDate: end } = DataTableState.use(state => state!)

  const fetchData = async (start?: Date, end?: Date) => {
    setLoading(true)
    try {
      const res = await api_fetch<QueryData>("/deductions/query", {
        params: {
          query_name: "overall_customer_breakdown",
          start_date: map(start, toISODateString),
          end_date: map(end, toISODateString),
        },
      })

      if (!res.ok) {
        throw new Error("Failed to fetch data")
      }

      // Transform the QueryResult format into our CustomerBreakdownRow format
      const processedData: CustomerBreakdownRow[] = res.value.data.rows.map(row => {
        const source = row[0] as string
        const invoice_amount = Number(row[1])
        const deduction_amount = Number(row[2])
        
        return {
          source: formatDistributor(source),
          invoice_amount,
          deduction_amount,
          net_amount: invoice_amount - deduction_amount,
          deduction_percentage: deduction_amount / invoice_amount
        }
      })

      setData(processedData)
    } catch (error) {
      console.error("Error fetching data:", error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData(start ?? undefined, end ?? undefined)
  }, [start, end])

  const memoizedColumns = useMemo(() => columns, [])

  return (
    <div>
      <Navbar showDistributorFilter={false} />
      <div className="space-y-4">
        <div className="rounded-md border">
          <AnalyticsTable 
            columns={memoizedColumns} 
            data={data} 
            loading={loading}
          />
        </div>
      </div>
    </div>
  )
} 