import { DeductionNote } from "@/api/deduction.tsx";
import { fetchNotes, markNoteRead, noteState, startPolling, stopPolling } from "@/stores/note";
import { classes } from "@/utils";
import { formatDistanceToNow } from "date-fns";
import { Dot, Loader2 } from "lucide-react";
import { useEffect } from "preact/hooks";

interface NoteListProps {
  isStandalone?: boolean;
  onClose?: () => void;
}

interface GroupedNotes {
  [deductionId: string]: DeductionNote[];
}

interface NoteItemProps {
  note: DeductionNote;
  onClose?: () => void;
  isOlderNote?: boolean;
}

function NoteItem({ note, onClose, isOlderNote }: NoteItemProps) {
  const handleClick = async (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (!note.read_at) {
      await markNoteRead(note.id);
    }
    if (onClose) {
      onClose();
    }
    window.location.href = `/deduction/${note.deduction_id}`;
  };

  return (
    <div 
      onClick={handleClick}
      className={classes(
        "px-6 py-3 hover:bg-muted/50 cursor-pointer",
        isOlderNote && "pl-12 pt-0 pb-2 text-sm border-l-2 border-l-muted ml-8"
      )}
    >
      <div className="flex gap-4">
        <div className={classes("mt-1", isOlderNote && "opacity-50")}>
          <Dot className={`h-5 w-5 ${note.read_at ? 'text-muted-foreground' : 'text-red-700'}`} />
        </div>
        <div className="flex-1 space-y-1">
          <div className="flex items-center justify-between">
            <p className="text-sm font-medium">
              {note.actor_name}
            </p>
            <span className="text-xs text-muted-foreground">
              {formatDistanceToNow(new Date(note.created_at), { addSuffix: true })}
            </span>
          </div>
          {(note.invoice_number || note.amount) && !isOlderNote && (
            <div className="flex gap-2 text-xs text-muted-foreground">
              {note.invoice_number && (
                <span>Invoice: {note.invoice_number}</span>
              )}
              {note.amount && (
                <span>Amount: ${note.amount.toLocaleString()}</span>
              )}
            </div>
          )}
          <p className={classes(
            "text-sm whitespace-pre-wrap",
            // note.read_at ? "text-muted-foreground" : "text-primary",
            isOlderNote && "text-muted-foreground"
          )}>
            {note.message}
          </p>
        </div>
      </div>
    </div>
  );
}

export function NoteList({ isStandalone, onClose }: NoteListProps) {
  const { notes, isLoading } = noteState.use();

  useEffect(() => {
    fetchNotes();
    startPolling();
    return () => stopPolling();
  }, []);

  // Group notes by deduction_id
  const groupedNotes = notes.reduce((acc: GroupedNotes, note) => {
    if (!acc[note.deduction_id]) {
      acc[note.deduction_id] = [];
    }
    acc[note.deduction_id].push(note);
    return acc;
  }, {});

  if (isLoading && notes.length === 0) {
    return (
      <div className="flex justify-center items-center h-[200px]">
        <Loader2 className="h-6 w-6 animate-spin" />
      </div>
    );
  }

  return (
    <div className="overflow-auto max-h-[calc(100vh-120px)]">
      {Object.entries(groupedNotes).map(([deductionId, deductionNotes]) => (
        <div key={deductionId} className="border-b last:border-b-0">
          {deductionNotes.map((note, index) => (
            <NoteItem 
              key={note.id} 
              note={note} 
              onClose={onClose}
              isOlderNote={index > 0}
            />
          ))}
        </div>
      ))}
      
      {isLoading && (
        <div className="flex justify-center p-4">
          <Loader2 className="h-6 w-6 animate-spin" />
        </div>
      )}
    </div>
  );
} 