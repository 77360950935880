import freshdeskLogo from "/img/integration/freshdesk.png"
import { Integration } from "@/api/deduction.tsx"
import { Form } from "@/components/form/Form.tsx"
import { useFormState } from "@/components/form/form_state.tsx"
import { Input, PasswordInput } from "@/components/form/Input.tsx"
import { Button } from "@/components/ui/button.tsx"
import { useState } from "preact/compat"
import { Ok, Result } from "src/utils/type.tsx"
import { useLocation } from "wouter-preact"
import { api_fetch, HttpError } from "../api/client.tsx"
import { LoginScreen } from "../components/LoginScreen.tsx"

async function create_freshdesk_integration(
  username: string,
  password: string,
): Promise<Result<Integration, HttpError>> {
  let options = {
    body: {
      service: "freshdesk",
      credential: {
        username: username,
        password: password,
      },
    },
  }
  const res = await api_fetch<Integration>("/integration", options)
  if (!res.ok) {
    console.error(res.error)
    return res
  }
  return Ok(res.value.data)
}

export function FreshdeskIntegration() {
  const [, setLocation] = useLocation()
  const [loading, setLoading] = useState(false)

  const form = useFormState({
    username: "",
    password: "",
  })

  async function onSubmit() {
    setLoading(true)
    form.set_form_errors([])
    const res = await create_freshdesk_integration(form.username.value, form.password.value)
    setLoading(false)

    setLocation(`/setup/email`)
  }

  return (
    <LoginScreen title="">
      <div>
        <Form class="space-y-6" onSubmit={onSubmit} alerts={form.form_alerts}>
          <img src={freshdeskLogo} />
          <p className="mt-6">
            Enter your FreshDesk account information so we can sync your KeHE dispute data for you.
          </p>
          <Input
            label="Username"
            type="text"
            name="username"
            required={true}
            bind={form.username}
          />
          <PasswordInput
            label="Password"
            required={false}
            bind={form.password}
          />
          <Button
            type="submit"
            className="w-full btn text-white bg-[#25C16F] rounded-md hover:bg-[#1ea15a] focus:outline-hidden focus:ring-1 focus:ring-offset-2 focus:ring-plue-500 disabled:bg-gray-400 disabled:cursor-not-allowed"
            disabled={loading}
          >
            Connect Account
          </Button>
          <p className="mt-6 text-sm text-gray-500">
            If you login to FreshDesk using Google or another third-party, please convert that account to a
            username/password account by following their password reset flow{" "}
            <a class="hover:cursor-pointer underline text-blue-500" href="https://kehecx.freshdesk.com/support/login">
              here
            </a>.
          </p>
          <p className="text-sm text-gray-500">
            If you don't have a Freshdesk account, please skip this for now and email your KeHE contact to invite you to
            create one.
          </p>
          <Button
            type="button"
            variant="outline"
            className="w-full"
            onClick={() => setLocation("/setup/email")}
            disabled={loading}
          >
            Skip
          </Button>
        </Form>
      </div>
    </LoginScreen>
  )
}
