import { api_fetch } from "@/api/client.tsx"
import { DeductionNote, DeductionResponse, Message } from "@/api/deduction.tsx"
import { UserState } from "@/auth/user.tsx"
import { Button } from "@/components/ui/button.tsx"
import { Card } from "@/components/ui/card.tsx"
import { Separator } from "@/components/ui/separator.tsx"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs.tsx"
import { Textarea } from "@/components/ui/textarea.tsx"
import { toast } from "@/components/ui/use-toast.ts"
import { format } from "date-fns"
import { ArrowUp, ChevronDown, ChevronUp, Dot, ExternalLink, Mail, Mails } from "lucide-react"
import { useEffect, useRef, useState } from "preact/hooks"
import { StatusFlag } from "../status_state.tsx"
import { handleSaveNote } from "../table/actions/notes.tsx"

interface ConversationProps {
  deduction: DeductionResponse
  messages: Message[]
}

interface SendMessageBoxProps {
  threadId: string;
  messageSource: "freshdesk" | "email";
  freshdeskTicketId?: string;
  className?: string;
}

function SendMessageBox({ threadId, messageSource, freshdeskTicketId, className }: SendMessageBoxProps) {
  let [content, setContent] = useState("")

  async function handleSendMessage() {
    if (!content) return;

    const url = messageSource === "freshdesk" 
      ? `/thread/${threadId}/freshdesk`
      : `/thread/${threadId}/message`;

    const body = { message: content };
    const res = await api_fetch(url, { method: "POST", body });

    if (res.ok) {
      setContent("");
      StatusFlag.set(flag => !flag);
      toast({
        title: "Message Sent",
        description: "Please wait a bit and refresh this page to see it.",
      });
    } else {
      console.error("Failed to send message");
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description: "We will look into this and get back to you.",
      });
    }
  }

  return (
    <div className={className}>
      <div className="mt-4">
        <Textarea
          value={content}
          onChange={(e: any) => setContent(e.target.value)}
          onKeyDown={async (e: any) => {
            if ((e.ctrlKey || e.metaKey) && e.key === "Enter") {
              e.preventDefault()
              await handleSendMessage()
            }
          }}
          className="rounded-none border-b-0 border-r-0 border-l-0 focus-visible:border-plue-300 resize-none w-full px-4 py-0 pt-4 pb-0 text-md focus-visible:outline-hidden focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-2"
          placeholder={`Send ${messageSource} reply...`}
        />
      </div>
      <div className="flex justify-end items-center gap-2 mb-4 mx-4">
        {messageSource === "freshdesk" && (
          <a
            href={`https://kehecx.freshdesk.com/support/tickets/${freshdeskTicketId}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm text-gray-500 hover:text-gray-700 flex items-center gap-1"
          >
            <ExternalLink className="h-4 w-4" />
            Attach files in Freshdesk
          </a>
        )}
        <Button
          onClick={handleSendMessage}
          variant={"secondary"}
          disabled={content.length === 0}
        >
          Send
          <ArrowUp className="w-4 h-4 ml-1" />
        </Button>
      </div>
    </div>
  )
}

// Add a utility function to filter valid messages
function getValidMessages(messages: Message[]) {
  return messages.filter(message => !!message.body)
}

// Extract message rendering logic into a reusable component
interface MessageItemProps extends RenderItemProps {
  isExpanded: boolean
  onToggleExpand: () => void
}

function MessageItem({ from, date, subject, body, sequence, isExpanded, onToggleExpand }: MessageItemProps) {
  const formattedDate = format(new Date(date), "MMM d, yyyy")
  const displayName = from?.toLowerCase().includes('unfi') ? 'UNFI' : from
  
  return (
    <>
      <div className="flex mt-2 gap-2 items-start ml-2">
        <div className="flex flex-col items-center">
          <div className="inline-flex items-center justify-center border p-2 rounded-3xl">
            <Mail className="text-gray-500 h-5 w-5" />
          </div>
        </div>
        <div className="flex flex-col grow overflow-hidden">
          <div className="flex justify-between w-full">
            <div className="text-md truncate">{displayName}</div>
            <div className="text-gray-500 text-sm mt-1">{formattedDate}</div>
          </div>
          {subject && sequence === 0 && <div className="text-sm mt-1 truncate">{subject}</div>}
          <div className="flex items-center">
            <div className={`text-sm mt-1 grow text-gray-500 max-w-full overflow-hidden ${
              isExpanded ? "" : "line-clamp-2"
            }`}>
              {body}
            </div>
            <div className="mr-2">
              {isExpanded 
                ? <ChevronUp className="cursor-pointer" onClick={onToggleExpand} />
                : <ChevronDown className="cursor-pointer" onClick={onToggleExpand} />}
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 w-full flex justify-center">
        <Separator />
      </div>
    </>
  )
}

function renderMessageControls(messages: Message[], lastMessage: Message) {
  // Check if any message in the thread is from Freshdesk
  const hasFreshdeskMessages = messages.some(m => m.source === "freshdesk");
  const freshdeskTicketId = hasFreshdeskMessages 
    ? messages.find(m => m.source === "freshdesk")?.remote_id 
    : undefined;

  return (
    <SendMessageBox 
      threadId={lastMessage.thread_id}
      messageSource={hasFreshdeskMessages ? "freshdesk" : "email"}
      freshdeskTicketId={freshdeskTicketId}
    />
  );
}

export function Conversations({ deduction, messages }: ConversationProps) {
  const validMessages = getValidMessages(messages)
  const hasExternalCommunication = validMessages.length > 0
  const defaultTab = hasExternalCommunication ? "external" : "internal"

  const renderExternalCommunication = () => {
    if (!hasExternalCommunication) return null
    
    const lastMessage = validMessages[validMessages.length - 1]
    return (
      <TabsContent value="external" className="h-full">
        <ExternalMessages messages={validMessages} />
        {renderMessageControls(validMessages, lastMessage)}
      </TabsContent>
    )
  }

  return (
    <Card className="flex flex-col h-full overflow-auto">
      <div class="flex justify-between items-center m-4">
        <h2 class="flex text-xl">
          <Mails class="h-6 w-6" />
          <span class="ml-2">Conversations</span>
        </h2>
      </div>
      <Tabs
        defaultValue={defaultTab}
        // @ts-ignore
        className="h-full"
      >
        <TabsList className="mx-4">
          <TabsTrigger value="internal">{`Internal (${deduction.notes?.length ?? 0})`}</TabsTrigger>
          {hasExternalCommunication && 
            <TabsTrigger value="external">{`External (${validMessages.length})`}</TabsTrigger>
          }
        </TabsList>
        <TabsContent value="internal" className="h-full ">
          <InternalMessages notes={deduction.notes} deduction_id={deduction.id} />
        </TabsContent>
        {renderExternalCommunication()}
      </Tabs>
    </Card>
  )
}

function formatEmailFrom(from: string, sequence: number) {
  // TODO why would from be null / empty string??
  if (!from) return sequence === 0 ? "Your team" : ""
  const re = /<([^>]+)>/
  const match = from.match(re)
  return match ? match[1] : from
}

interface RenderItemProps {
  key: string
  from: string
  date: string
  subject?: string
  body: string
  sequence: number
}

interface ExternalMessagesProps {
  messages: Message[]
}

function ExternalMessages({ messages }: ExternalMessagesProps) {
  const [expandedMessage, setExpandedMessage] = useState<number | null>(null)
  const messagesContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight
    }
  }, [messages])

  return (
    <div className="flex flex-col gap-4 mx-4 mt-6 mb-4 overflow-auto h-96" ref={messagesContainerRef}>
      {messages.map(message => (
        <MessageItem
          key={message.id}
          from={message.from.name ?? message.from.email ?? ""}
          date={message.date}
          body={message.body}
          sequence={message.sequence}
          isExpanded={expandedMessage === message.sequence}
          onToggleExpand={() => setExpandedMessage(
            expandedMessage === message.sequence ? null : message.sequence
          )}
        />
      ))}
    </div>
  )
}

interface InternalMessageProps {
  notes?: DeductionNote[]
  deduction_id: string
}

interface NoteTextAreaProps {
  deduction_id: string
}

function NoteTextArea({ deduction_id }: NoteTextAreaProps) {
  const [note, setNote] = useState("")
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  const handleSave = async () => {
    if (!note.trim()) return
    await handleSaveNote({ deduction_id, note, setNote })
    // Focus back on textarea after sending
    textareaRef.current?.focus()
  }

  return (
    <div className="justify-end">
      <div className="mt-4">
        <Textarea
          ref={textareaRef}
          value={note}
          onChange={(e: any) => setNote(e.target.value)}
          onKeyDown={async (e: any) => {
            if ((e.ctrlKey || e.metaKey) && e.key === "Enter") {
              e.preventDefault()
              await handleSave()
            }
          }}
          className="rounded-none border-b-0 border-r-0 border-l-0 resize-none w-full px-4 py-0 pt-4 pb-0 text-md focus-visible:outline-hidden focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-2"
          placeholder="Send an internal note..."
        />
      </div>
      <div className="flex justify-end mb-4">
        <Button
          variant={"secondary"}
          className="mx-4"
          disabled={!note.trim()}
          onClick={handleSave}
        >
          Send
          <ArrowUp className="w-4 h-4 ml-1" />
        </Button>
      </div>
    </div>
  )
}

function InternalMessages({ notes, deduction_id }: InternalMessageProps) {
  const messagesContainerRef = useRef<HTMLDivElement>(null)
  const user = UserState.use()

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight
    }
  }, [notes])

  if (!notes) return null

  // Sort notes by created_at in ascending order to show oldest first
  const sortedNotes = [...notes].sort((a, b) => 
    new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
  )

  return (
    <>
      <div 
        className="flex flex-col gap-4 mt-6 mx-4 grow overflow-auto h-96" 
        ref={messagesContainerRef}
      >
        {sortedNotes.map(({ message, user_id, actor_name, created_at }) => {
          const isSelfNote = user_id === user?.id
          const formattedDate = format(new Date(created_at), "MMM d, yyyy")
          return (
            <div className={`flex flex-col ${isSelfNote ? "items-end" : "items-start"}`}>
              <div className={`border border-gray-300 p-2 ${
                isSelfNote ? "rounded-br-sm" : "rounded-bl-sm"
              } rounded-xl max-w-[80%]`}>
                <p className="text-gray-500 break-words">{message}</p>
              </div>
              <p className="text-sm flex items-center mt-1">
                <span>{isSelfNote ? formattedDate : actor_name}</span>
                <Dot className="w-2 h-2 mx-1" />
                <span>{isSelfNote ? actor_name : formattedDate}</span>
              </p>
            </div>
          )
        })}
      </div>
      <NoteTextArea deduction_id={deduction_id} />
    </>
  )
}

if (import.meta.vitest) {
  it('should return "Your team" for sequence 0 and empty from', () => {
    expect(formatEmailFrom("", 0)).toBe("Your team")
  })

  it("should return an empty string for non-zero sequence and empty from", () => {
    expect(formatEmailFrom("", 1)).toBe("")
  })

  it("should extract email from angle brackets", () => {
    expect(formatEmailFrom("John Doe <john@example.com>", 1)).toBe("john@example.com")
  })

  it("should return the full string if no angle brackets are present", () => {
    expect(formatEmailFrom("john@example.com", 1)).toBe("john@example.com")
  })
}
