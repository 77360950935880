import { fetch_query_data } from "@/api"
import { LoadingSpinner } from "@/app/loading"
import { DataTableState } from "@/deductions/table_state.tsx"
import { currencyFormatter, index_map, useAsyncEffect } from "@/utils"
import { BarList } from "@tremor/react"
import { capitalCase } from "change-case"
import { useState } from "preact/compat"
import { StatusFlag } from "./status_state.tsx"

type DeductionCategoryData = {
  name: string
  value: number
  amount: number
}

const colors = [
  "bg-green-500",
  "bg-yellow-500",
  "bg-amber-500",
  "bg-red-500",
  "bg-lime-500",
  "bg-sky-500",
  "bg-emerald-500",
  "bg-rose-500",
  "bg-fuchsia-500",
  "bg-teal-500",
]

export const STATUS_COLORS: { [key: string]: string } = {
  // needs action - red / amber
  new: "red-500",
  backup_received: "amber-500",
  review: "amber-500",
  disputable: "amber-500",

  // partial action taken - yellow
  expensable: "yellow-500",
  backup_requested: "yellow-500",
  disputed: "yellow-500",
  pending: "yellow-500",

  // don't need to work on it - slate (with white text)
  processing: "slate-500 text-white",
  archived: "slate-500 text-white",
  lost: "slate-500 text-white",
  misc: "slate-500 text-white",

  // celebration - green
  validated: "green-500",
  won: "green-500",
}

export const SORT_ORDER = {
  // needs action - red / amber
  new: 0,
  backup_received: 1,
  review: 2,
  disputable: 3,
  // partial action taken - yellow
  expensable: 4,
  backup_requested: 5,
  pending: 6,
  disputed: 7,
  // don't need to work on it - slate
  processing: 8,
  archived: 9,
  lost: 10,
  misc: 11,
  // celebration - green
  validated: 13,
  won: 14,
}

export type Bar<T> = T & {
  key?: string
  value: number
  // name: React$1.ReactNode;
  // icon?: React$1.JSXElementConstructor<any>;
  href?: string
  target?: string
  // color?: Color$1;
}

export function StatusBreakdown() {
  const [data, setData] = useState<DeductionCategoryData[]>([])
  const [loading, setLoading] = useState(true)
  const statusFlag = StatusFlag.use(sf => sf!)
  const [start, end, distributor] = DataTableState.use(s => [s.startDate, s.endDate, s.distributor])

  useAsyncEffect(async () => {
    let res = await fetch_query_data("status", { start, end, distributor })
    setLoading(false)
    if (!res.ok) {
      setData([])
      throw new Error("Failed to fetch data")
    }

    let { rows, headers } = res.value
    const headerIndex = index_map(headers)

    let total = rows
      .map(row => row[headerIndex.deduction_count] as number)
      .reduce((a, b) => a + b, 0)
    let nudge = total * 0.3
    let data = rows.map(row => {
      let amount = row[headerIndex.total_dollar_amount] as number
      let count = row[headerIndex.deduction_count] as number
      return {
        key: row[headerIndex.status_value] as keyof typeof SORT_ORDER,
        amount: amount,
        name: `${capitalCase(row[headerIndex.status_value] as string)} (${count})`,
        value: count + nudge,
        color: STATUS_COLORS[row[headerIndex.status_value] as keyof typeof STATUS_COLORS] || "gray",
      }
    })
    data.sort((a, b) => SORT_ORDER[a.key] - SORT_ORDER[b.key])
    setData(data)
  }, [start, end, distributor, statusFlag])

  if (loading || !data) {
    return <LoadingSpinner color="blue" />
  }

  return (
    <div class="flex">
      <BarList
        data={data}
        className="max-w-2xl grow"
        sortOrder="none"
        valueFormatter={(_: DeductionCategoryData) => ""}
        onValueChange={(item: Bar<DeductionCategoryData>) => {
          DataTableState.set(s => {
            return {
              ...s,
              search: "",
              transactionTypes: ["deduction", "invoice"], 
              columnFilters: [{ id: "status_value", value: item.key! }],
              pagination: { pageIndex: 0, pageSize: s.pagination.pageSize },
            }
          })
        }}
      />
      <div class="xl:ml-6">
        {data.map((d, i) => {
          return <div class="h-8 mb-1.5 items-center flex">{currencyFormatter(Math.abs(d.amount))}</div>
        })}
      </div>
    </div>
  )
}
