import { Navbar } from "@/app/navbar"
import { SummaryCards } from "@/deductions/summary_cards"
import { DetailedMonthlyBreakdownChart } from "./detailed_monthly_breakdown"
import { DetailedMonthlyExpenseBreakdownChart } from "./detailed_monthly_expense_breakdown"
import { DisputedBySourceChart } from "./disputed_by_source"
import { MonthlyBreakdownChart } from "./monthly_breakdown"

export function Sales() {
  return (
    <div>
      <Navbar />
      <div className="space-y-8">
        <SummaryCards />
        <div className="grid grid-cols-2 gap-4">          
          <DisputedBySourceChart />
          <MonthlyBreakdownChart />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <DetailedMonthlyBreakdownChart />
          <DetailedMonthlyExpenseBreakdownChart />
        </div>
      </div>
    </div>
  )
}
