/**
 * Utility functions for Amazon deduction links
 */
import { DeductionResponse } from "@/api/deduction.tsx";

// Type definitions for Amazon deduction matchers
type AmazonDeductionType = 
  | 'price_claim'
  | 'shortage_claim'
  | 'coop'
  | 'missed_adjustment'
  | 'vendor_return'
  | 'invoice_dispute'
  | 'rejected_po'
  | 'overweight_carton'
  | 'cap_seal'
  | 'bulk_buy'
  | 'c2fo'
  | 'provision'
  | 'unknown'

// Pattern definition type
interface DeductionPattern {
  invoice_regex: RegExp;
  description_regex: RegExp;
}

// Patterns to identify different Amazon deduction types
const DEDUCTION_PATTERNS: Record<string, DeductionPattern> = {
  price_claim: {
    invoice_regex: /\d+SCRPC/,
    description_regex: /Price Claim/i
  },
  shortage_claim: {
    invoice_regex: /\d+SC($|SCRSC$|SCRSCRSC$|SCRSCRSCRSC$)/,
    description_regex: /Shortage Claim/i
  },
  coop: {
    invoice_regex: /\d+-\d+/,
    description_regex: /(Co-op|Coop)/i
  },
  missed_adjustment: {
    invoice_regex: /\d+(DSC-|SC-|SCRSC-)/,
    description_regex: /Missed Adjustment Claim/i
  },
  vendor_return: {
    invoice_regex: /.*/,  // Match any invoice
    description_regex: /Vendor Return/i
  },
  rejected_po: {
    invoice_regex: /\d+VCBSINV/,
    description_regex: /Rejected PO/i
  },
  overweight_carton: {
    invoice_regex: /\d+VCBSINV/,
    description_regex: /Overweight Carton/i
  },
  cap_seal: {
    invoice_regex: /\d+VCBSINV/,
    description_regex: /Prep - Cap seal/i
  },
  bulk_buy: {
    invoice_regex: /(IPAPAUDUS|APAUDITUS)\d+/,
    description_regex: /Bulk Buy Allowance/i
  },
  c2fo: {
    invoice_regex: /\d+-C\d+FO-CM/,
    description_regex: /C2FO Credit Memo/i
  },
  provision: {
    invoice_regex: /\d+_PROVISION_FOR_/,
    description_regex: /Provision/i
  }
}

/**
 * Determine the type of Amazon deduction based on invoice number and description
 */
export function getAmazonDeductionType(deduction: DeductionResponse): AmazonDeductionType {
  const { invoice_number, description, category_description } = deduction
  
  if (!invoice_number) return 'unknown'
  
  // Check against all patterns
  for (const [type, patterns] of Object.entries(DEDUCTION_PATTERNS)) {
    const matchesInvoice = patterns.invoice_regex.test(invoice_number)
    const matchesDescription = patterns.description_regex && (
      (description && patterns.description_regex.test(description)) || 
      (category_description && patterns.description_regex.test(category_description))
    )
    
    // Different match criteria for different types
    if (type === 'coop' && matchesInvoice && matchesDescription) {
      return type as AmazonDeductionType
    } else if (['rejected_po', 'overweight_carton', 'cap_seal'].includes(type) && 
        matchesInvoice && matchesDescription) {
      return type as AmazonDeductionType
    } else if (matchesInvoice || matchesDescription) {
      return type as AmazonDeductionType
    }
  }
  
  return 'unknown'
}

/**
 * Get the appropriate Amazon Vendor Central link based on deduction type
 */
export function getAmazonVendorCentralLink(deduction: DeductionResponse): string | null {
  if (deduction.source.toLowerCase() !== 'amazon') return null
  
  const deductionType = getAmazonDeductionType(deduction)
  const { invoice_number } = deduction
  
  switch (deductionType) {
    case 'price_claim':
    case 'shortage_claim':
    case 'coop':
      return `https://vendorcentral.amazon.com/hz/vendor/members/coop?searchText=${invoice_number}`
      
    case 'invoice_dispute':
      // Nutiva specific payee code - this would need to be dynamic in production
      const payeeCode = 'NUAJ9'
      return `https://vendorcentral.amazon.com/invoice-management/details?invoiceNumber=${invoice_number}&payeeCode=${payeeCode}&leId=US`
      
    case 'rejected_po':
    case 'overweight_carton':
    case 'cap_seal':
      return `https://vendorcentral.amazon.com/hz/vendor/members/chargebacks/ui/${
        deductionType === 'cap_seal' ? '?defectType=prep_related' : ''
      }`
      
    // TODO: Extract PO number from description or other fields
    // case 'po_detail':
    //   const poNumber = extractPoNumber(deduction)
    //   if (poNumber) {
    //     return `https://vendorcentral.amazon.com/po/vendor/members/po-mgmt/order?poId=${poNumber}`
    //   }
    //   return null
      
    default:
      // General dispute page as fallback
      return 'https://vendorcentral.amazon.com/hz/vendor/members/disputes'
  }
}

export function getAmazonPaymentRemittanceLink(deduction: DeductionResponse): string | null {
  if (deduction.source.toLowerCase() !== 'amazon') return null
  
  // For Amazon deductions, link to the remittance home page
  return 'https://vendorcentral.amazon.com/hz/vendor/members/remittance/home'
}

/**
 * Extract PO number from deduction description or other fields
 * This is a placeholder implementation - actual logic will depend on the data format
 */
export function extractPoNumber(deduction: DeductionResponse): string | null {
  // Check if PO already exists
  if (deduction.po_number) {
    return deduction.po_number;
  }
  
  // Try to extract from description
  if (deduction.description) {
    // Match patterns like "PO: 123456789" or "PO#: 123456789" or "PO# 123456789"
    const poMatch = deduction.description.match(/PO[:#]?\s*(\d+)/i);
    if (poMatch && poMatch[1]) {
      return poMatch[1];
    }
  }
  
  // Try to extract from category_description
  if (deduction.category_description) {
    const poMatch = deduction.category_description.match(/PO[:#]?\s*(\d+)/i);
    if (poMatch && poMatch[1]) {
      return poMatch[1];
    }
  }

  return null;
}

/**
 * Generate a link to Amazon Vendor Central PO details
 */
export function getAmazonPoDetailLink(deduction: DeductionResponse): string | null {
  if (deduction.source.toLowerCase() !== 'amazon') return null;
  
  const poNumber = extractPoNumber(deduction);
  if (!poNumber) return null;
  
  return `https://vendorcentral.amazon.com/po/vendor/members/po-mgmt/order?poId=${poNumber}`;
}

// TODO: Implement function to extract PO number from description or other fields
// function extractPoNumber(deduction: DeductionResponse): string | null {
//   // Logic to extract PO number from description, category_description, or notes
//   return null
// } 