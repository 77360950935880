import { QueryData, api_fetch } from "@/api"
import { DataTableState } from "@/deductions/table_state"
import { formatDistributor, toISODateString } from "@/utils"
import { addDays, format, parseISO } from "date-fns"
import { useEffect, useState } from "preact/hooks"
import { getColor } from "./chart_colors"
import { ChartData, MonthlyBreakdownChartBase, RawData } from "./monthly_breakdown_base"

interface MonthlyBreakdownRawData extends RawData {
  distributor: string
  total_sales: number
  total_deductions: number
}

export function MonthlyBreakdownChart() {
  const [rawData, setRawData] = useState<MonthlyBreakdownRawData[]>([])
  const [chartData, setChartData] = useState<ChartData[]>([])
  const [distributors, setDistributors] = useState<string[]>([])
  const [loading, setLoading] = useState(true)
  const { startDate, endDate } = DataTableState.use(ts => ts!)

  useEffect(() => {
    async function fetchData() {
      try {
        let res = await api_fetch<QueryData>(`/deductions/query`, {
          params: {
            query_name: "monthly_breakdown",
            start_date: startDate ? toISODateString(startDate) : undefined,
            end_date: endDate ? toISODateString(endDate) : undefined,
          },
        })

        if (!res.ok) {
          throw new Error("Failed to fetch data")
        }

        const headerIndex = res.value.data.headers.reduce(
          (acc, header, index) => {
            acc[header] = index
            return acc
          },
          {} as { [key: string]: number }
        )

        const data = res.value.data.rows.map(row => {
          const parsedDate = parseISO(row[headerIndex.month] as string)
          const correctedDate = addDays(parsedDate, 1) // Adjust for off-by-one error
          return {
            month: format(correctedDate, "MMM yyyy"),
            distributor: row[headerIndex.distributor] as string,
            total_sales: Number(row[headerIndex.total_sales]),
            total_deductions: Number(row[headerIndex.total_deductions]),
          }
        })

        setRawData(data)
        setDistributors([...new Set(data.map(item => item.distributor))])
        setLoading(false)
      } catch (error) {
        console.error("Error fetching data:", error)
        setLoading(false)
      }
    }

    fetchData()
  }, [startDate, endDate])

  useEffect(() => {
    function processData() {
      const grouped: { [key: string]: ChartData } = {}

      rawData.forEach(item => {
        if (!grouped[item.month]) {
          grouped[item.month] = { month: item.month }
        }
        grouped[item.month][`${item.distributor}_sales`] = item.total_sales
        grouped[item.month][`${item.distributor}_deductions`] = item.total_deductions
      })

      setChartData(Object.values(grouped))
    }

    processData()
  }, [rawData])

  if (loading) {
    return (
      <div className="rounded-xl border bg-card text-card-foreground shadow-sm">
        <div className="p-6">
          <h3 className="font-semibold tracking-tight">Loading...</h3>
        </div>
      </div>
    )
  }

  const chartConfig = distributors.reduce((config, distributor) => {
    const formattedDistributor = formatDistributor(distributor)
    config[`${distributor}_sales`] = {
      label: `${formattedDistributor} Sales`,
      color: getColor(distributor, "sales"),
    }
    config[`${distributor}_deductions`] = {
      label: `${formattedDistributor} Deductions`,
      color: getColor(distributor, "deductions"),
    }
    return config
  }, {} as any)

  const dataKeys = distributors.flatMap(distributor => [
    `${distributor}_sales`,
    `${distributor}_deductions`,
  ])

  return (
    <MonthlyBreakdownChartBase
      title="Monthly Sales vs Deductions by Distributor"
      titleTooltip="Showing total sales and deductions for each distributor over the last 12 months"
      chartData={chartData}
      chartConfig={chartConfig}
      dataKeys={dataKeys}
    />
  )
}
