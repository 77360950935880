import React from "react"
import { FieldState } from "./form_state"

export type InputType = "text" | "date" | "number" | "email" | "password"

interface InputProps {
  label: string
  type: InputType
  name: string
  autocomplete?: string
  errors?: string[] | null
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  required?: boolean
  value?: string
  placeholder?: string
  tabIndex?: number
  bind?: FieldState<string>
}

export function Input(props: InputProps) {
  if (props.bind) {
    props = {
      ...props,
      errors: props.bind.errors,
      onChange: props.bind.set_value,
      value: props.bind.value,
    }
  }
  return <div>
    <label htmlFor={props.name} className="block text-sm font-medium text-gray-700">
      {props.label}
    </label>
    <div className="mt-1">
      <input
        tabIndex={props.tabIndex}
        type={props.type}
        name={props.name}
        id={props.name}
        autoComplete={props.autocomplete}
        value={props.value}
        required={props.required}
        placeholder={props.placeholder}
        onChange={props.onChange}
        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm
                placeholder-gray-400 focus:outline-hidden focus:ring-plue-800 focus:border-plue-900 sm:text-sm"
      />
    </div>
  </div>
}

export function EmailInput(props: Omit<InputProps, "type" | "autocomplete" | "name"> & { name?: string }) {
  return <Input {...props} type="email" autocomplete="email" name={props.name ?? "email"} />
}

export function PasswordInput(props: Omit<InputProps, "type" | "name"> & { name?: string }) {
  return <Input {...props} type="password" name={props.name ?? "password"} autocomplete="current-password" />
}
