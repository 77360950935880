import { DeductionResponse } from "@/api/deduction.tsx"
import { stopPropagation } from "@/utils"
import { ExternalLinkIcon, FileText, PackageSearch, Receipt, ShieldAlert, ShoppingCart, Tag } from "lucide-react"
import { useLocation } from "wouter-preact"
import { getAmazonPaymentRemittanceLink, getAmazonPoDetailLink, getAmazonVendorCentralLink } from "./amazon_utils"
import { getCvsDisputeUrl, getDisputeAction } from "./dispute_utils"
import { FileDisputeDrawer } from "./file_dispute_drawer.tsx"
import { Notes } from "./notes.tsx"

function getAmazonPoDetailIcon(deduction: DeductionResponse) {
  if (deduction.source.toLowerCase() !== 'amazon') return null
  
  const poLink = getAmazonPoDetailLink(deduction)
  if (!poLink) return null
  
  return (
    <div
      onClick={(e) => {
        e.stopPropagation()
        window.open(poLink, "_blank")
      }}
      title="View PO Details"
      className="cursor-pointer"
    >
      <ShoppingCart className="h-5 w-5 text-purple-600 cursor-pointer" />
    </div>
  )
}

function getAmazonVendorCentralIcon(deduction: DeductionResponse) {
  if (deduction.source.toLowerCase() !== 'amazon') return null
  
  const amazonLink = getAmazonVendorCentralLink(deduction)
  if (!amazonLink) return null
  
  // Don't link to the disputes page - we want to use our internal dispute flow
  if (amazonLink.includes('disputes')) {
    return null
  }
  
  // Icon selection based on link type
  let icon = <FileText className="h-5 w-5 text-blue-600 cursor-pointer" />
  
  if (amazonLink.includes('coop')) {
    console.log('coop')
    icon = <Tag className="h-5 w-5 text-blue-600 cursor-pointer" />
  } else if (amazonLink.includes('chargebacks')) {
    icon = <PackageSearch className="h-5 w-5 text-blue-600 cursor-pointer" />
  }
  
  return (
    <div
      onClick={(e) => {
        e.stopPropagation()
        window.open(amazonLink, "_blank")
      }}
      title="Open in Amazon Vendor Central"
      className="cursor-pointer"
    >
      {icon}
    </div>
  )
}

function getAmazonPaymentDetailIcon(deduction: DeductionResponse) {
  if (deduction.source.toLowerCase() !== 'amazon') return null
  
  const paymentLink = getAmazonPaymentRemittanceLink(deduction)
  if (!paymentLink) return null
  
  return (
    <div
      onClick={(e) => {
        e.stopPropagation()
        window.open(paymentLink, "_blank")
      }}
      title="View Payment Details"
      className="cursor-pointer"
    >
      <Receipt className="h-5 w-5 text-green-600 cursor-pointer" />
    </div>
  )
}

function getDisputeActionIcon(deduction: DeductionResponse) {
  // If already disputed, show green shield
  if (deduction.dispute_id) {
    return <ShieldAlert className="h-5 w-5 text-green-500 cursor-pointer" />
  }

  let action = getDisputeAction(deduction, null)

  if (!action || action.type !== "can_file") {
    return null
  }

  if (deduction.source.toLowerCase() === "cvs") {
    return (
      <ShieldAlert
        onClick={() => window.open(getCvsDisputeUrl(deduction), "_blank")}
        className="h-5 w-5 text-red-600 cursor-pointer"
      />
    )
  }
  
  // Don't add special handling for Amazon - use our internal dispute drawer for all other deductions
  return (
    <FileDisputeDrawer
      deduction={deduction}
      trigger={<ShieldAlert className="h-5 w-5 cursor-pointer text-red-600" />}
    />
  )
}

export function Actions({ deduction }: { deduction: DeductionResponse }) {
  const [location] = useLocation()
  const isAmazon = deduction.source.toLowerCase() === 'amazon'

  return (
    <div className="flex justify-between items-center gap-1" onClick={stopPropagation}>
      <Notes deduction_id={deduction.id} notes={deduction.notes} />
      {getDisputeActionIcon(deduction)}
      {isAmazon && getAmazonVendorCentralIcon(deduction)}
      {isAmazon && getAmazonPaymentDetailIcon(deduction)}
      {isAmazon && getAmazonPoDetailIcon(deduction)}
      <ExternalLinkIcon
        className="h-5 w-5 cursor-pointer text-primary underline"
        onClick={() =>
          window.open(
            location.includes("accounting")
              ? `/split/${deduction.id}`
              : `/deduction/${deduction.id}`,
            "_blank",
          )}
      />
    </div>
  )
}
