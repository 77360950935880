import { DeductionResponse } from "@/api/deduction.tsx"
import { UserState } from "@/auth/user"

const BASE_URL = window.location.origin

export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text).catch(err => {
    console.error("Failed to copy: ", err)
  })
}

// NETSUITE EXPORT NOTES FOR SUPER COFFEE:
// d.remote_accounting_id is the NS Internal ID

type AccountingHeaderMap = Record<string, Record<string, string>>
const ACCOUNTING_HEADER_MAP: AccountingHeaderMap = {
  // Bellway
  "01j0vbrzjm2nfg26hqnqhk_8hc7": {
    invoice_amount: "UnitPrice",
    reason_code: "Name",
    reason_code_type: "Type",
    expense_account: "ExpenseAccount",
    income_account: "IncomeAccount",
  },
  // Guayaki
  "01j9q1mzmvdqdqw9t6z0j3_qm8b": {
    // Map internal field names to exact CSV column names
    po_number: "PO #",
    execution_date: "Items: MCB Date",
    items_deptId: "Items: DeptID",
    items_classId: "Items: classID",
    items_regionId: "Items: regionID",
    items_channelId: "Items: channelID",
    items_priceLevel: "Items: Price Level",
    items_item: "Items: Item",
    items_itemId: "Items: ItemID",
    items_description: "Items: Description",
    items_quantity: "Items: Quantity",
    items_proposed_rate: "Items: Proposed Rate",
    items_rate: "Items: Rate",
    customer_id: "Customer ID",
    customer: "Customer",
    date: "Date",
    location: "Location",
    external_id: "External ID",
    // Add the new CM field mappings
    cm_deptId: "CM: deptID",
    cm_classId: "CM: classID",
    cm_regionId: "CM: regionID",
    cm_channelId: "CM: channelID",
    memo: "Memo",
  }
}

export function buildCsv(filteredData: Record<string, any>[], org_id: string): string {
  const HEADER_MAP = ACCOUNTING_HEADER_MAP[org_id] || {}

  // Get all possible headers while preserving order
  const headerSet = new Set<string>()
  
  // First add headers from the first item to maintain primary column order
  if (filteredData[0]) {
    Object.keys(filteredData[0]).forEach(key => headerSet.add(key))
  }
  
  // Then add any additional headers from other items
  filteredData.forEach(item => {
    Object.keys(item).forEach(key => headerSet.add(key))
  })

  // Convert to array while preserving order
  const baseHeaders = Object.keys(filteredData[0] || {})
  const additionalHeaders = Array.from(headerSet).filter(h => !baseHeaders.includes(h))
  const headers = [...baseHeaders, ...additionalHeaders]

  // Create header row
  const headerRow = headers
    .map(header => `"${HEADER_MAP[header] || header}"`)
    .join(",")

  // Create body rows using ordered headers
  const body = filteredData
    .map(item => {
      return headers
        .map(header => {
          const cellValue = item[header]
          return `"${cellValue != null ? cellValue : ""}"`
        })
        .join(",")
    })
    .join("\n")

  return [headerRow, body].join("\n")
}

export function downloadCsv(csvData: string, filename: string) {
  const blob = new Blob([csvData], { type: "text/csv" })
  const url = URL.createObjectURL(blob)
  const a = document.createElement("a")
  a.href = url

  // Format the download filename with the current date
  const today = new Date()
  let year = today.getFullYear()
  let month = String(today.getMonth() + 1).padStart(2, "0")
  let day = String(today.getDate()).padStart(2, "0")
  const formattedDate = `${year}-${month}-${day}`
  a.download = `${filename}_${formattedDate}.csv`
  // Trigger the download and clean up the URL object
  a.click()
  URL.revokeObjectURL(url)
}

// Add this type to help with object column unpacking
type UnpackConfig = {
  path: string[]
  prefix?: string
  fields?: string[]
}

// Rename to better reflect its purpose
const SPLIT_UNPACK_COLUMNS: Record<string, UnpackConfig> = {
  reason_codes: {
    path: [], // No longer need path since we'll handle each array item
    prefix: "reason_code_",
    fields: [
      "expense_account",
      "name",
      "number",
      "actor",
      "customer_name",
      "amount",
      "is_split",
      "code_description",
      "retailer_name",
    ],
  },
}

// Add portal link based on current page context
function addPortalLink(item: any): string {
  const isAccountingPage = window.location.pathname.includes('split') || window.location.pathname.includes('accounting')
  const id = item.deduction_id ?? item.id
  return `${BASE_URL}/${isAccountingPage ? "split" : "deduction"}/${id}`
}

// Add this type to help with code description parsing
type ParsedCodeDescription = {
  deptId?: string
  classId?: string
  regionId?: string
  channelId?: string
  priceLevel?: string
}

// Add helper function to parse code description
function parseCodeDescription(description?: string): ParsedCodeDescription {
  if (!description) return {}
  
  const result: ParsedCodeDescription = {}
  
  // Match patterns like "Items: DeptID-462" and extract the value after the dash
  const patterns = {
    deptId: /Items: DeptID-(\d+)/,
    classId: /Items: classID-(\d+)/,
    regionId: /Items: regionID-(\d+)/,
    channelId: /Items: channelID-(\d+)/,
    priceLevel: /Items: Price Level-(\w+)/,
  }

  Object.entries(patterns).forEach(([key, pattern]) => {
    const match = description.match(pattern)
    if (match?.[1]) {
      result[key as keyof ParsedCodeDescription] = match[1]
    }
  })

  return result
}

// Add this helper function
function getExcelSerialDate(date: Date): number {
  // Excel's epoch is 1/1/1900 and Excel incorrectly considers 1900 as leap year
  const EXCEL_EPOCH = new Date(1899, 11, 31) // Dec 31, 1899
  const MILLISECONDS_PER_DAY = 24 * 60 * 60 * 1000
  
  // Get days between dates
  const utcDate = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  const utcExcelEpoch = Date.UTC(EXCEL_EPOCH.getFullYear(), EXCEL_EPOCH.getMonth(), EXCEL_EPOCH.getDate())
  
  // Add 1 to account for Excel's leap year bug in 1900
  return Math.floor((utcDate - utcExcelEpoch) / MILLISECONDS_PER_DAY) + 1
}

// Add helper function to handle Guayaki-specific transformations
function addGuayakiSpecificFields(baseItem: Record<string, any>, item: any, arrayItem?: any) {
  const user = UserState.get()
  if (!user?.org_id || user.org_id !== "01j9q1mzmvdqdqw9t6z0j3_qm8b") return

  // Get today's date as Excel serial number
  const excelDate = getExcelSerialDate(new Date())
  
  // Parse code description for the required fields
  const description = arrayItem?.code_description ?? (item as any).code_description
  const parsedDesc = parseCodeDescription(description)
  
  // Add only the required fields
  Object.assign(baseItem, {
    // Standard fields
    customer: "KeHE",
    customer_id: "107475",
    date: new Date().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }),
    external_id: `107475-${excelDate}${item.check_number ? item.check_number : String.fromCharCode(97 + Math.floor(Math.random() * 26))}`,
    location: item.location || "GSRP HQ",
    // po_number: item.po_number,
    memo: item.memo || "MarginWiz Deduction Platform - Accounting Export",

    cm_deptId: "462",
    cm_classId: "362",
    cm_regionId: "17",
    cm_channelId: "2",
    
    // Items fields from parsed description
    items_deptId: parsedDesc.deptId || "",
    items_classId: parsedDesc.classId || "",
    items_regionId: parsedDesc.regionId || "",
    items_channelId: parsedDesc.channelId || "",
    items_priceLevel: parsedDesc.priceLevel || "",
  })

  // Add fields that depend on arrayItem
  if (arrayItem) {
    const executionDate = arrayItem.execution_date ? new Date(arrayItem.execution_date) : new Date(item.invoice_date)
    // Updated format to "MMM YYYY" (e.g. "Dec 2023")
    const mcbDate = executionDate ? 
      `${executionDate.toLocaleString('en-US', { month: 'short' })} ${executionDate.getFullYear()}` : 
      ""
    
    Object.assign(baseItem, {
      items_item: baseItem.reason_code_expense_account,
      items_itemId: arrayItem.remote_id || "",
      items_description: [
        baseItem.reason_code_expense_account,
        mcbDate
      ].filter(Boolean).join(": "),
      items_quantity: "1",
      items_proposed_rate: Math.abs(arrayItem.amount).toFixed(2),
      items_rate: "0.00",
      execution_date: mcbDate,
      reason_code_amount: Math.abs(arrayItem.amount).toFixed(2), // todo: maybe handle decimal splitting better on the backend?
    })
  }
}

export function collectDownloadData<T>(data: T[]) {
  const filteredData = []
  const INTERNAL_KEYS = [
    "created_at",
    "updated_at",
    "raw_data",
    "s3_uri",
    "backup_s3_uri",
    "check_s3_uri",
    "repayment_for",
    "is_repayment",
    "is_prepayment",
    "notes",
    "document",
    "user_files",
    "task",
    "amount_remaining",
    "custom_deduction_method",
    // "accounting_number", // adding back in for super coffee export
    // "original_source", // adding back in for super coffee export
    "parent_accounting_number",
    "accounting_status",
    "has_splits",
    "is_parent",
    "is_deduction",
  ]

  for (const item of data) {
    let addedSplitRow = false

    // Process each possible split field
    for (const [splitKey, config] of Object.entries(SPLIT_UNPACK_COLUMNS)) {
      const arrayValue = item[splitKey as keyof typeof item]

      if (Array.isArray(arrayValue) && arrayValue.length > 0) {
        addedSplitRow = true
        // Create a row for each item in the array
        for (const arrayItem of arrayValue) {
          const baseItem = processItem(item, INTERNAL_KEYS, [splitKey])
          baseItem.portal_link = addPortalLink(item)
          
          // Add the split field's properties with prefix
          for (const objKey in arrayItem) {
            if (!config.fields || config.fields.includes(objKey)) {
              const newKey = config.prefix ? `${config.prefix}${objKey}` : objKey
              baseItem[newKey] = arrayItem[objKey]
            }
          }

          // Add Guayaki-specific fields
          addGuayakiSpecificFields(baseItem, item, arrayItem)
          
          filteredData.push(baseItem)
        }
      }
    }

    // If no split rows were added, add the base item
    if (!addedSplitRow) {
      const baseItem = processItem(item, INTERNAL_KEYS)
      baseItem.portal_link = addPortalLink(item)
      
      // Add Guayaki-specific fields
      addGuayakiSpecificFields(baseItem, item)
      
      filteredData.push(baseItem)
    }
  }

  return filteredData
}

// Helper function to process a single item
function processItem(
  item: any, 
  internalKeys: string[], 
  excludeKeys: string[] = []
): Record<string, any> {
  const filteredItem: Record<string, any> = {}

  for (const key in item) {
    if (
      Object.prototype.hasOwnProperty.call(item, key) &&
      (key === "remote_accounting_id" || !key.endsWith("_id")) &&
      !internalKeys.includes(key) &&
      !excludeKeys.includes(key) &&
      key !== "id"
    ) {
      filteredItem[key] = item[key]
    }
  }

  return filteredItem
}

export function convertBackupToCsv(data: Record<string, number | string | boolean>[]) {
  if (!data || !data.length) {
    return ""
  }

  // Get all headers
  const allHeaders = Object.keys(data[0])
  
  // Filter out headers where all values are null, undefined, or empty string
  const headers = allHeaders.filter(header => {
    return data.some(item => {
      const value = item[header]
      return value !== null && value !== undefined && value !== ""
    })
  })

  const headerRow = headers.join(",")

  const dataRows = data
    .map(item => {
      return headers
        .map(header => {
          const cellValue = item[header]
          return `"${cellValue != null ? cellValue : ""}"`
        })
        .join(",")
    })
    .join("\n")

  return [headerRow, dataRows].join("\n")
}

const TVI_PREFIXES = ["vcna", "vsup", "vcpn", "vonl", "viap"]

export function getTargetUrl(ded: DeductionResponse): string {
  if (TVI_PREFIXES.some(prefix => ded.invoice_number?.toLowerCase().startsWith(prefix))) {
    let modified = ded.invoice_number
      .split(/[-:]/)[0]
      .replace(/[^0-9]/g, "")
    return `https://tvi.partnersonline.com/quick-search${modified}`
  } else if (ded.invoice_number?.toLowerCase().startsWith("cb")) {
    return "https://apreports.partnersonline.com/credit_debit"
  } else {
    return "https://www.partnersonline.com/page/help/NDI0"
  }
}

if (import.meta.vitest) {
  describe("getTargetUrl", () => {
    it.each([
      ["VCNA0003420106-06", "https://tvi.partnersonline.com/quick-search0003420106"],
      ["VSUP0002549886-01", "https://tvi.partnersonline.com/quick-search0002549886"],
      ["VCPN0002537204-01", "https://tvi.partnersonline.com/quick-search0002537204"],
      ["VONL00253720-101", "https://tvi.partnersonline.com/quick-search00253720"],
      ["VIAP3490811", "https://tvi.partnersonline.com/quick-search3490811"],
      ["VIAP3471694:2024-06-01", "https://tvi.partnersonline.com/quick-search3471694"],
    ])("should return correct TVI URL for %s", (invoiceNumber, expectedUrl) => {
      const row = { invoice_number: invoiceNumber } as any
      expect(getTargetUrl(row)).toBe(expectedUrl)
    })

    it("should return credit/debit URL for CB prefix", () => {
      const row = { invoice_number: "CB-123" } as any
      expect(getTargetUrl(row)).toBe("https://apreports.partnersonline.com/credit_debit")
    })

    it("should return help URL for other cases", () => {
      const row = { invoice_number: "OTHER-123" } as any
      expect(getTargetUrl(row)).toBe("https://www.partnersonline.com/page/help/NDI0")
    })

    it("should handle lowercase invoice numbers", () => {
      const row = { invoice_number: "vcna123" } as any
      expect(getTargetUrl(row)).toBe("https://tvi.partnersonline.com/quick-search123")
    })

    it("should handle undefined invoice number", () => {
      const row = { } as any
      expect(getTargetUrl(row)).toBe("https://www.partnersonline.com/page/help/NDI0")
    })
  })

  describe("processItem", () => {
    it("should include remote_accounting_id in the result", () => {
      const item = {
        id: "123",
        name: "John",
        remote_accounting_id: "NS12345",
        user_id: "456",
        created_at: "2023-01-01"
      }
      const internalKeys = ["created_at"]
      
      const result = processItem(item, internalKeys)
      
      expect(result).toEqual({
        name: "John",
        remote_accounting_id: "NS12345"
      })
    })

    it("should exclude other fields ending with _id", () => {
      const item = {
        id: "123",
        name: "John",
        remote_accounting_id: "NS12345",
        user_id: "456",
        customer_id: "789",
        org_id: "101112"
      }
      
      const result = processItem(item, [])
      
      expect(result).toEqual({
        name: "John",
        remote_accounting_id: "NS12345"
      })
    })

    it("should respect internalKeys and excludeKeys", () => {
      const item = {
        id: "123",
        name: "John",
        remote_accounting_id: "NS12345",
        status: "active",
        notes: "test notes"
      }
      
      const result = processItem(item, ["notes"], ["status"])
      
      expect(result).toEqual({
        name: "John",
        remote_accounting_id: "NS12345"
      })
    })
  })

  describe("convertBackupToCsv", () => {
    it("should convert an array of objects to CSV string", () => {
      const data = [
        { name: "John", age: 30, city: "New York" },
        { name: "Jane", age: 25, city: "Los Angeles" },
      ]
      const expected = 'name,age,city\n"John","30","New York"\n"Jane","25","Los Angeles"'
      expect(convertBackupToCsv(data)).toBe(expected)
    })

    it("should handle empty array", () => {
      expect(convertBackupToCsv([])).toBe("")
    })

    it("should handle objects with boolean values", () => {
      const data = [
        { name: "John", isStudent: true },
        { name: "Jane", isStudent: false },
      ]
      const expected = 'name,isStudent\n"John","true"\n"Jane","false"'
      expect(convertBackupToCsv(data)).toBe(expected)
    })

    it("should handle objects with special characters in values", () => {
      const data = [
        { name: 'John "Doe"', description: "Likes to use, commas" },
        { name: "Jane", description: "No special characters" },
      ]
      const expected = 'name,description\n"John "Doe"","Likes to use, commas"\n"Jane","No special characters"'
      expect(convertBackupToCsv(data)).toBe(expected)
    })

    it("should remove columns that are entirely empty, null, or undefined", () => {
      const data = [
        { name: "John", emptyStr: "", nullVal: null, undefinedVal: undefined, age: 30 },
        { name: "Jane", emptyStr: "", nullVal: null, undefinedVal: undefined, age: 25 },
      ]
      const expected = 'name,age\n"John","30"\n"Jane","25"'
      // @ts-ignore
      expect(convertBackupToCsv(data)).toBe(expected)
    })

    it("should keep columns that have at least one non-empty value", () => {
      const data = [
        { name: "John", col1: "", col2: null, col3: "value" },
        { name: "Jane", col1: "", col2: "data", col3: "" },
      ]
      const expected = 'name,col2,col3\n"John","","value"\n"Jane","data",""'
      // @ts-ignore
      expect(convertBackupToCsv(data)).toBe(expected)
    })

    it("should handle mixed data types in columns", () => {
      const data = [
        { name: "John", value: 123, empty: "" },
        { name: "Jane", value: "string", empty: null },
      ]
      const expected = 'name,value\n"John","123"\n"Jane","string"'
      // @ts-ignore
      expect(convertBackupToCsv(data)).toBe(expected)
    })
  })

  describe("collectDownloadData", () => {
    beforeEach(() => {
      // Mock window.location
      Object.defineProperty(window, 'location', {
        value: {
          pathname: '/deductions',
          origin: 'http://localhost:3000'
        },
        writable: true
      })
    })

    it("should use deduction_id when available", () => {
      const input = [
        { id: "999", deduction_id: "123", name: "John" },
        { id: "888", deduction_id: "456", name: "Jane" },
      ]
      const result = collectDownloadData(input)
      
      expect(result[0].portal_link).toBe("http://localhost:3000/deduction/123")
      expect(result[1].portal_link).toBe("http://localhost:3000/deduction/456")
    })

    it("should fall back to id when deduction_id is not available", () => {
      const input = [
        { id: "123", name: "John" },
        { id: "456", name: "Jane" },
      ]
      const result = collectDownloadData(input)
      
      expect(result[0].portal_link).toBe("http://localhost:3000/deduction/123")
      expect(result[1].portal_link).toBe("http://localhost:3000/deduction/456")
    })

    it("should add split portal links when on accounting page", () => {
      // Update mock location to accounting page
      Object.defineProperty(window, 'location', {
        value: {
          pathname: '/accounting',
          origin: 'http://localhost:3000'
        },
        writable: true
      })

      const input = [
        { id: "999", deduction_id: "123", name: "John" },
        { id: "888", deduction_id: "456", name: "Jane" },
      ]
      const result = collectDownloadData(input)
      
      expect(result[0].portal_link).toBe("http://localhost:3000/split/123")
      expect(result[1].portal_link).toBe("http://localhost:3000/split/456")
    })

    it("should add portal links to split rows", () => {
      const input = [{
        id: "999",
        deduction_id: "123",
        name: "John",
        reason_codes: [
          { name: "RC1", amount: 100 },
          { name: "RC2", amount: 200 },
        ]
      }]
      const result = collectDownloadData(input)
      
      expect(result.length).toBe(2)
      expect(result[0].portal_link).toBe("http://localhost:3000/deduction/123")
      expect(result[1].portal_link).toBe("http://localhost:3000/deduction/123")
      expect(result[0].reason_code_name).toBe("RC1")
      expect(result[1].reason_code_name).toBe("RC2")
    })

    it("should preserve existing data while adding portal links", () => {
      const input = [{
        id: "999",
        deduction_id: "123",
        name: "John",
        status: "active",
        amount: 100
      }]
      const result = collectDownloadData(input)
      
      expect(result[0]).toEqual({
        name: "John",
        status: "active",
        amount: 100,
        portal_link: "http://localhost:3000/deduction/123"
      })
    })

    it("should handle missing ids", () => {
      const input = [{ name: "John" }]
      const result = collectDownloadData(input)
      
      expect(result[0].portal_link).toBe("http://localhost:3000/deduction/undefined")
    })

    it("should handle null deduction_id", () => {
      const input = [{ id: "123", deduction_id: null, name: "John" }]
      const result = collectDownloadData(input)
      
      expect(result[0].portal_link).toBe("http://localhost:3000/deduction/123")
    })

    it("should include remote_accounting_id in the exported data", () => {
      const input = [{ 
        id: "123", 
        name: "John", 
        remote_accounting_id: "NS12345",
        some_other_id: "OTHER_ID" // This should be filtered out
      }]
      const result = collectDownloadData(input)
      
      expect(result[0].remote_accounting_id).toBe("NS12345")
      expect(result[0].some_other_id).toBeUndefined()
    })
  })

  describe("build_csv function", () => {
    const mockDeductionResponse: DeductionResponse[] = [
      {
        invoice_amount: 100,
        reason_codes: [
          {
            name: "mock-reason-code-name",
            expense_account: "mock-expense-account",
            actor: "mock-actor",
            amount: 100,
            customer_name: "mock-customer-name",
            retailer_name: "mock-retailer-name",
            code_description: "mock-code-description",
            // @ts-ignore
            number: 123,
          },
        ],
        source: "mock-source",
        invoice_number: "mock-invoice-number",
        check_number: 123,
        po_number: "mock-po-number",
        retailer_name: "mock-retailer-name",
        category: "mock-category",
        description: "mock-description",
        status_value: "mock-status-value",
      },
    ]

    it("should return a CSV string with mapped headers when org_id exists in HEADER_MAP", () => {
      const org_id = "01j0vbrzjm2nfg26hqnqhk_8hc7"
      const result = buildCsv(collectDownloadData(mockDeductionResponse), org_id)

      const lines = result.split("\n")
      const headers = lines[0]
      const values = lines[1]

      // Check that invoice_amount is mapped to UnitPrice
      expect(headers).toContain('"UnitPrice"')

      // Check that unpacked reason_code fields are present with their prefixes
      expect(headers).toContain('"reason_code_name"')
      expect(headers).toContain('"reason_code_expense_account"')

      // Check values
      expect(values).toContain('"100"') // invoice_amount/UnitPrice
      expect(values).toContain('"mock-reason-code-name"')
      expect(values).toContain('"mock-expense-account"')
    })
  })

  describe("parseCodeDescription", () => {
    it("should correctly parse code description fields", () => {
      const description = "Items: DeptID-462. Items: classID-2.Items: regionID-35.Items: channelID-2.Items: Price Level-Custom"
      const result = parseCodeDescription(description)
      
      expect(result).toEqual({
        deptId: "462",
        classId: "2",
        regionId: "35",
        channelId: "2",
        priceLevel: "Custom"
      })
    })

    it("should handle missing fields", () => {
      const description = "Items: DeptID-462. Items: Price Level-Custom"
      const result = parseCodeDescription(description)
      
      expect(result).toEqual({
        deptId: "462",
        priceLevel: "Custom"
      })
    })

    it("should handle empty or undefined input", () => {
      expect(parseCodeDescription()).toEqual({})
      expect(parseCodeDescription("")).toEqual({})
    })
  })

  describe("collectDownloadData with code description parsing", () => {
    it("should parse code description for Guayaki orgs", () => {
      const testData = [{
        org_id: "01j9q1mzmvdqdqw9t6z0j3_qm8b",
        reason_codes: [{
          code_description: "Items: DeptID-462. Items: classID-2.Items: regionID-35.Items: channelID-2.Items: Price Level-Custom",
          amount: 100,
          execution_date: "2025-01-07"
        }]
      }]

      const result = collectDownloadData(testData)
      
      expect(result[0]).toMatchObject({
        portal_link: expect.any(String),
        reason_code_code_description: "Items: DeptID-462. Items: classID-2.Items: regionID-35.Items: channelID-2.Items: Price Level-Custom"
      })
    })

    it("should not parse code description for other orgs", () => {
      const testData = [{
        org_id: "other_org",
        reason_codes: [{
          code_description: "Items: DeptID-462. Items: classID-2"
        }]
      }]

      const result = collectDownloadData(testData)
      
      expect(result[0]).not.toHaveProperty("items_deptId")
      expect(result[0]).not.toHaveProperty("items_classId")
    })
  })

  describe("addGuayakiSpecificFields", () => {
    beforeEach(() => {
      vi.spyOn(UserState, 'get').mockImplementation(() => ({
        org_id: "01j9q1mzmvdqdqw9t6z0j3_qm8b",
        email: "test@example.com",
        id: "123",
        first_name: "Test",
        last_name: "User",
        role: "admin"
      }))
    })

    afterEach(() => {
      vi.restoreAllMocks()
    })

    it("should add all required fields with exact column names", () => {
      const baseItem = {
        source: "kehe",
        invoice_amount: -54.23,
        invoice_date: "2024-12-17",
        check_number: "3113707",
        check_date: "2024-12-20",
        check_amount: 40675.89,
        po_number: "1234",
        dc: "55",
        location: "GSRP HQ",
        memo: "MarginWiz Deduction Platform - Accounting Export",
        reason_code_expense_account: "62990 - Misc",
      }
      
      const arrayItem = {
        code_description: "Items: DeptID-462. Items: classID-2.Items: regionID-29.Items: channelID-2.Items: Price Level-Custom",
        amount: 47.7224,
        expense_account: "62990 - Misc",
        execution_date: "2025-01-07",
        actor: "promoted",
        is_split: true,
        customer_name: "kehe",
        remote_id: "123"
      }
      
      addGuayakiSpecificFields(baseItem, baseItem, arrayItem)
      
      expect(baseItem).toMatchObject({
        customer: "KeHE",
        customer_id: "107475",
        date: expect.stringMatching(/^\d{2}\/\d{2}\/\d{4}$/),
        external_id: expect.stringMatching(/^107475-\d{5}3113707$/),
        location: "GSRP HQ",
        po_number: "1234",
        memo: "MarginWiz Deduction Platform - Accounting Export",
        items_itemId: "123",
        items_description: "62990 - Misc: Jan 2025",
        items_quantity: "1",
        items_proposed_rate: "47.72",
        items_rate: "0.00",
        execution_date: "Jan 2025",
        items_deptId: "462",
        items_classId: "2",
        items_regionId: "29",
        items_channelId: "2",
        items_priceLevel: "Custom",
        cm_deptId: "462",
        cm_classId: "362",
        cm_regionId: "17",
        cm_channelId: "2"
      })
    })

    it("should format MCB date correctly", () => {
      const baseItem = {
        reason_code_expense_account: "62990 - Misc"
      } as any
      const arrayItem = {
        execution_date: "2025-01-07",
        amount: 100
      }
      
      addGuayakiSpecificFields(baseItem, baseItem, arrayItem)
      expect(baseItem.execution_date).toBe("Jan 2025")
    })

    it("should format description correctly", () => {
      const baseItem = {
        reason_code_expense_account: "62990 - Misc"
      } as any
      const arrayItem = {
        execution_date: "2025-01-07",
        amount: 100
      }
      
      addGuayakiSpecificFields(baseItem, baseItem, arrayItem)
      expect(baseItem.items_description).toBe("62990 - Misc: Jan 2025")
    })

    it("should handle missing arrayItem gracefully", () => {
      const baseItem = {
        code_description: "Items: DeptID-462. Items: classID-2.Items: regionID-29.Items: channelID-2.Items: Price Level-Custom"
      } as any
      
      addGuayakiSpecificFields(baseItem, baseItem)
      
      expect(baseItem).toMatchObject({
        items_deptId: "462",
        items_classId: "2",
        items_regionId: "29",
        items_channelId: "2",
        items_priceLevel: "Custom",
        customer: "KeHE",
        customer_id: "107475",
        cm_deptId: "462",
        cm_classId: "362",
        cm_regionId: "17",
        cm_channelId: "2"
      })
      
      // Should not have array-item specific fields
      expect(baseItem).not.toHaveProperty("items_item")
      expect(baseItem).not.toHaveProperty("items_quantity")
      expect(baseItem).not.toHaveProperty("items_proposed_rate")
    })

    it("should generate unique external IDs with check number when available", () => {
      const baseItem1 = { check_number: "12345" } as any
      const baseItem2 = { check_number: "67890" } as any
      
      addGuayakiSpecificFields(baseItem1, baseItem1)
      addGuayakiSpecificFields(baseItem2, baseItem2)
      
      expect(baseItem1.external_id).toMatch(/^107475-\d{5}12345$/)
      expect(baseItem2.external_id).toMatch(/^107475-\d{5}67890$/)
      expect(baseItem1.external_id).not.toBe(baseItem2.external_id)
    })

    it("should generate external IDs with random letter when check number is missing", () => {
      const baseItem = {} as any
      
      addGuayakiSpecificFields(baseItem, baseItem)
      
      expect(baseItem.external_id).toMatch(/^107475-\d{5}[a-z]$/)
    })

    it("should not modify baseItem if user org is not Guayaki", () => {
      vi.spyOn(UserState, 'get').mockImplementation(() => ({
        org_id: "other-org",
        email: "test@example.com",
        id: "123",
        first_name: "Test",
        last_name: "User",
        role: "admin"
      }))

      const baseItem = {
        original_field: "value"
      }
      
      addGuayakiSpecificFields(baseItem, baseItem)
      
      expect(baseItem).toEqual({
        original_field: "value"
      })
    })

    it("should handle null user gracefully", () => {
      vi.spyOn(UserState, 'get').mockImplementation(() => null)

      const baseItem = {
        original_field: "value"
      }
      
      addGuayakiSpecificFields(baseItem, baseItem)
      
      expect(baseItem).toEqual({
        original_field: "value"
      })
    })
  })

  describe("addGuayakiSpecificFields MCB date formatting", () => {
    beforeEach(() => {
      vi.spyOn(UserState, 'get').mockImplementation(() => ({
        org_id: "01j9q1mzmvdqdqw9t6z0j3_qm8b",
        email: "test@example.com",
        id: "123",
        first_name: "Test",
        last_name: "User",
        role: "admin"
      }))
    })

    afterEach(() => {
      vi.restoreAllMocks()
    })

    it("should format MCB date as 'MMM YYYY'", () => {
      const baseItem = {
        reason_code_expense_account: "62990 - Misc"
      } as any
      const arrayItem = {
        execution_date: "2023-12-15",
        amount: 100
      }
      
      addGuayakiSpecificFields(baseItem, baseItem, arrayItem)
      expect(baseItem.execution_date).toBe("Dec 2023")
      expect(baseItem.items_description).toBe("62990 - Misc: Dec 2023")
    })
  })

  describe("getExcelSerialDate", () => {
    it("should convert dates to Excel serial numbers", () => {
      expect(getExcelSerialDate(new Date(2024, 11, 31))).toBe(45657) // 12/31/2024
      expect(getExcelSerialDate(new Date(2024, 0, 1))).toBe(45292)   // 1/1/2024
      expect(getExcelSerialDate(new Date(2023, 11, 31))).toBe(45291) // 12/31/2023
    })
  })
}
