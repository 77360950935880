import { VNode } from "preact";
import { RouteProps, Switch, useLocation } from "wouter-preact";

import { Sidebar } from "@/app/sidebar";

export function Container({ children }: { children: Array<VNode<RouteProps>> }) {
  const [location] = useLocation();
  const isTableView = location === "/deductions" || location === "/accounting";
  
  return (
    <div class="flex w-full flex-col min-h-screen">
      <div className="flex flex-col gap-4 py-4 pl-14">
        <Sidebar />

        <main class={`${isTableView ? 'mx-0' : 'mx-8'} max-w-full`}>
          <Switch children={children} />
        </main>
      </div>
    </div>
  )
}