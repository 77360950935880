import { DeductionFile, Files as SharedFiles } from "@/deductions/detail/files"

type AccountingFilesProps = {
  files: DeductionFile[]
  deductionId: string
  onFileSelect: (file: DeductionFile) => void
  orgId?: string
  source?: string
  invoiceNumber?: string
}

export function Files(props: AccountingFilesProps) {
  return (
    <SharedFiles
      {...props}
      height="compact"
    />
  )
}
